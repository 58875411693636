import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { entitiesSelector } from './entities';

const EMPTY_STRING = '';
const EMPTY_OBJECT = {};

export const modalNameSelector = (state: RootState): ModalTypes =>
  state.modal.name;

export const modalPreviousNameSelector = (state: RootState): ModalTypes =>
  state.modal.previousName || EMPTY_STRING;

export const modalPodcastIdSelector = (state: RootState): string =>
  state.modal.podcastId || EMPTY_STRING;

export const modalEpisodeIdSelector = (state: RootState): string =>
  state.modal.episodeId || EMPTY_STRING;

export const modalPodcastSelector = createSelector(
  [modalPodcastIdSelector, entitiesSelector],
  (podcastId, entities) => entities.podcasts[podcastId] || EMPTY_OBJECT
);

export const modalEpisodeSelector = createSelector(
  [modalEpisodeIdSelector, entitiesSelector],
  (episodeId, entities) => entities.episodes[episodeId] || EMPTY_OBJECT
);

export const modalEpisodeImageSelector = createSelector(
  [modalEpisodeSelector],
  (modalEpisode) => modalEpisode?.image?.sourceUrl
);

export const modalPodcastFeedOwnerEmailSelector = createSelector(
  [modalPodcastSelector],
  (modalPodcast) => modalPodcast.feedOwnerEmail
);

export const modalPodcastFeedIdSelector = createSelector(
  [modalPodcastSelector],
  (modalPodcast) => modalPodcast.feedId
);
