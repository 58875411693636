import { Link } from 'react-router-dom';
import { MusicLink as BauhausMusicLink } from '@amzn/DMWebUIComponents/dist/react/components';

export const MusicLink = ({ href, ...rest }: any) =>
  href?.startsWith('/') ? (
    <Link to={href}>
      <BauhausMusicLink {...rest} />
    </Link>
  ) : (
    <BauhausMusicLink href={href} {...rest} />
  );
