import { useSelector } from 'react-redux';
import { modalNameSelector } from 'store/selectors/modal';
import {
  CLAIM_PODCAST,
  CONNECT_YOUR_AMAZON_ACCOUNT,
  CONTINUE_WITH_YOUR_AMAZON_ACCOUNT,
  CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME,
  PODCAST_DATE_RANGE,
  EPISODE_DATE_RANGE,
  TERMS_OF_SERVICE,
  HIDDEN_PODCAST,
  PENDING_PODCAST,
  SHARE_PODCAST,
  SHARE_EPISODE,
  ACTIVE_PODCAST,
  METADATA,
  HIDE,
  UNHIDE_PODCAST,
  UNCONFIRMED,
  SUSPENDED,
  UPDATE_RSS,
  OWNERSHIP_RELEASED,
  RELEASE_OWNERSHIP,
  COOKIE_CONSENT,
} from 'constants/modals';
import ClaimPodcast from './ClaimPodcast';
import { ConnectAccount, ConnectAccountHome } from './ConnectAccount';
import PodcastDateRangeModal from './DateRange/Podcast';
import EpisodeDateRangeModal from './DateRange/Episode';
import SignedIn from './SignedIn';
import { TOS } from './TOS';
import MetadataModal from './MetadataModal';
import PodcastActive from './PodcastActive';
import PodcastHidden from './PodcastHidden';
import HideModal from './HideModal';
import PodcastPending from './PodcastPending';
import SharePodcastModal from './ShareModal/SharePodcastModal';
import ShareEpisodeModal from './ShareModal/ShareEpisodeModal';
import UnhidePodcast from './UnhidePodcast';
import UnconfirmedModal from './UnconfirmedModal';
import SuspendedModal from './SuspendedModal';
import UpdateRSSModal from './UpdateRSSModal';
import OwnershipReleasedModal from './OwnershipReleasedModal';
import ReleaseOwnershipModal from './ReleaseOwnershipModal';
import { CookieConsentModal } from './CookieConsentModal';

export const Modal = () => {
  const lookup = {
    [CONNECT_YOUR_AMAZON_ACCOUNT]: ConnectAccount,
    [CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME]: ConnectAccountHome,
    [CONTINUE_WITH_YOUR_AMAZON_ACCOUNT]: SignedIn,
    [TERMS_OF_SERVICE]: TOS,
    [METADATA]: MetadataModal,
    [ACTIVE_PODCAST]: PodcastActive,
    [HIDDEN_PODCAST]: PodcastHidden,
    [HIDE]: HideModal,
    [PENDING_PODCAST]: PodcastPending,
    [SHARE_PODCAST]: SharePodcastModal,
    [SHARE_EPISODE]: ShareEpisodeModal,
    [SUSPENDED]: SuspendedModal,
    [CLAIM_PODCAST]: ClaimPodcast,
    [UNHIDE_PODCAST]: UnhidePodcast,
    [UNCONFIRMED]: UnconfirmedModal,
    [UPDATE_RSS]: UpdateRSSModal,
    [OWNERSHIP_RELEASED]: OwnershipReleasedModal,
    [RELEASE_OWNERSHIP]: ReleaseOwnershipModal,
    [PODCAST_DATE_RANGE]: PodcastDateRangeModal,
    [EPISODE_DATE_RANGE]: EpisodeDateRangeModal,
    [COOKIE_CONSENT]: CookieConsentModal,
    '': null,
  };
  const modalName = useSelector(modalNameSelector);

  const ModalComponent = lookup[modalName];

  return ModalComponent ? <ModalComponent /> : null;
};
