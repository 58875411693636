const episodeEntitiesReducer = (
  accumulator: Record<string, Episode>,
  episode: Episode
) => ({
  ...accumulator,
  [episode.episodeId]: episode,
});

export const normalizeEpisodes = (data: EpisodeListResponse) => ({
  episodesListHasNextPage: data.hasNextPage,
  episodeListCursor: data.nextPageToken,
  episodeList: data.items.map((item: Episode) => item.episodeId),
  entities: data.items.reduce(episodeEntitiesReducer, {}),
});
