import { takeLatest } from 'redux-saga/effects';
import { setLocale } from 'store/slices/locale';
import { LOCALE, LOCALE_TYPES } from 'constants/locales';
import { PODCASTER_SUPPORT_EMAIL_TYPES } from 'constants/emails';

function persistLocale(action: {
  payload: { code: LOCALE_TYPES; supportEmail: PODCASTER_SUPPORT_EMAIL_TYPES };
  type: string;
}) {
  localStorage.setItem(LOCALE, action.payload.code);
}

export const localeSagas = [takeLatest(setLocale.toString(), persistLocale)];
