import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ShareModal from 'components/Modal/ShareModal/';
import BlurredModal from 'components/Modal/BlurredModal';
import { MusicHorizontalItem } from 'components/MusicHorizontalItem';
import { SOCIAL_HANDLES_MAP } from 'constants/locales';
import { SHARE_PODCAST_MODAL } from 'constants/metrics';
import { localeSelector } from 'store/selectors/locale';
import { modalPodcastSelector } from 'store/selectors/modal';
import { usePageView } from 'hooks/pageView';
import {
  getFacebookShareLink,
  getLocalizedPublicUrl,
  getTwitterShareLink,
} from 'utils/url';
import { PageContext } from 'contexts';
import '../styles.scss';

const ModalTitle = (
  <h4>
    <FormattedMessage id="share-podcast" defaultMessage="Share my Podcast" />
  </h4>
);
const SharePodcastModal = () => {
  usePageView(SHARE_PODCAST_MODAL);
  const { imageUrl, title, podcastId } = useSelector(modalPodcastSelector);
  const locale = useSelector(localeSelector);
  const intl = useIntl();

  const shareMessage = intl.formatMessage({ id: 'share-message' }, { title });
  const twitterShareMessage = intl.formatMessage(
    { id: 'share-message-twitter' },
    { title, amazonMusicTwitterAccount: SOCIAL_HANDLES_MAP[locale] }
  );

  const publicUrl = getLocalizedPublicUrl(podcastId, locale, title);
  const twitterUrl = getTwitterShareLink(publicUrl, twitterShareMessage);
  const facebookUrl = getFacebookShareLink(publicUrl);
  const emailUrl = `mailto:?body=${encodeURIComponent(
    publicUrl
  )}&subject=${shareMessage}`;

  return (
    <PageContext.Provider value={SHARE_PODCAST_MODAL}>
      <BlurredModal title={ModalTitle}>
        <div className="Share__content">
          <div className="Share__podcastInfo">
            <MusicHorizontalItem
              imageUrl={imageUrl}
              label={<FormattedMessage id="podcast" defaultMessage="Podcast" />}
              primaryText={title}
            />
          </div>

          <ShareModal
            publicUrl={publicUrl}
            facebookUrl={facebookUrl}
            twitterUrl={twitterUrl}
            emailUrl={emailUrl}
          />
        </div>
      </BlurredModal>
    </PageContext.Provider>
  );
};
export default SharePodcastModal;
