import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { modalPodcastSelector } from 'store/selectors/modal';
import LabelArtworkTile from 'components/ArtworkTile/components/ArtworkTileLabel';
import {
  getDisplayedStatus,
  PODCAST_STATUS_TO_MESSAGE_ID_MAP,
} from 'constants/podcasts';

const ModalArtworkTile = () => {
  const { imageUrl, status, title, hostingProvider } = useSelector(
    modalPodcastSelector
  );
  const displayedStatus = getDisplayedStatus(status);
  const Status = (
    <FormattedMessage
      {...{
        defaultMessage: displayedStatus,
        id: `${PODCAST_STATUS_TO_MESSAGE_ID_MAP[displayedStatus]}`,
      }}
    />
  );

  return (
    <LabelArtworkTile
      imageUrl={imageUrl}
      status={Status}
      title={title}
      hostingProvider={hostingProvider || ''}
    />
  );
};

export default ModalArtworkTile;
