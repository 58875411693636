import {
  de_DE,
  en_US,
  en_GB,
  es_ES,
  es_MX,
  fr_FR,
  it_IT,
  ja_JP,
  pt_BR,
} from 'constants/locales';
import {
  PODCASTERS_BR_EMAIL,
  PODCASTERS_JP_EMAIL,
  PODCASTERS_EU_EMAIL,
  PODCASTERS_MX_EMAIL,
  PODCASTERS_IN_EMAIL,
  PODCASTERS_CA_EMAIL,
  PODCASTERS_EMAIL,
  AM4P_PRIME_EMAIL,
} from 'constants/emails';
/* cirrus */
export const PODCASTER_PORTAL_ENCRYPTED_DEVICE_TYPE_ID = 'A37DUQPXR0VP16';
export const UI_CLICK_BASE_EVENT_VERSION = '3';
export const UI_CLICK_SPECIFIC_EVENT_VERSION = '4';
export const UI_CLICK_CONTENT_SOURCE = 'client';

/* Action Types */
export const CLICK = 'uiClick';
export const PAGE_VIEW = 'uiPageView';

/* Pages */
export const HOME_PAGE = 'podcasterHome';
export const ADD_YOUR_PODCAST_PAGE = 'podcasterAddYourPodcast';
export const ALREADY_CLAIMED_PAGE = 'podcasterAlreadyClaimed';
export const ALREADY_PLAYING_PAGE = 'podcasterAlreadyPlaying';
export const CLAIM_SUCCESSFUL_PAGE = 'podcasterClaimSuccessful';
export const EMAIL_SENT_PAGE = 'podcasterEmailSent';
export const FAQ_PAGE = 'podcasterFaq';
export const IS_THIS_YOUR_PODCAST_PAGE = 'podcasterIsThisYourPodcast';
export const PROMOTIONAL_TOOLS_PAGE = 'podcasterPromotionalTools';
export const ERROR_PAGE = 'podcasterError';
export const TERMS_OF_SERVICE_PAGE = 'podcasterTermsOfService';
export const ANALYTICS_OVERVIEW_PAGE = 'podcasterAnalyticsOverview';
export const OVERVIEW_PAGE = 'podcasterOverview';
export const ANALYTICS_PODCAST_DETAILS_PAGE =
  'podcasterAnalyticsPodcastDetails';
export const EPISODE_DETAILS_PAGE = 'podcasterEpisodeDetails';
export const PODCAST_DETAILS_PAGE = 'podcasterPodcastDetails';
export const UNKNOWN = 'UNKNOWN';
export const CLAIM_UNSUCCESSFUL_PAGE_MAP = {
  FEED_CLAIMED: 'podcasterClaimUnsuccessfulFeedAlreadyClaimed',
  INVALID_TOKEN: 'podcasterClaimUnsuccessfulInvalidToken',
  WRONG_CUSTOMER: 'podcasterClaimUnsuccessfulWrongUser',
  '': 'podcasterClaimUnsuccessfulDefaultError',
};
export const INVALID_FEED_PAGE_MAP = {
  CANNOT_DOWNLOAD_OR_PARSE: 'podcasterInvalidFeedCannotDownloadOrParse',
  DUPLICATE: 'podcasterInvalidFeedDuplicate',
  INVALID_EMAIL: 'podcasterInvalidFeedInvalidEmail',
  MALFORMED_XML: 'podcasterInvalidFeedMalformedXml',
  TAKEN_DOWN: 'podcasterInvalidFeedTakenDown',
  '': 'podcasterInvalidFeedDefault',
};

/* Modals */
export const CLAIM_PODCAST_MODAL = 'claimPodcastModal';
export const CONNECT_ACCOUNT_MODAL = 'podcasterConnectAccountModal';
export const COOKIE_CONSENT_MODAL = 'podcasterCookieConsentModal';
export const DATE_RANGE_MODAL = 'podcasterDateRangeModal';
export const SIGNED_IN_MODAL = 'podcasterSignedInModal';
export const TOS_MODAL = 'podcasterTermsOfServiceModal';
export const SHARE_PODCAST_MODAL = 'podcasterSharePodcastModal';
export const SHARE_EPISODE_MODAL = 'podcasterShareEpisodeModal';
export const PODCAST_PENDING_MODAL = 'podcasterPodcastPendingModal';
export const METADATA_MODAL = 'podcasterMetadataModal';
export const PODCAST_ACTIVE_MODAL = 'podcasterPodcastActiveModal';
export const PODCAST_HIDDEN_MODAL = 'podcasterPodcastHiddenModal';
export const PODCAST_UNCONFIRMED_MODAL = 'podcasterPodcastUnconfirmedModal';
export const PODCAST_HIDE_MODAL = 'podcasterPodcastHideModal';
export const UNHIDE_PODCAST_MODAL = 'podcasterUnhidePodcastModal';
export const PODCAST_SUSPENDED_MODAL = 'podcasterPodcastSuspendedModal';
export const UPDATE_RSS_MODAL = 'podcasterUpdateRssModal';
export const OWNERSHIP_RELEASED_MODAL = 'podcasterOwnershipReleasedModal';
export const RELEASE_OWNERSHIP_MODAL = 'podcasterReleaseOwnershipModal';

/* Element Enums */
export const GO_HOME = 'goHome';
export const GO_SUBMIT_RSS_FEED = 'goSubmitRssFeed';
export const GO_PRIVACY_NOTICE = 'goPrivacyNotice';
export const GO_SECONDARY_PRIVACY_NOTICE = 'goSecondaryPrivacyNotice';
export const GO_CONDITIONS_OF_USE = 'goConditionsOfUse';
export const GO_INTEREST_BASED_ADS = 'goInterestBasedAds';
export const GO_US_AND_GLOBAL_EMAIL = 'goUsAndGlobalEmail';
export const GO_AM4P_PRIME_EMAIL = 'goAM4PPrimeEmail';
export const GO_DE_EMAIL = 'goGermanEmail';
export const GO_EUROPE_EMAIL = 'goEuropeEmail';
export const GO_JAPAN_EMAIL = 'goJapanEmail';
export const GO_BRAZIL_EMAIL = 'goBrazilEmail';
export const GO_MEXICO_EMAIL = 'goMexicoEmail';
export const GO_INDIA_EMAIL = 'goIndiaEmail';
export const GO_CANADA_EMAIL = 'goCanadaEmail';
export const GO_UK_EMAIL = 'goUkEmail';
export const GO_FR_EMAIL = 'goFranceEmail';
export const GO_IT_EMAIL = 'goItalyEmail';
export const GO_ES_EMAIL = 'goSpainEmail';
export const GO_ANZ_EMAIL = 'goAustraliaNewZealandEmail';
export const EMAIL_TO_ENUM_MAP = {
  [PODCASTERS_EMAIL]: GO_US_AND_GLOBAL_EMAIL,
  [PODCASTERS_EU_EMAIL]: GO_EUROPE_EMAIL,
  [PODCASTERS_JP_EMAIL]: GO_JAPAN_EMAIL,
  [PODCASTERS_BR_EMAIL]: GO_BRAZIL_EMAIL,
  [PODCASTERS_MX_EMAIL]: GO_MEXICO_EMAIL,
  [PODCASTERS_IN_EMAIL]: GO_INDIA_EMAIL,
  [PODCASTERS_CA_EMAIL]: GO_CANADA_EMAIL,
  [AM4P_PRIME_EMAIL]: GO_AM4P_PRIME_EMAIL,
};
export const GO_UPDATE_ANCHOR_EMAIL = 'goUpdateAnchorEmail';
export const GO_UPDATE_SOUNDCLOUD_EMAIL = 'goUpdateSoundcloudEmail';
export const GO_UPDATE_SPREAKER_EMAIL = 'goUpdateSpreakerEmail';
export const GO_UPDATE_RSS = 'goUpdateRSS';
export const GO_TRANSFER = 'goTransferOwnership';
export const GO_HIDE_PODCAST = 'goHidePodcast';
export const GO_UNHIDE_PODCAST = 'goUnhidePodcast';
export const GO_REMOVE = 'goRemovePodcast';
export const GO_GET_SUPPORT = 'goGetSupport';
export const GO_RELEASE_OWNERSHIP = 'goReleaseOwnership';
export const GO_SHARE_PODCAST = 'goSharePodcast';
export const GO_VIEW_EXTERNAL_SHOW_PAGE = 'goViewShowPageExternal';
export const GO_ADD_OR_CLAIM_PODCAST = 'goAddOrClaimPodcast';
export const GO_PODCASTER_FAQ = 'goPodcasterFaq';
export const GO_STICKER_SHEET_FAQ = 'goStickerSheetFAQ';
export const GO_ADD_EMAIL_TO_FEED_FAQ = 'goAddEmailToFeedFAQ';
export const GO_PROMOTIONAL_TOOLS = 'goPromotionalTools';
export const GO_TERMS_AND_CONDITIONS = 'goTermsAndConditions';
export const GO_AMAZON_MUSIC = 'goAmazonMusic';
export const GO_AMAZON_MUSIC_ASSETS = 'goAmazonMusicAssets';
export const GO_AUDIBLE = 'goAudible';
export const GO_CREATE_NEW_ACCOUNT = 'goCreateNewAccount';
export const GO_DIFFERENT_ACCOUNT = 'goDifferentAccount';
export const GO_PODCASTER_PROMO_EMAIL = 'goPodcasterPromoEmail';
export const GO_AM4P_PARTNERSHIPS_EMAIL = 'goAm4pPartnershipsEmail';
export const GO_PODCASTER_SUPPORT_EMAIL = 'goPodcasterSupportEmail';
export const GO_PODCAST_HOME_PAGE = 'goPodcastHomePage';
export const GO_SOCIAL_TEMPLATES = 'goSocialTemplates';
export const GO_CREATIVE_GUIDELINES = 'goCreativeGuidelines';
export const GO_DIGITAL_MUSIC_EMAIL = 'goDigitalMusicEmail';
export const GO_CONTRACTS_LEGAL_EMAIL = 'goContractsLegalEmail';
export const GO_ALEXA_GUIDELINES = 'goAlexaGuidelines';
export const GO_ECHO_GUIDELINES = 'goEchoGuidelines';
export const GO_PODCAST_PUBLIC_URL = 'goPodcastPublicUrl';
export const GO_EPISODE_PUBLIC_URL = 'goEpisodePublicUrl';
export const GO_PODCAST_TRAILER_PUBLIC_URL = 'goPodcastTrailerPublicUrl';
export const GO_FACEBOOK = 'goFacebook';
export const GO_TWITTER = 'goTwitter';
export const GO_EMAIL = 'goEmail';
export const GO_PODCAST_DETAILS = 'goPodcastDetails';
export const GO_PODCAST_RSS_URL = 'goPodcastRSSUrl';
export const GO_EPISODE_DETAILS = 'goEpisodeDetails';
export const GO_REQUEST_SUPPORT = 'goRequestSupport';
export const GO_SIGN_OUT = 'goSignOut';
export const GO_DASHBOARD = 'goDashboard';
export const GO_ART19PUBLISHERS = 'goArt19Publishers';
export const GO_INFLUENCERS = 'goInfluencers';
export const GO_AUDIENCE_INSIGHTS_BETA_EMAIL = 'goAudienceInsightsBetaEmail';
export const GO_COOKIE_PRIVACY_NOTICE = 'goCookiePrivacyNotice';
export const GO_COOKIE_NOTICE = 'goCookieNotice';
export const GO_COOKIE_PREFERENCES = 'goCookiePreferences';
export const SELECT_ACCEPT_COOKIE = 'selectAcceptCookie';
export const SELECT_CONTINUE_WITHOUT_CONSENTING_COOKIE =
  'selectContinueWithoutConsentingCookie';
export const SELECT_SUBMIT_FEED_URL = 'selectSubmitFeedUrl';
export const SELECT_SIGN_IN = 'selectSignIn';
export const SELECT_DASHBOARD = 'selectDashboard';
export const SELECT_GET_STARTED = 'selectGetStarted';
export const SELECT_ADD_PODCAST = 'selectAddPodcast';
export const SELECT_SEND_EMAIL = 'selectSendEmail';
export const SELECT_RESEND_EMAIL = 'selectResendEmail';
export const SELECT_CONFIRM_EMAIL = 'selectConfirmEmail';
export const SELECT_CONFIRM_MODAL = 'selectConfirmModal';
export const SELECT_OK = 'selectOk';
export const SELECT_CLOSE_MODAL = 'selectCloseModal';
export const SELECT_CLOSE_UPCOMING_FEATURES_RIBBON =
  'selectCloseUpcomingFeaturesRibbon';
export const SELECT_CLOSE_STATUS_RIBBON = 'selectCloseStatusRibbon';
export const SELECT_CONTINUE_TO_ACCOUNT = 'selectContinueToAccount';
export const SELECT_LOCALE_DROPDOWN = 'selectLocaleDropdown';
export const SELECT_COUNTRY_DROPDOWN = 'selectCountryDropdown';
export const SELECT_RSS_INPUT = 'selectRssInput';
export const SELECT_TOS_CHECKBOX = 'selectTosCheckbox';
export const SELECT_SHARE_PODCAST_MODAL = 'selectSharePodcastModal';
export const SELECT_SHARE_EPISODE_MODAL = 'selectShareEpisodeModal';
export const SELECT_MANAGE_POPOVER = 'selectManagePopover';
export const SELECT_HIDE_PODCAST = 'selectHidePodcast';
export const SELECT_UNHIDE_PODCAST = 'selectUnhidePodcast';
export const SELECT_COPY_LINK = 'selectCopyLink';
export const SELECT_PODCAST_PENDING_MODAL = 'selectPodcastPendingModal';
export const SELECT_PODCAST_UNCONFIRMED_MODAL = 'selectPodcastUnconfirmedModal';
export const SELECT_PODCAST_SUSPENDED_MODAL = 'selectPodcastSuspendedModal';
export const SELECT_VIEW_ALL_METADATA = 'selectViewAllMetadata';
export const SELECT_PODCAST_LIST_LOAD_MORE = 'selectPodcastListLoadMore';
export const SELECT_MENU = 'selectMenu';
export const SELECT_CLOSE_MENU = 'selectCloseMenu';
export const SELECT_LOGO = 'selectLogo';
export const SELECT_UPDATE_RSS = 'selectUpdateRss';
export const SELECT_UPDATE_RSS_INPUT = 'selectUpdateRssInput';
export const SELECT_OWNERSHIP_RELEASED_MODAL = 'selectOwnershipReleased';
export const SELECT_RELEASE_OWNERSHIP_MODAL = 'selectReleaseOwnership';
export const SELECT_TIME_FRAME = 'selectTimeFrame';
export const SELECT_TIME_FRAME_7_DAYS = 'selectTimeFrame7days';
export const SELECT_TIME_FRAME_14_DAYS = 'selectTimeFrame14days';
export const SELECT_TIME_FRAME_30_DAYS = 'selectTimeFrame30days';
export const SELECT_TIME_FRAME_90_DAYS = 'selectTimeFrame90Days';
export const SELECT_TIME_FRAME_ALL_TIME = 'selectTimeFrameAllTime';
export const SELECT_TIME_FRAME_CUSTOM = 'selectTimeFrameCustom';
export const SELECT_ANALYTICS_TYPE_DROPDOWN = 'selectAnalyticsTypeDropdown';
export const SELECT_ANALYTICS_TYPE_DROPDOWN_STARTS =
  'selectAnalyticsTypeDropdownStarts';
export const SELECT_ANALYTICS_TYPE_DROPDOWN_PLAYS =
  'selectAnalyticsTypeDropdownPlays';
export const SELECT_ANALYTICS_TYPE_DROPDOWN_LISTENERS =
  'selectAnalyticsTypeDropdownListeners';
export const SELECT_ANALYTICS_TYPE_DROPDOWN_ENGAGED_LISTENERS =
  'selectAnalyticsTypeDropdownEngagedListeners';
export const SELECT_ANALYTICS_TYPE_DROPDOWN_FOLLOWERS =
  'selectAnalyticsTypeDropdownFollowers';
export const SELECT_STICKER_TOOLTIP_PLAYS = 'selectStickerTooltipPlays';
export const SELECT_STICKER_TOOLTIP_STARTS = 'selectStickerTooltipStarts';
export const SELECT_STICKER_TOOLTIP_LISTENERS = 'selectStickerTooltipListeners';
export const SELECT_STICKER_TOOLTIP_FOLLOWERS = 'selectStickerTooltipFollowers';
export const SELECT_STICKER_TOOLTIP_ENGAGED_LISTENERS =
  'selectStickerTooltipEngagedListeners';
export const SELECT_EPISODES_TAB = 'selectEpisodesTab';
export const SELECT_DETAILS_TAB = 'selectDetailsTab';
export const SELECT_DOWNLOAD_PODCASTS_CSV = 'selectDownloadPodcastsCsv';
export const SELECT_DOWNLOAD_EPISODES_CSV = 'selectDownloadEpisodesCsv';
export const SELECT_CANCEL_MODAL = 'selectCancelModal';

export const SELECT_LOCALE_MAP = {
  [de_DE]: 'selectDeDe',
  [en_US]: 'selectEnUs',
  [en_GB]: 'selectEnGb',
  [es_ES]: 'selectEsEs',
  [es_MX]: 'selectEsMx',
  [fr_FR]: 'selectFrFr',
  [it_IT]: 'selectItIt',
  [ja_JP]: 'selectJaJp',
  [pt_BR]: 'selectPtBr',
};
