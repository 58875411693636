import { createAction, createSlice } from '@reduxjs/toolkit';

type AnalyticsState = {
  podcasts: Record<string, Analytic>;
  episodes: Record<string, EpisodeAnalytic>;
};

const initialState: AnalyticsState = {
  podcasts: {},
  episodes: {},
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    updatePodcastAnalytics: (state, action) => {
      const { podcastId, ...rest } = action.payload;

      state.podcasts[podcastId] = {
        ...state.podcasts[podcastId],
        ...rest,
      };
    },
    updateEpisodeAnalytics: (state, action) => {
      const episodes: Record<string, EpisodeAnalytic> = action.payload.episodes;
      Object.entries(episodes).forEach(
        ([episodeId, episode]: [string, EpisodeAnalytic]) => {
          state.episodes[episodeId] = {
            ...state.episodes[episodeId],
            ...episode,
          };
        }
      );
    },

    updateTimeFrame: (state, action) => {
      const { podcastId, timeFrame } = action.payload;
      state.podcasts[podcastId] = { timeFrame } as Analytic;
      if (podcastId === 'all') {
        const { all, ...rest } = state.podcasts;
        Object.entries(rest).forEach(
          ([podcastId, podcast]: [string, Analytic]) => {
            const { timeFrame } = podcast;
            state.podcasts[podcastId] = {
              timeFrame,
            } as Analytic;
          }
        );
      } else {
        state.episodes = {};
      }
    },

    updateEpisodeTimeFrame: (state, action) => {
      const { episodeId, timeFrame } = action.payload;
      state.episodes[episodeId] = { timeFrame } as EpisodeAnalytic;
    },
  },
});

export const getEpisodesAggregate = createAction<
  EpisodeAnalyticsAggregateActionPayload
>('analytics/getEpisodesAggregate');

export const getEpisodesAggregateFailure = createAction(
  'analytics/getEpisodesAggregateFailure'
);

export const getPodcastsAggregate = createAction<
  PodcastAnalyticsAggregateActionPayload
>('analytics/getPodcastsAggregate');

export const getPodcastsAggregateFailure = createAction(
  'analytics/getPodcastsAggregateFailure'
);

export const getPodcastsTimeSeries = createAction<
  PodcastAnalyticsActionPayload
>('analytics/getPodcastsTimeSeries');

export const getPodcastsTimeSeriesFailure = createAction(
  'analytics/getPodcastsTimeSeriesFailure'
);

export const getEpisodesTimeSeries = createAction<
  EpisodeAnalyticsActionPayload
>('analytics/getEpisodesTimeSeries');

export const getEpisodesPlaybackSegments = createAction<
  EpisodeAnalyticsPlaybackActionPayload
>('analytics/getEpisodesPlaybackSegments');

export const getEpisodesTimeSeriesFailure = createAction(
  'analytics/getEpisodesTimeSeriesFailure'
);

export const getPodcastsTotals = createAction<PodcastAnalyticsActionPayload>(
  'analytics/getPodcastsTotals'
);

export const getPodcastsTotalsFailure = createAction(
  'analytics/getPodcastsTotalsFailure'
);

export const getEpisodesTotals = createAction<EpisodeAnalyticsActionPayload>(
  'analytics/getEpisodesTotals'
);

export const getEpisodesTotalsFailure = createAction(
  'analytics/getEpisodesTotalsFailure'
);

export const {
  updatePodcastAnalytics,
  updateEpisodeAnalytics,
  updateTimeFrame,
  updateEpisodeTimeFrame,
} = analyticsSlice.actions;
export const { reducer } = analyticsSlice;
