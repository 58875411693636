import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState: User = {
  primaryContact: {},
  inBeta: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authenticated: (state, { payload }) => {
      state.authenticated = payload;
    },
    updateUserInfo: (state, { payload }) => {
      state.hasFeedClaims = payload.hasFeedClaims ?? state.hasFeedClaims;
      state.inBeta = payload.isBetaReleaseUser ?? state.inBeta;
      state.primaryContact = payload.primaryContact || state.primaryContact;
    },
    updateAuth: (state, { payload }) => {
      state.auth = payload;
    },
    updateCookiePreference: (state, { payload }) => {
      state.cookiePreference = { cookieConsent: payload };
    },
    updateViewerCountry: (state, { payload }) => {
      state.viewerCountry = payload;
    },
  },
});

export const {
  authenticated,
  updateUserInfo,
  updateAuth,
  updateCookiePreference,
  updateViewerCountry,
} = userSlice.actions;

export const updateUserCookiePreference = createAction<{ preference: string }>(
  'user/cookiePreference'
);
export const { reducer } = userSlice;
