import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Modal } from 'components/Modal';
import { Router } from 'components/Router';
import { UNKNOWN } from 'constants/metrics';
import { LanguageContext, PageContext } from 'contexts';
import { reportClickAction } from 'store/actions/metrics';
import { initialize } from 'store/slices/initialize';
import { localeSelector } from 'store/selectors/locale';
import { modalNameSelector } from 'store/selectors/modal';
import { languagesLoader, messageLoader } from 'utils/translation';
import en_US_translations from '@translations/translation-en-US.puff.json';
import en_US_language_translations from '@translations/language.translation-en-US.puff.json';
import useGetLocaleMessages from 'hooks/getLocaleMessages';
import './styles.scss';
import useGetUserCookiePreference from 'hooks/getUserCookiePreference';

export const App = () => {
  const dispatch = useDispatch();
  useGetUserCookiePreference();
  const modal = useSelector(modalNameSelector);
  const locale = useSelector(localeSelector);
  const messages = useGetLocaleMessages(messageLoader, en_US_translations);
  const localizedLanguages = useGetLocaleMessages(
    languagesLoader,
    en_US_language_translations
  );

  const dispatchClickAction = (event: Event) => {
    const target = event.target as HTMLElement;
    const dataFields = target.closest('[data-id]')?.getAttribute('data-id');

    if (dataFields) {
      const [dataId, dataPage] = dataFields.split('-');
      dispatch(reportClickAction({ dataId, dataPage }));
    }
  };

  useEffect(() => {
    dispatch(initialize());
    document.body.addEventListener('click', dispatchClickAction, {
      passive: true,
    });
    document.body.addEventListener('musicActivate', dispatchClickAction, {
      passive: true,
    });

    return () => {
      document.body.removeEventListener('click', dispatchClickAction);
      document.body.removeEventListener('musicActivate', dispatchClickAction);
    };
  }, []);

  return (
    <PageContext.Provider value={UNKNOWN}>
      <LanguageContext.Provider value={localizedLanguages}>
        <IntlProvider messages={messages} locale={locale} defaultLocale="en-US">
          <BrowserRouter basename="/">
            <div className={`App__container${modal ? ' blur' : ''}`}>
              <Router />
            </div>
            <Modal />
          </BrowserRouter>
        </IntlProvider>
      </LanguageContext.Provider>
    </PageContext.Provider>
  );
};
