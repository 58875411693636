import { createSlice } from '@reduxjs/toolkit';
import { ConfirmClaimErrorTypes } from 'constants/confirmClaimErrors';
import { ERROR, SUCCESS } from 'constants/status';

type ClaimTypes = {
  status: typeof SUCCESS | typeof ERROR | '';
  errorCode: ConfirmClaimErrorTypes;
};

const initialState: ClaimTypes = {
  status: '',
  errorCode: '',
};

const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    claim: (_state, action) => action.payload,
  },
});

export const { claim } = claimSlice.actions;
export const { reducer } = claimSlice;
