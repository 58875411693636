import { createAction, createSlice } from '@reduxjs/toolkit';
import { PodcastsState } from 'types/podcasts';
import { updateTimeFrame } from './analytics';

const initialState: PodcastsState = {
  hasNextPage: false,
  cursor: '',
  podcastIds: [],
  currentPodcastId: '',
};

const podcastsSlice = createSlice({
  name: 'podcasts',
  initialState,
  reducers: {
    updatePodcasts: (state, action) => {
      const set =
        state.cursor === ''
          ? new Set([...action.payload.podcastIds, ...state.podcastIds])
          : new Set([...state.podcastIds, ...action.payload.podcastIds]);
      state.hasNextPage = action.payload.hasNextPage;
      state.cursor = action.payload.cursor;
      state.podcastIds = Array.from(set);
    },
    updateCurrentPodcastId: (state, action) => {
      state.currentPodcastId = action.payload;
    },
    clearCursor: (state) => {
      state.cursor = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTimeFrame, (state) => {
      state.cursor = '';
      state.podcastIds = [];
    });
  },
});

export const getPodcasts = createAction<{ pageSize: number }>(
  'podcasts/getClaimed'
);
export const getPodcastDetails = createAction<{ ids: Array<string> }>(
  'podcasts/getDetails'
);
export const hidePodcast = createAction<{ id: string }>('podcasts/hidePodcast');
export const unhidePodcast = createAction<{ id: string }>(
  'podcasts/unhidePodcast'
);
export const unclaimPodcast = createAction<{ id: string }>(
  'podcasts/unclaimPodcast'
);

export const {
  updatePodcasts,
  updateCurrentPodcastId,
  clearCursor,
} = podcastsSlice.actions;

export const { reducer } = podcastsSlice;
