import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { podcastDetailsLoadingSelector } from 'store/selectors/loading';
import { getPodcastDetails } from 'store/slices/podcasts';
import { podcastIdListSelector } from 'store/selectors/podcasts';
import { localeSelector } from 'store/selectors/locale';

const useGetPodcastDetails = (id: string) => {
  const dispatch = useDispatch();
  const podcastIds = useSelector(podcastIdListSelector);
  const podcastDetailsLoading = useSelector(podcastDetailsLoadingSelector);
  const locale = useSelector(localeSelector);

  useEffect(() => {
    if (!podcastIds.includes(id)) {
      dispatch(getPodcastDetails({ ids: [id] }));
    }
  }, [id, locale]);

  return {
    podcastDetailsLoading: podcastDetailsLoading,
  };
};

export default useGetPodcastDetails;
