import { createSlice } from '@reduxjs/toolkit';

const initialState: Banners = {
  displayDataOutageBanner: false,
};

const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    updateDataOutageBannerStatus: (state, action) => {
      state.displayDataOutageBanner = action.payload;
    },
  },
});

export const { updateDataOutageBannerStatus } = bannersSlice.actions;
export const { reducer } = bannersSlice;
