import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { MusicLink } from 'components/MusicLink';
import { TOS_ROUTE } from 'constants/routes';
import { GO_TERMS_AND_CONDITIONS } from 'constants/metrics';
import { PageContext } from 'contexts';

const TermsAndConditionsLink = () => {
  const page = useContext(PageContext);
  return (
    <MusicLink
      kind="accent"
      href={TOS_ROUTE}
      data-id={`${GO_TERMS_AND_CONDITIONS}-${page}`}
    >
      <FormattedMessage id="termsAndConditions" />
    </MusicLink>
  );
};

export default TermsAndConditionsLink;
