import { all } from 'redux-saga/effects';
import { analyticsSagas } from './analytics';
import { episodeSagas } from './episodes';
import { feedSagas } from './feed';
import { initializeSagas } from './initialize';
import { localeSagas } from './locale';
import { metricsSagas } from './metrics';
import { podcastSagas } from './podcasts';

export function* rootSaga() {
  yield all([
    ...analyticsSagas,
    ...episodeSagas,
    ...feedSagas,
    ...initializeSagas,
    ...localeSagas,
    ...metricsSagas,
    ...podcastSagas,
  ]);
}
