export const EPISODE_LIST = 'EPISODE_LIST';
export const PODCAST_DETAILS = 'PODCAST_DETAILS';
export const PODCAST_LIST = 'PODCAST_LIST';
export const UNHIDE_PODCAST = 'UNHIDE_PODCAST';
export const HIDE_PODCAST = 'HIDE_PODCAST';
export const UPDATE_URL = 'UPDATE_URL';
export const UNCLAIM_PODCAST = 'UNCLAIM_PODCAST';
export const FORBIDDEN = 'FORBIDDEN';
export const NOT_FOUND = 'NOT_FOUND';
export const UNEXPECTED = 'UNEXPECTED';
export const MULTIVARIANT_PODCAST = 'MULTIVARIANT_PODCAST';
