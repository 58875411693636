import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { reducer as analytics } from 'store/slices/analytics';
import { reducer as apiStatus } from 'store/slices/apiStatus';
import { reducer as banners } from 'store/slices/banners';
import { reducer as claim } from 'store/slices/claim';
import { reducer as entities } from 'store/slices/entities';
import { reducer as episodes } from 'store/slices/episodes';
import { reducer as feed } from 'store/slices/feed';
import { reducer as modal } from 'store/slices/modal';
import { reducer as user } from 'store/slices/user';
import { reducer as loading } from 'store/slices/loading';
import { reducer as locale } from 'store/slices/locale';
import { reducer as podcasts } from 'store/slices/podcasts';
import { reducer as sideNav } from 'store/slices/sideNav';
import { rootSaga } from 'store/sagas';
import { productionStage } from 'utils/url';

const rootReducer = combineReducers({
  analytics,
  apiStatus,
  banners,
  claim,
  entities,
  episodes,
  feed,
  modal,
  user,
  loading,
  locale,
  podcasts,
  sideNav,
});

const sagaMiddleware = createSagaMiddleware();
let middleware = applyMiddleware(sagaMiddleware);

if (!productionStage()) {
  middleware = composeWithDevTools(middleware);
}

const store = createStore(rootReducer, middleware);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export default store;
