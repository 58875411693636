import en_US from 'date-fns/locale/en-US';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { MusicButton } from 'components/MusicButton';
import BlurredModal from 'components/Modal/BlurredModal';
import { CUSTOM_TIME_FRAME } from 'constants/analytics';
import { LOCALE_DATE_MAP } from 'constants/locales';
import {
  DATE_RANGE_MODAL,
  SELECT_CANCEL_MODAL,
  SELECT_CONFIRM_MODAL,
} from 'constants/metrics';
import { usePageView } from 'hooks/pageView';
import { localeSelector } from 'store/selectors/locale';
import { closeModal } from 'store/slices/modal';
import {
  YESTERDAY,
  LAST_WEEK,
  PODCASTS_LAUNCH_DATE,
  isTheSameTimeFrame,
} from 'utils/date';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'assets/sass/bauhausReactDatePickerStyles.scss';
import './styles.scss';

const DateRangeModal = ({
  startTime,
  endTime,
  updateTimeFrameReducer,
  confirmDateRangePayloadId,
}: DateRangeModalProps) => {
  usePageView(DATE_RANGE_MODAL);
  const dispatch = useDispatch();
  const locale = useSelector(localeSelector);

  const [selectedRange, setSelectedRange] = useState<Range>({
    key: 'selection',
    startDate: new Date(startTime) || YESTERDAY,
    endDate: new Date(endTime) || LAST_WEEK,
  });
  const dateLocalization = LOCALE_DATE_MAP[locale] || en_US;

  const confirmDateRangePayloadTimeFrame = {
    timeFrame: {
      startTime: selectedRange.startDate?.toISOString(),
      endTime: selectedRange.endDate?.toISOString(),
      updateSource: CUSTOM_TIME_FRAME,
    },
  };

  const confirmDateRangePayload = {
    ...confirmDateRangePayloadId,
    ...confirmDateRangePayloadTimeFrame,
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  const onConfirm = () => {
    dispatch(closeModal());
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (
      !isTheSameTimeFrame(
        new Date(startTime),
        new Date(endTime),
        selectedRange.startDate,
        selectedRange.endDate
      )
    ) {
      dispatch(updateTimeFrameReducer(confirmDateRangePayload));
    }
  };

  const onChange = (rangesByKey: RangeKeyDict) => {
    if (rangesByKey.selection) {
      setSelectedRange({
        key: 'selection',
        startDate: rangesByKey.selection.startDate,
        endDate: rangesByKey.selection.endDate,
      });
    }
  };

  return (
    <BlurredModal cancelButton={false}>
      <div className="DateRange__body">
        <DateRange
          rangeColors={['rgba(26,210,251, .75)']}
          locale={dateLocalization}
          minDate={PODCASTS_LAUNCH_DATE}
          maxDate={YESTERDAY}
          onChange={onChange}
          ranges={[selectedRange]}
        />
        <div className="DateRange__button-container">
          <MusicButton
            variant="outline"
            size="medium"
            data-id={`${SELECT_CANCEL_MODAL}-${DATE_RANGE_MODAL}`}
            onMusicActivate={onClose}
          >
            <FormattedMessage id="Cancel" />
          </MusicButton>
          <MusicButton
            size="medium"
            data-id={`${SELECT_CONFIRM_MODAL}-${DATE_RANGE_MODAL}`}
            onMusicActivate={() => onConfirm()}
          >
            <FormattedMessage id="Confirm" />
          </MusicButton>
        </div>
      </div>
    </BlurredModal>
  );
};

export default DateRangeModal;
