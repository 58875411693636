import { useDispatch, useSelector } from 'react-redux';
import { usePageView } from 'hooks/pageView';
import {
  COOKIE_CONSENT_MODAL,
  SELECT_ACCEPT_COOKIE,
  SELECT_CONTINUE_WITHOUT_CONSENTING_COOKIE,
} from 'constants/metrics';
import { closeModal, openModal } from 'store/slices/modal';
import { updateUserCookiePreference } from 'store/slices/user';
import { MusicButton } from 'components/MusicButton';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { localeSelector } from 'store/selectors/locale';
import './styles.scss';
import ScrollableBodyModal from '../ScrollableBodyModal';
import en_US_cookie_preference_messages from '@translations/messages-en-US.puff.json';
import { cookiePreferenceLoader } from 'utils/translation';
import useGetLocaleMessages from 'hooks/getLocaleMessages';
import { CookieConsentBody } from './CoookieConsentBody';
import { getCookiePrefUrl } from 'utils/url';
import { MusicLink } from '@amzn/DMWebUIComponents/dist/react/components';
import { modalPreviousNameSelector } from 'store/selectors/modal';
import {
  formatCookiePreferenceMessages,
  getTags,
} from 'utils/cookiePreference';

export const CookieConsentModal = () => {
  const dispatch = useDispatch();
  const locale = useSelector(localeSelector);
  const modalPreviousName = useSelector(modalPreviousNameSelector);

  const rawCookiePreferenceMessages = useGetLocaleMessages(
    cookiePreferenceLoader,
    en_US_cookie_preference_messages
  );
  // format the a tags to be usable for react-intl:
  //  <a>Cookie Notice</a -> {CookieNotice}
  const cookiePreferenceMessages = formatCookiePreferenceMessages(
    rawCookiePreferenceMessages
  );
  // Generate a map to rebuild the links in the translation in CookieConsentBody
  const tagObjects = getTags(
    rawCookiePreferenceMessages.resources.cookieConsentLegaleseContent
  );

  usePageView(COOKIE_CONSENT_MODAL);

  const handleClosingModal = () => {
    if (modalPreviousName) {
      dispatch(openModal({ name: modalPreviousName, previousName: '' }));
    } else {
      dispatch(closeModal());
    }
  };

  const acceptCookie = () => {
    dispatch(updateUserCookiePreference({ preference: 'ACCEPT-ALL' }));
    handleClosingModal();
  };

  const rejectCookie = () => {
    dispatch(updateUserCookiePreference({ preference: 'REJECT-ALL' }));
    handleClosingModal();
  };

  const Title = (
    <h4 className="CookieConsent__title">
      <FormattedMessage id="cookieConsentLegaleseHeaderText" />
    </h4>
  );

  const Actions = (
    <div className="CookieConsent__actions">
      <MusicButton
        variant="solid"
        onMusicActivate={acceptCookie}
        className="CookieConsent__button MusicButton__wide"
        data-id={`${SELECT_ACCEPT_COOKIE}-${COOKIE_CONSENT_MODAL}`}
      >
        <FormattedMessage id="cookieConsentAcceptButtonText" />
      </MusicButton>
      <MusicButton
        variant="outline"
        onMusicActivate={rejectCookie}
        className="CookieConsent__button MusicButton__wide"
        data-id={`${SELECT_CONTINUE_WITHOUT_CONSENTING_COOKIE}-${COOKIE_CONSENT_MODAL}`}
      >
        <FormattedMessage id="cookieConsentDenyButtonText" />
      </MusicButton>
      <MusicLink
        href={getCookiePrefUrl(locale)}
        kind="accent"
        className="CookieConsent__customize-cookie"
      >
        <FormattedMessage id="cookieConsentCustomizeButtonText" />
      </MusicLink>
    </div>
  );

  return (
    <IntlProvider
      messages={cookiePreferenceMessages.resources}
      locale={locale}
      defaultLocale="en-US"
    >
      <ScrollableBodyModal
        title={Title}
        body={<CookieConsentBody tagObjects={tagObjects} />}
        actions={Actions}
        cancelButton={false}
        maxBodyHeight="75%"
      />
    </IntlProvider>
  );
};
