import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { MusicLink } from '@amzn/DMWebUIComponents/dist/react/components';
import { signInToDifferentAccountUrl } from 'constants/links';
import { SUBMIT_RSS_ROUTE } from 'constants/routes';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/selectors/locale';
import { GO_DIFFERENT_ACCOUNT } from 'constants/metrics';
import { PageContext } from 'contexts';

const DifferentAccount = () => {
  const page = useContext(PageContext);
  const locale = useSelector(localeSelector);
  return (
    <MusicLink
      kind="accent"
      href={signInToDifferentAccountUrl(locale, SUBMIT_RSS_ROUTE)}
      data-id={`${GO_DIFFERENT_ACCOUNT}-${page}`}
    >
      <FormattedMessage id="differentAccount" />
    </MusicLink>
  );
};

export default DifferentAccount;
