import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { getWithCredentials, postWithCredentials } from 'api';
import {
  COOKIE_PREFERENCE_FEATURE,
  EXCHANGE_TOKEN_FEATURE,
} from 'constants/features';
import { PODCASTER_PORTAL_ENCRYPTED_DEVICE_TYPE_ID } from 'constants/metrics';
import {
  EXCHANGE_TOKEN_API,
  GET_USER_PREFERENCE_AUTH_API,
  GET_USER_PREFERENCE_UNAUTH_API,
  SUBMIT_USER_PREFERENCE_AUTH_API,
  SUBMIT_USER_PREFERENCE_UNAUTH_API,
  USER_INFO_API,
} from 'constants/routes';
import { initialize } from 'store/slices/initialize';
import { checkFeedClaims } from 'store/slices/feed';
import {
  authenticated,
  updateAuth,
  updateUserInfo,
  updateCookiePreference,
  updateUserCookiePreference,
  updateViewerCountry,
} from 'store/slices/user';
import { authenticatedSelector } from 'store/selectors/user';
import { checkForFeature } from 'utils/features';
import { updateSession } from 'utils/session';
import { getQueryStringParams, queryStringWithoutCSRF } from 'utils/url';
import { UNKNOWN } from 'constants/cookies';

export function* exchangeToken(): any {
  const response = yield call(postWithCredentials, {
    path: EXCHANGE_TOKEN_API,
    body: {
      deviceType: PODCASTER_PORTAL_ENCRYPTED_DEVICE_TYPE_ID,
    },
  });

  if (response.ok) {
    const { token, expirationTimeInMilliseconds } = yield call([
      response,
      'json',
    ]);
    yield put(
      updateAuth({ token, expirationTime: expirationTimeInMilliseconds })
    );
    return token;
  }
}

function* auth(): any {
  try {
    const response = yield call(getWithCredentials, {
      path: USER_INFO_API,
    });

    if (response.ok) {
      const json = yield call([response, 'json']);

      yield put(updateUserInfo(json));
      yield put(authenticated(true));

      if (checkForFeature(EXCHANGE_TOKEN_FEATURE)) {
        yield call(exchangeToken);
      }
      return;
    }
  } catch (error) {
    yield put(authenticated(false));
    yield put(updateUserInfo({ hasFeedClaims: false }));
  }
  yield put(authenticated(false));
  yield put(updateUserInfo({ hasFeedClaims: false }));
}

function checkCsrf() {
  const [csrfToken] = getQueryStringParams(['csrf-token']);

  if (csrfToken) {
    const cleanToken = csrfToken.replace(new RegExp(' ', 'g'), '+');
    localStorage.setItem('csrfToken', cleanToken);

    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}${queryStringWithoutCSRF()}`
    );
  }
}

export function* getCookiePreference(): any {
  const cookieConsentFromStorage = sessionStorage.getItem('cookieConsent');
  const viewerCountryFromStorage = sessionStorage.getItem('viewerCountry');
  if (
    cookieConsentFromStorage &&
    viewerCountryFromStorage &&
    cookieConsentFromStorage !== UNKNOWN
  ) {
    yield put(updateViewerCountry(viewerCountryFromStorage));
    yield put(updateCookiePreference(cookieConsentFromStorage));
    return;
  }

  const GET_USER_PREFERENCE_API_PATH = (yield select(authenticatedSelector))
    ? GET_USER_PREFERENCE_AUTH_API
    : GET_USER_PREFERENCE_UNAUTH_API;

  try {
    const response = yield call(getWithCredentials, {
      path: GET_USER_PREFERENCE_API_PATH,
    });

    if (response.ok) {
      const json = yield call([response, 'json']);
      const { cookieConsent, viewerCountry } = json;

      yield put(updateViewerCountry(viewerCountry));
      yield put(updateCookiePreference(cookieConsent));

      sessionStorage.setItem('viewerCountry', viewerCountry);
      sessionStorage.setItem('cookieConsent', cookieConsent);
    } else {
      sessionStorage.setItem('cookieConsent', UNKNOWN);
    }
  } catch (error) {
    sessionStorage.setItem('cookieConsent', UNKNOWN);
  }
}

export function* setCookiePreference(action: {
  payload: { preference: string };
  type: string;
}): any {
  const preference = action.payload.preference;
  const SUBMIT_USER_PREFERENCE_API_PATH = (yield select(authenticatedSelector))
    ? SUBMIT_USER_PREFERENCE_AUTH_API
    : SUBMIT_USER_PREFERENCE_UNAUTH_API;

  sessionStorage.setItem('cookieConsent', preference);
  yield put(updateCookiePreference({ cookieConsent: preference }));

  yield call(postWithCredentials, {
    path: SUBMIT_USER_PREFERENCE_API_PATH,
    body: { cookieConsent: preference },
  });
}

export function* getCredentials(): any {
  yield all([call(auth), call(updateSession)]);
  // need auth and updateSession to complete before calling getCookiePreference
  if (checkForFeature(COOKIE_PREFERENCE_FEATURE)) {
    yield call(getCookiePreference);
  }
}

function initalizeSessionListener() {
  document.body.addEventListener('click', updateSession, { passive: true });
  document.body.addEventListener('musicActivate', updateSession, {
    passive: true,
  });
  document.body.addEventListener('change', updateSession, { passive: true });
}

export const initializeSagas = [
  takeLeading(initialize.toString(), getCredentials),
  takeLeading(initialize.toString(), checkCsrf),
  takeLeading(initialize.toString(), initalizeSessionListener),
  takeLatest(checkFeedClaims.toString(), auth),
  takeLatest(updateUserCookiePreference.toString(), setCookiePreference),
];
