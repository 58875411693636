import { call, put } from 'redux-saga/effects';
import { postWithCredentials } from 'api';
import { INVALID_FEED } from 'constants/feed';
import { CLAIMS_VALIDATE_API } from 'constants/routes';
import { feed, validateFeed } from 'store/slices/feed';
import { getQueryStringParams } from 'utils/url';
import { openModal } from 'store/slices/modal';
import { CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME } from 'constants/modals';
import { normalizeFeed } from 'utils/normalizer/feed';
import { invalidReasons } from './invalidReason';

export function* validateFeedSaga(
  action: typeof validateFeed,
  retryCount = 1
): any {
  const [rssUrl, countryOfOrigin] = getQueryStringParams([
    'rssFeed',
    'country',
  ]);

  try {
    const response = yield call(postWithCredentials, {
      path: CLAIMS_VALIDATE_API,
      body: {
        rssUrl: rssUrl?.trim(),
      },
    });

    if (response.ok) {
      const json = yield call([response, 'json']);
      const normalizedFeed = normalizeFeed(json, rssUrl, countryOfOrigin);
      yield put(feed(normalizedFeed));
    } else {
      switch (response.status) {
        case 400: {
          const json = yield call([response, 'json']);
          yield put(
            feed({
              status: INVALID_FEED,
              invalidReason: invalidReasons(
                json.errorCode,
                json.podcastProvider
              ),
            })
          );
          break;
        }
        case 401:
        case 403: {
          yield put(
            openModal({ name: CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME })
          );
          break;
        }
        default:
          if (retryCount < 2) {
            yield call(validateFeedSaga, action, retryCount + 1);
          } else {
            yield put(feed({ status: INVALID_FEED }));
          }
      }
    }
  } catch (error) {
    yield put(feed({ status: INVALID_FEED }));
  }
}
