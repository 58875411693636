import { createSlice } from '@reduxjs/toolkit';

type LoadingType = {
  emailClaim?: boolean;
  podcastList?: boolean;
  podcastDetails?: boolean;
  episodeDetails?: boolean;
  podcastTotals?: boolean;
  episodeTotals?: boolean;
  updateFeedUrl?: boolean;
  episodesList?: boolean;
  timeSeries?: { [key: string]: boolean };
  aggregate?: boolean;
  episodePlaybacks?: boolean;
};

const initialState: LoadingType = {};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    emailClaimLoading: (state, action) => {
      state.emailClaim = action.payload;
    },
    updatePodcastListLoading: (state, action) => {
      state.podcastList = action.payload;
    },
    podcastDetailsLoading: (state, action) => {
      state.podcastDetails = action.payload;
    },
    episodeDetailsLoading: (state, action) => {
      state.episodeDetails = action.payload;
    },
    podcastTotalsLoading: (state, action) => {
      state.podcastTotals = action.payload;
    },
    episodeTotalsLoading: (state, action) => {
      state.episodeTotals = action.payload;
    },
    updateFeedUrlLoading: (state, action) => {
      state.updateFeedUrl = action.payload;
    },
    updateEpisodesListLoading: (state, action) => {
      state.episodesList = action.payload;
    },
    updateTimeSeriesLoading: (state, action) => {
      state.timeSeries = { ...state.timeSeries, ...action.payload };
    },
    updateAggregateLoading: (state, action) => {
      state.aggregate = action.payload;
    },
    updateEpisodePlaybacksLoading: (state, action) => {
      state.episodePlaybacks = action.payload;
    },
  },
});

export const {
  emailClaimLoading,
  updatePodcastListLoading,
  podcastDetailsLoading,
  episodeDetailsLoading,
  podcastTotalsLoading,
  episodeTotalsLoading,
  updateFeedUrlLoading,
  updateEpisodesListLoading,
  updateTimeSeriesLoading,
  updateAggregateLoading,
  updateEpisodePlaybacksLoading,
} = loadingSlice.actions;
export const { reducer } = loadingSlice;
