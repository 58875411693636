import {
  en_US,
  de_DE,
  en_GB,
  es_ES,
  es_MX,
  fr_FR,
  it_IT,
  ja_JP,
  pt_BR,
  LOCALE_TYPES,
} from 'constants/locales';

export const AUDIENCE_INSIGHTS_BETA_EMAIL = 'am4p-analytics-beta@amazon.com';
export const PODCASTERS_EMAIL = 'podcasters@amazon.com';
export const PODCASTERS_DE_EMAIL = 'podcasters-de@amazon.com';
export const PODCASTERS_EU_EMAIL = 'podcasters-eu@amazon.com';
export const PODCASTERS_JP_EMAIL = 'jp-podcasters@amazon.com';
export const PODCASTERS_BR_EMAIL = 'podcasters-br@amazon.com';
export const PODCASTERS_MX_EMAIL = 'podcasters-mx@amazon.com';
export const PODCASTERS_IN_EMAIL = 'podcasters-in@amazon.com';
export const PODCASTERS_CA_EMAIL = 'podcasters-ca@amazon.com';
export const PODCASTERS_UK_EMAIL = 'podcasters-uk@amazon.com';
export const PODCASTERS_FR_EMAIL = 'podcasters-fr@amazon.com';
export const PODCASTERS_IT_EMAIL = 'podcasters-it@amazon.com';
export const PODCASTERS_ES_EMAIL = 'podcasters-es@amazon.com';
export const PODCASTERS_ANZ_EMAIL = 'podcasters-anz@amazon.com';
export const PODCASTERS_PROMO_EMAIL = 'podcastpromo@amazon.com';
export const TOS_CONTRACTS_LEGAL_EMAIL = 'contracts-legal@amazon.com';
export const TOS_DIGITAL_MUSIC_EMAIL = 'digital-music@amazon.com';
export const AM4P_PARTNERSHIPS_EMAIL = 'am4p-partnerships@amazon.com';
export const AM4P_PRIME_EMAIL = 'am4p-prime@amazon.com';

export const PODCASTER_SUPPORT_EMAILS: Record<
  LOCALE_TYPES,
  PODCASTER_SUPPORT_EMAIL_TYPES
> = {
  [de_DE]: PODCASTERS_EU_EMAIL,
  [en_US]: PODCASTERS_EMAIL,
  [en_GB]: PODCASTERS_EU_EMAIL,
  [es_ES]: PODCASTERS_EU_EMAIL,
  [fr_FR]: PODCASTERS_EU_EMAIL,
  [it_IT]: PODCASTERS_EU_EMAIL,
  [pt_BR]: PODCASTERS_BR_EMAIL,
  [ja_JP]: PODCASTERS_JP_EMAIL,
  [es_MX]: PODCASTERS_MX_EMAIL,
};

export type PODCASTER_SUPPORT_EMAIL_TYPES =
  | typeof PODCASTERS_EMAIL
  | typeof PODCASTERS_MX_EMAIL
  | typeof PODCASTERS_IN_EMAIL
  | typeof PODCASTERS_CA_EMAIL
  | typeof PODCASTERS_BR_EMAIL
  | typeof PODCASTERS_JP_EMAIL
  | typeof PODCASTERS_EU_EMAIL
  | typeof AM4P_PRIME_EMAIL;
