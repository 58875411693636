import { PayloadAction } from '@reduxjs/toolkit';
import { call, select, takeEvery } from 'redux-saga/effects';
import { reportClientAction, reportClientActionClick } from 'api/metrics';
import { FIVE_SECONDS } from 'constants/time';
import { reportClickAction, reportPageViewAction } from 'store/actions/metrics';
import {
  authTokenSelector,
  authExpirationTimeSelector,
  authenticatedSelector,
} from 'store/selectors/user';
import { exchangeToken } from 'store/sagas/initialize';

export function* getToken(): any {
  const userAuthenticated = yield select(authenticatedSelector);
  let token = yield select(authTokenSelector);
  const expirationTime = yield select(authExpirationTimeSelector);
  const fiveSecondsOrLessFromExpiration =
    Date.now() - FIVE_SECONDS > expirationTime;

  if (userAuthenticated && fiveSecondsOrLessFromExpiration) {
    token = yield call(exchangeToken);
  }

  return token;
}

export function* reportClickSaga({
  payload: { dataId, dataPage },
}: PayloadAction<{ dataId: string; dataPage: string }>): any {
  const token = yield call(getToken);
  reportClientActionClick(dataId, dataPage, token);
}

export function* reportPageViewSaga(action: PayloadAction<Array<any>>): any {
  const token = yield call(getToken);
  reportClientAction(action.payload, token);
}

export const metricsSagas = [
  takeEvery(reportClickAction.toString(), reportClickSaga),
  takeEvery(reportPageViewAction.toString(), reportPageViewSaga),
];
