import { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { HOME_ROUTE, PODCASTS_ROUTE } from 'constants/routes';
import { hasFeedClaimsSelector } from 'store/selectors/user';

const Home = lazy(() => import('components/Home'));

const HomeRoute = () => {
  const hasFeedClaims = useSelector(hasFeedClaimsSelector);

  return (
    <Route
      path={HOME_ROUTE}
      render={() =>
        hasFeedClaims ? <Redirect to={PODCASTS_ROUTE} /> : <Home />
      }
    />
  );
};

export default HomeRoute;
