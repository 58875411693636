import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { postWithCredentials } from 'api';
import { CHRONOLOGICAL_NEWEST_FIRST, ERROR } from 'constants/api';
import { DEFAULT_PAGE_SIZE } from 'constants/podcasts';
import {
  EPISODE_DETAILS,
  EPISODE_LIST,
  FORBIDDEN,
  NOT_FOUND,
  UNEXPECTED,
} from 'constants/episodeFailures';
import { EPISODE_LIST_API, EPISODE_DETAILS_API } from 'constants/routes';
import { episodeListCursorSelector } from 'store/selectors/episodes';
import { addApiStatus } from 'store/slices/apiStatus';
import { updateCurrentPodcastId } from 'store/slices/podcasts';
import {
  getEpisodes,
  updateEpisodeEntities,
  updatePodcastEpisodes,
} from 'store/slices/entities';
import {
  episodeDetailsLoading,
  updateEpisodesListLoading,
} from 'store/slices/loading';
import { normalizeEpisodes } from 'utils/normalizer/episodes';
import { getEpisodeDetails } from 'store/slices/episodes';

export function* fetchEpisodes({
  payload: { pageSize, podcastId },
}: PayloadAction<EpisodesActionPayload>): any {
  if (podcastId) {
    yield put(updateEpisodesListLoading(true));
    const episodeListCursor = yield select(
      episodeListCursorSelector(podcastId)
    );

    try {
      const response = yield call(postWithCredentials, {
        path: EPISODE_LIST_API,
        body: {
          pagination: {
            pageToken: episodeListCursor,
            pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
            podcastId,
            sortByProperty: CHRONOLOGICAL_NEWEST_FIRST,
          },
        },
      });

      const { data } = yield call([response, 'json']);
      const episodes = normalizeEpisodes(data);
      yield put(updateEpisodeEntities(episodes.entities));
      yield put(updatePodcastEpisodes({ podcastId, episodes }));
    } catch (e) {
      yield put(addApiStatus({ type: ERROR, code: EPISODE_LIST }));
    }
    yield put(updateEpisodesListLoading(false));
  }
}

export function* fetchEpisodeDetails(action: {
  payload: { episodeId: string };
  type: string;
}): any {
  yield put(episodeDetailsLoading(true));
  try {
    const response = yield call(postWithCredentials, {
      path: EPISODE_DETAILS_API,
      body: {
        episodeId: action.payload.episodeId,
      },
    });

    if (response.ok) {
      const json = yield response.json();
      const episodeMetadata = json.metadata;

      if (episodeMetadata) {
        yield put(
          updateEpisodeEntities({ [action.payload.episodeId]: episodeMetadata })
        );
        yield put(updateCurrentPodcastId(episodeMetadata.podcastId));
      } else {
        yield put(addApiStatus({ type: ERROR, code: EPISODE_DETAILS }));
      }
    } else {
      switch (response.status) {
        case 403:
          yield put(addApiStatus({ type: ERROR, code: FORBIDDEN }));
          break;
        case 404:
          yield put(addApiStatus({ type: ERROR, code: NOT_FOUND }));
          break;
        default:
          yield put(addApiStatus({ type: ERROR, code: UNEXPECTED }));
      }
    }
  } catch (e) {
    yield put(addApiStatus({ type: ERROR, code: EPISODE_DETAILS }));
  }
  yield put(episodeDetailsLoading(false));
}

export const episodeSagas = [
  takeEvery(getEpisodes.toString(), fetchEpisodes),
  takeLatest(getEpisodeDetails.toString(), fetchEpisodeDetails),
];
