import Cookies from 'js-cookie';
import { ONE_YEAR } from 'constants/time';
import { SESSION_INFO_API } from 'constants/routes';
import {
  PODCASTER_SESSION_ID,
  SESSION_ID,
  UNIQUE_BROWSER_ID,
} from 'constants/cookies';
import { call } from 'redux-saga/effects';
import { postWithCredentials } from '../api';

export function* updateSession(): any {
  let sessionId = Cookies.get(SESSION_ID);
  let uniqueBrowserId = Cookies.get(UNIQUE_BROWSER_ID);

  const oneYearFromNow = Date.now() + ONE_YEAR;
  if (!sessionId || !uniqueBrowserId) {
    const response = yield call(postWithCredentials, {
      body: {},
      path: SESSION_INFO_API,
    });

    if (response.ok) {
      const json = yield call([response, 'json']);
      sessionId = json.sessionId;
      if (sessionId) {
        Cookies.set(SESSION_ID, sessionId, {
          domain: '.amazon.com',
          expires: new Date(oneYearFromNow),
          secure: true,
        });
      }

      uniqueBrowserId = json.uniqueBrowserId;
      if (uniqueBrowserId) {
        Cookies.set(UNIQUE_BROWSER_ID, uniqueBrowserId, {
          domain: '.amazon.com',
          expires: new Date(oneYearFromNow),
          secure: true,
        });
      }
    }
  }

  const podcasterSessionId = Cookies.get(PODCASTER_SESSION_ID);

  if (!podcasterSessionId && sessionId) {
    yield Cookies.set(PODCASTER_SESSION_ID, sessionId, {
      expires: new Date(oneYearFromNow),
      secure: true,
    });
  }
}
