import { createSelector } from 'reselect';
import { RootState } from 'store';
import { currentPodcastIdSelector } from './podcasts';
import { currentEpisodeIdSelector } from './episodes';
import { LAST_WEEK, YESTERDAY } from 'utils/date';
import { PRESET_TIME_FRAME } from 'constants/analytics';

const EMPTY_OBJECT = {};

export const analyticsSelector = (state: RootState) => state.analytics;

export const startTimeFrameSelector = (podcastId: string) => (
  state: RootState
) =>
  state.analytics.podcasts[podcastId]?.timeFrame?.startTime ||
  LAST_WEEK.toISOString();

export const endTimeFrameSelector = (podcastId: string) => (state: RootState) =>
  state.analytics.podcasts[podcastId]?.timeFrame?.endTime ||
  YESTERDAY.toISOString();

export const currentPodcastAnalyticsSelector = createSelector(
  [currentPodcastIdSelector, analyticsSelector],
  (podcastId, analytics) => analytics.podcasts[podcastId] || EMPTY_OBJECT
);

export const currentEpisodeAnalyticsSelector = createSelector(
  [currentEpisodeIdSelector, analyticsSelector],
  (episodeId, analytics) => analytics.episodes[episodeId] || EMPTY_OBJECT
);

export const currentEpisodePlaybackSegmentsSelector = createSelector(
  [currentEpisodeAnalyticsSelector],
  (episodeAnalytics) => episodeAnalytics.playbackSegments || []
);

export const currentPodcastTimeFrameUpdateSourceSelector = createSelector(
  [currentPodcastAnalyticsSelector],
  (currentPodcast) =>
    currentPodcast.timeFrame?.updateSource || PRESET_TIME_FRAME
);

export const currentPodcastStartTimeFrameSelector = createSelector(
  [currentPodcastAnalyticsSelector],
  (currentPodcast) =>
    currentPodcast.timeFrame?.startTime || LAST_WEEK.toISOString()
);

export const currentPodcastEndTimeFrameSelector = createSelector(
  [currentPodcastAnalyticsSelector],
  (currentPodcast) =>
    currentPodcast.timeFrame?.endTime || YESTERDAY.toISOString()
);

export const currentPodcastStartsSelector = createSelector(
  [currentPodcastAnalyticsSelector],
  (currentPodcast) => currentPodcast.starts
);

export const currentPodcastStartsTimeSeriesSelector = createSelector(
  [currentPodcastAnalyticsSelector],
  (currentPodcast) => currentPodcast.startsTimeSeries
);

export const currentPodcastPlaysSelector = createSelector(
  [currentPodcastAnalyticsSelector],
  (currentPodcast) => currentPodcast.plays
);

export const currentPodcastPlaysTimeSeriesSelector = createSelector(
  [currentPodcastAnalyticsSelector],
  (currentPodcast) => currentPodcast.playsTimeSeries
);

export const currentPodcastAnalyticsTotalOfSelector = (
  analyticsType: AnalyticTypeOption
) =>
  createSelector(
    [currentPodcastAnalyticsSelector],
    (currentPodcast) => currentPodcast[analyticsType]
  );

export const currentPodcastAnalyticsTimeSeriesOfSelector = (
  analyticsType: AnalyticTypeOption
) =>
  createSelector(
    [currentPodcastAnalyticsSelector],
    (currentPodcast) => currentPodcast[`${analyticsType}TimeSeries`]
  );

export const currentEpisodeAnalyticsTotalOfSelector = (
  analyticsType: EpisodeAnalyticTypeOption
) =>
  createSelector(
    [currentEpisodeAnalyticsSelector],
    (currentEpisode) => currentEpisode[analyticsType]
  );

export const currentEpisodeAnalyticsTimeSeriesOfSelector = (
  analyticsType: EpisodeAnalyticTypeOption
) =>
  createSelector(
    [currentEpisodeAnalyticsSelector],
    (currentEpisode) => currentEpisode[`${analyticsType}TimeSeries`]
  );

export const podcastAnalyticsStartsSelector = (podcastId: string) => (
  state: RootState
) => state.analytics.podcasts[podcastId]?.starts;

export const podcastAnalyticsStartsDeltaSelector = (podcastId: string) => (
  state: RootState
) => state.analytics.podcasts[podcastId]?.startsDelta;

export const podcastAnalyticsPlaysSelector = (podcastId: string) => (
  state: RootState
) => state.analytics.podcasts[podcastId]?.plays;

export const podcastAnalyticsPlaysDeltaSelector = (podcastId: string) => (
  state: RootState
) => state.analytics.podcasts[podcastId]?.playsDelta;

export const podcastAnalyticsListenersSelector = (podcastId: string) => (
  state: RootState
) => state.analytics.podcasts[podcastId]?.listeners;

export const podcastAnalyticsListenersDeltaSelector = (podcastId: string) => (
  state: RootState
) => state.analytics.podcasts[podcastId]?.listenersDelta;

export const podcastAnalyticsFollowersSelector = (podcastId: string) => (
  state: RootState
) => state.analytics.podcasts[podcastId]?.followers;

export const podcastAnalyticsFollowersDeltaSelector = (podcastId: string) => (
  state: RootState
) => state.analytics.podcasts[podcastId]?.followersDelta;

export const episodeStartTimeFrameSelector = (episodeId: string) => (
  state: RootState
) =>
  state.analytics.episodes[episodeId]?.timeFrame?.startTime ||
  LAST_WEEK.toISOString();

export const episodeEndTimeFrameSelector = (episodeId: string) => (
  state: RootState
) =>
  state.analytics.episodes[episodeId]?.timeFrame?.endTime ||
  YESTERDAY.toISOString();

export const episodeTimeFrameUpdateSourceSelector = (episodeId: string) => (
  state: RootState
) =>
  state.analytics.episodes[episodeId]?.timeFrame?.updateSource ||
  PRESET_TIME_FRAME;

export const episodeAnalyticsStartsSelector = (episodeId: string) => (
  state: RootState
) => state.analytics.episodes[episodeId]?.starts;

export const episodeAnalyticsPlaysSelector = (episodeId: string) => (
  state: RootState
) => state.analytics.episodes[episodeId]?.plays;

export const episodeAnalyticsListenersSelector = (episodeId: string) => (
  state: RootState
) => state.analytics.episodes[episodeId]?.listeners;

export const episodeAnalyticsEngagedListenersSelector = (episodeId: string) => (
  state: RootState
) => state.analytics.episodes[episodeId]?.engagedListeners;
