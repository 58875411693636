import { createAction, createSlice } from '@reduxjs/toolkit';
import { FeedStatusTypes, InvalidFeedTypes } from 'constants/feed';

type FeedType = {
  countryOfOrigin?: string;
  imageUrl?: string;
  ownerEmail?: string;
  id?: string;
  title?: string;
  url?: string;
  status?: FeedStatusTypes;
  invalidReason?: InvalidFeedTypes;
};

const initialState: FeedType = {};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    clearFeed: () => initialState,
    feed: (_state, action) => action.payload,
    updateFeed: (state, action) => {
      state = {
        ...state,
        ...action.payload,
      };
    },
  },
});
export const confirmFeed = createAction('feed/confirm');
export const submitFeed = createAction('feed/submit');
export const submitFeedFailure = createAction('feed/submitFailure');
export const validateFeed = createAction('feed/validate');
export const updateFeedUrl = createAction<{
  feedId: string;
  url: string;
  podcastId: string;
}>('feed/updateUrl');
export const sendEmail = createAction('feed/sendEmail');
export const checkFeedClaims = createAction('feed/feedClaims');
export const { clearFeed, feed, updateFeed } = feedSlice.actions;
export const { reducer } = feedSlice;
