import { forwardRef } from 'react';
import './styles.scss';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => (
    <input ref={ref} {...props} className={'music-input ' + props.className} />
  )
);

Input.displayName = 'Input';
