import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { openModal } from 'store/slices/modal';
import { COOKIE_CONSENT } from 'constants/modals';
import { COOKIE_PREFERENCE_FEATURE } from 'constants/features';
import { checkForFeature } from 'utils/features';
import {
  cookiePreferenceSelector,
  inEuCountrySelector,
} from 'store/selectors/user';
import { modalNameSelector } from 'store/selectors/modal';

const useGetUserCookiePreference = () => {
  const dispatch = useDispatch();
  const cookiePreference = useSelector(cookiePreferenceSelector);
  const inEuCountry = useSelector(inEuCountrySelector);
  const modalName = useSelector(modalNameSelector);

  useEffect(() => {
    if (
      checkForFeature(COOKIE_PREFERENCE_FEATURE) &&
      inEuCountry &&
      cookiePreference === 'UNKNOWN'
    ) {
      dispatch(openModal({ name: COOKIE_CONSENT, previousName: modalName }));
    }
  }, [cookiePreference]);
};

export default useGetUserCookiePreference;
