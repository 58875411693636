import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { PODCAST_PENDING_MODAL } from 'constants/metrics';
import { PageContext } from 'contexts';
import ModalArtworkTile from 'components/ArtworkTile/components/ModalArtworkTile';
import ContactUs from 'components/Links/ContactUs';
import BlurredModal from 'components/Modal/BlurredModal';
import useGetPodcastDetails from 'hooks/getPodcastDetails';
import { usePageView } from 'hooks/pageView';
import { modalPodcastIdSelector } from 'store/selectors/modal';
import './styles.scss';
// Todos: Change href for music link, decide if we want more explicit pending statuses

const Title = (
  <h4>
    <FormattedMessage
      id="podcastPending-title"
      defaultMessage="Your Show is Pending"
    />
  </h4>
);

const PodcastPending = () => {
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  usePageView(PODCAST_PENDING_MODAL);
  useGetPodcastDetails(modalPodcastId);

  return (
    <PageContext.Provider value={PODCAST_PENDING_MODAL}>
      <BlurredModal title={Title}>
        <div className="PodcastPending__container">
          <p className="bodyPrimary secondary-text">
            <FormattedMessage
              id="podcastPending-message"
              values={{ contactUs: <ContactUs /> }}
              defaultMessage="New podcasts can take up to 24 hours to appear on Amazon Music and Audible. We'll email you when it's live. If your podcast is taking longer than usual to appear, {contactUs}."
            />
          </p>
          <div className="PodcastPending__artwork">
            <ModalArtworkTile />
          </div>
        </div>
      </BlurredModal>
    </PageContext.Provider>
  );
};

export default PodcastPending;
