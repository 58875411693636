export const CLAIMED = 'CLAIMED';
export const UNCLAIMED = 'UNCLAIMED';
export const UNCLAIMED_ALREADY_PLAYING = 'UNCLAIMED_ALREADY_PLAYING';
export const INVALID_FEED = 'INVALID_FEED';
export const ERROR = 'ERROR';

export type FeedStatusTypes =
  | typeof CLAIMED
  | typeof UNCLAIMED
  | typeof UNCLAIMED_ALREADY_PLAYING
  | typeof INVALID_FEED
  | '';

// https://code.amazon.com/packages/DMPPodcasterPortalLambdaModel/blobs/mainline/--/model/types/errorCodes.smithy
export const CANNOT_DOWNLOAD_OR_PARSE = 'CANNOT_DOWNLOAD_OR_PARSE';
export const DUPLICATE = 'DUPLICATE';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const MALFORMED_XML = 'MALFORMED_XML';
export const TAKEN_DOWN = 'TAKEN_DOWN';

export const FEED_DOES_NOT_MATCH = 'FEED_DOES_NOT_MATCH';
export const MISSING_PODCAST_TITLE = 'MISSING_PODCAST_TITLE';
export const CANNOT_DOWNLOAD = 'CANNOT_DOWNLOAD';
export const CANNOT_PARSE = 'CANNOT_PARSE';
export const CLAIM_NOT_EXIST = 'CLAIM_NOT_EXIST';
export const DEPENDENCY_ERROR = 'DEPENDENCY_ERROR';

export type InvalidFeedTypes =
  | typeof CANNOT_DOWNLOAD_OR_PARSE
  | typeof DUPLICATE
  | typeof INVALID_EMAIL
  | typeof MALFORMED_XML
  | typeof TAKEN_DOWN
  | '';

export type UpdateFeedUrlFailures =
  | typeof FEED_DOES_NOT_MATCH
  | typeof MISSING_PODCAST_TITLE
  | typeof CANNOT_DOWNLOAD
  | typeof CANNOT_PARSE
  | typeof CLAIM_NOT_EXIST
  | typeof DEPENDENCY_ERROR
  | '';
