import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MusicButton } from 'components/MusicButton';
import { SELECT_CLOSE_MODAL } from 'constants/metrics';
import { PODCASTS_ROUTE } from 'constants/routes';
import { PageContext } from 'contexts';
import { clearFeed } from 'store/slices/feed';
import Logo from './components/Logo';
import Header from '.';

const Cancel = ({ route }: CancelProps) => {
  const page = useContext(PageContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const onClick = () => {
    dispatch(clearFeed());
    if (route) {
      history.push(route);
    } else if (history.action === 'POP') {
      history.push('');
    } else {
      history.goBack();
    }
  };

  return (
    <MusicButton
      onMusicActivate={onClick}
      icon-only
      icon-name="cancel"
      variant="glass"
      size="small"
      data-id={`${SELECT_CLOSE_MODAL}-${page}`}
    />
  );
};

export const HeaderWithCancelToPodcasts = () => (
  <Header>
    <Logo />
    <Cancel route={PODCASTS_ROUTE} />
  </Header>
);

export const HeaderWithCancel = () => (
  <Header>
    <Logo />
    <Cancel />
  </Header>
);
