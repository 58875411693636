import { createSlice } from '@reduxjs/toolkit';

type ModalState = {
  name: ModalTypes;
  previousName?: ModalTypes;
  podcastId?: string;
  episodeId?: string;
};

const initialState: ModalState = {
  name: '',
};

const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (_state, action) => action.payload,
    closeModal: () => initialState,
  },
});

export const { openModal, closeModal } = modal.actions;
export const { reducer } = modal;
