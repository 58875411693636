import Cookies from 'js-cookie';
import { postWithCredentials } from 'api';
import { AUTHENTICATION_ERROR } from 'constants/api';
import { PODCASTER_SESSION_ID, UNIQUE_BROWSER_ID } from 'constants/cookies';
import { CIRRUS_RCA_API } from 'constants/links';
import { LOCALE } from 'constants/locales';
import {
  CLICK,
  PODCASTER_PORTAL_ENCRYPTED_DEVICE_TYPE_ID,
  UI_CLICK_BASE_EVENT_VERSION,
  UI_CLICK_CONTENT_SOURCE,
  UI_CLICK_SPECIFIC_EVENT_VERSION,
  UNKNOWN,
} from 'constants/metrics';
import { convertToSeconds } from 'utils/conversions';

export const reportClientAction = async (
  clientActionList: Array<Object>,
  token = ''
) => {
  const body = {
    deviceId: Cookies.get(UNIQUE_BROWSER_ID) || UNKNOWN,
    deviceType: PODCASTER_PORTAL_ENCRYPTED_DEVICE_TYPE_ID,
    clientActionList,
  };

  const headers = {
    'X-Amz-Target':
      'com.amazon.cirrus.libraryservice.v3.CirrusLibraryServiceExternalV3.reportClientActions',
    'Content-Encoding': 'amz-1.0',
  };

  const metricsEndpoints: string[] = Cookies.get('x-main')
    ? Object.values(CIRRUS_RCA_API)
    : [CIRRUS_RCA_API.NA];

  for (const endpoint of metricsEndpoints) {
    const response = await postWithCredentials({
      path: endpoint,
      headers: {
        ...headers,
        'x-amz-access-token': token,
      },
      body,
    });

    const responseJson = await response.json();

    if (responseJson.message?.includes(AUTHENTICATION_ERROR)) {
      postWithCredentials({
        path: endpoint,
        headers,
        body,
      });
    }
  }
};

export const reportClientActionClick = (
  dataId: string,
  dataPage: string,
  token?: string
) => {
  const clickEvent = {
    timestamp: convertToSeconds(Date.now()),
    actionName: CLICK,
    additionalDetails: {
      url: window.location.href,
      actionType: dataId,
      locale: localStorage.getItem(LOCALE),
      podcasterSessionId: Cookies.get(PODCASTER_SESSION_ID),
      pageType: dataPage,
      baseEventVersion: UI_CLICK_BASE_EVENT_VERSION,
      specificEventVersion: UI_CLICK_SPECIFIC_EVENT_VERSION,
    },
    contentInfo: [
      {
        contentSrc: UI_CLICK_CONTENT_SOURCE,
        contentName: dataId,
      },
    ],
  };

  reportClientAction([clickEvent], token);
};
