import { call, put, select } from 'redux-saga/effects';
import { getWithCredentials } from 'api';
import { CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME } from 'constants/modals';
import { EMAIL_OWNER_API } from 'constants/routes';
import { openModal } from 'store/slices/modal';
import { feedIdSelector } from 'store/selectors/feed';

export function* sendEmailSaga(): any {
  let feedId = yield select(feedIdSelector);
  feedId = feedId || localStorage.getItem('feedId');
  const response = yield call(getWithCredentials, {
    path: `${EMAIL_OWNER_API}?feedId=${feedId}`,
  });

  if (!response.ok && (response.status === 401 || response.status === 403)) {
    yield put(openModal({ name: CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME }));
  }
}
