import { useSelector } from 'react-redux';
import DateRangeModal from 'components/Modal/DateRange';
import {
  episodeEndTimeFrameSelector,
  episodeStartTimeFrameSelector,
  episodeTimeFrameUpdateSourceSelector,
} from 'store/selectors/analytics';
import { modalEpisodeIdSelector } from 'store/selectors/modal';
import { updateEpisodeTimeFrame } from 'store/slices/analytics';
import { currentEpisodePublishedTimeSelector } from 'store/selectors/episodes';
import { adjustEpisodeStartTime } from 'utils/date';

const EpisodeDateRangeModal = () => {
  const modalEpisodeId = useSelector(modalEpisodeIdSelector);
  const episodeStartTime = useSelector(
    episodeStartTimeFrameSelector(modalEpisodeId)
  );
  const episodeEndTime = useSelector(
    episodeEndTimeFrameSelector(modalEpisodeId)
  );
  const episodePublishDate = useSelector(currentEpisodePublishedTimeSelector);
  const updateSource = useSelector(
    episodeTimeFrameUpdateSourceSelector(modalEpisodeId)
  );

  const confirmDateRangePayloadId = {
    episodeId: modalEpisodeId,
  };

  return (
    <DateRangeModal
      startTime={adjustEpisodeStartTime(
        episodeStartTime,
        updateSource,
        episodePublishDate
      )}
      endTime={episodeEndTime}
      updateTimeFrameReducer={updateEpisodeTimeFrame}
      confirmDateRangePayloadId={confirmDateRangePayloadId}
    />
  );
};

export default EpisodeDateRangeModal;
