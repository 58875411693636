import { Fragment, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/slices/modal';

const BaseModal = ({ children, allowEscape = true }: BaseModalProps) => {
  const dispatch = useDispatch();

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape' && allowEscape) {
      dispatch(closeModal());
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, { passive: true });

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default BaseModal;
