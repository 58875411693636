import date_de from 'date-fns/locale/de';
import date_es from 'date-fns/locale/es';
import date_en_US from 'date-fns/locale/en-US';
import date_en_GB from 'date-fns/locale/en-GB';
import date_fr from 'date-fns/locale/fr';
import date_it from 'date-fns/locale/it';
import date_ja from 'date-fns/locale/ja';
import date_pt from 'date-fns/locale/pt';
import de from 'assets/images/flags/de.svg';
import us from 'assets/images/flags/us.svg';
import gb from 'assets/images/flags/gb.svg';
import es from 'assets/images/flags/es.svg';
import mx from 'assets/images/flags/mx.svg';
import fr from 'assets/images/flags/fr.svg';
import it from 'assets/images/flags/it.svg';
import jp from 'assets/images/flags/jp.svg';
import br from 'assets/images/flags/br.svg';
import {
  DE_SOCIAL,
  US_SOCIAL,
  GB_SOCIAL,
  ES_SOCIAL,
  MX_SOCIAL,
  FR_SOCIAL,
  IT_SOCIAL,
  JP_SOCIAL,
  BR_SOCIAL,
  US_PROMO_TOOLS_SOCIAL,
} from 'constants/social';
import {
  COOKIE_NOTICE_LINK_BR,
  COOKIE_NOTICE_LINK_DE,
  COOKIE_NOTICE_LINK_ES,
  COOKIE_NOTICE_LINK_FR,
  COOKIE_NOTICE_LINK_GB,
  COOKIE_NOTICE_LINK_IT,
  COOKIE_NOTICE_LINK_JP,
  COOKIE_NOTICE_LINK_MX,
  COOKIE_NOTICE_LINK_US,
  COOKIE_PRIVACY_NOTICE_LINK_BR,
  COOKIE_PRIVACY_NOTICE_LINK_DE,
  COOKIE_PRIVACY_NOTICE_LINK_ES,
  COOKIE_PRIVACY_NOTICE_LINK_FR,
  COOKIE_PRIVACY_NOTICE_LINK_GB,
  COOKIE_PRIVACY_NOTICE_LINK_IT,
  COOKIE_PRIVACY_NOTICE_LINK_JP,
  COOKIE_PRIVACY_NOTICE_LINK_MX,
  COOKIE_PRIVACY_NOTICE_LINK_US,
} from './links';

export const LOCALE = 'locale';

export const en_US = 'en-US';
export const en_AU = 'en-AU';
export const de_DE = 'de-DE';
export const en_CA = 'en-CA';
export const en_GB = 'en-GB';
export const en_IN = 'en-IN';
export const es_ES = 'es-ES';
export const es_MX = 'es-MX';
export const fr_CA = 'fr-CA';
export const fr_FR = 'fr-FR';
export const it_IT = 'it-IT';
export const ja_JP = 'ja-JP';
export const pt_BR = 'pt-BR';

export type LOCALE_TYPES =
  | typeof en_US
  | typeof de_DE
  | typeof en_GB
  | typeof es_ES
  | typeof es_MX
  | typeof fr_FR
  | typeof it_IT
  | typeof ja_JP
  | typeof pt_BR;

export const LOCALES = [
  en_US,
  de_DE,
  en_GB,
  es_ES,
  es_MX,
  fr_CA,
  fr_FR,
  it_IT,
  ja_JP,
  pt_BR,
];

export const LOCALES_MAP = {
  [de_DE]: 'Deutsch',
  [en_US]: 'English',
  [en_GB]: 'English',
  [es_ES]: 'Español',
  [es_MX]: 'Español',
  [fr_FR]: 'Français',
  [it_IT]: 'Italiano',
  [ja_JP]: '日本語',
  [pt_BR]: 'Português',
};

export const FLAG_MAP = {
  [de_DE]: de,
  [en_US]: us,
  [en_GB]: gb,
  [es_ES]: es,
  [es_MX]: mx,
  [fr_FR]: fr,
  [it_IT]: it,
  [ja_JP]: jp,
  [pt_BR]: br,
};

export const SOCIAL_HANDLES_MAP = {
  [de_DE]: DE_SOCIAL,
  [en_US]: US_SOCIAL,
  [en_GB]: GB_SOCIAL,
  [es_ES]: ES_SOCIAL,
  [es_MX]: MX_SOCIAL,
  [fr_FR]: FR_SOCIAL,
  [it_IT]: IT_SOCIAL,
  [ja_JP]: JP_SOCIAL,
  [pt_BR]: BR_SOCIAL,
};

export const COOKIE_NOTICE_LINKS_MAP = {
  [de_DE]: COOKIE_NOTICE_LINK_DE,
  [en_US]: COOKIE_NOTICE_LINK_US,
  [en_GB]: COOKIE_NOTICE_LINK_GB,
  [es_ES]: COOKIE_NOTICE_LINK_ES,
  [es_MX]: COOKIE_NOTICE_LINK_MX,
  [fr_FR]: COOKIE_NOTICE_LINK_FR,
  [it_IT]: COOKIE_NOTICE_LINK_IT,
  [ja_JP]: COOKIE_NOTICE_LINK_JP,
  [pt_BR]: COOKIE_NOTICE_LINK_BR,
};

export const COOKIE_PRIVACY_NOTICE_LINKS_MAP = {
  [de_DE]: COOKIE_PRIVACY_NOTICE_LINK_DE,
  [en_US]: COOKIE_PRIVACY_NOTICE_LINK_US,
  [en_GB]: COOKIE_PRIVACY_NOTICE_LINK_GB,
  [es_ES]: COOKIE_PRIVACY_NOTICE_LINK_ES,
  [es_MX]: COOKIE_PRIVACY_NOTICE_LINK_MX,
  [fr_FR]: COOKIE_PRIVACY_NOTICE_LINK_FR,
  [it_IT]: COOKIE_PRIVACY_NOTICE_LINK_IT,
  [ja_JP]: COOKIE_PRIVACY_NOTICE_LINK_JP,
  [pt_BR]: COOKIE_PRIVACY_NOTICE_LINK_BR,
};

export const PROMO_TOOLS_HANDLES_MAP = {
  ...SOCIAL_HANDLES_MAP,
  [en_US]: US_PROMO_TOOLS_SOCIAL,
};

export const LOCALE_TO_TLD_MAP = {
  [de_DE]: '.de',
  [en_US]: '.com',
  [en_GB]: '.co.uk',
  [es_ES]: '.es',
  [es_MX]: '.com.mx',
  [fr_FR]: '.fr',
  [it_IT]: '.it',
  [ja_JP]: '.co.jp',
  [pt_BR]: '.com.br',
};

export const LOCALE_DATE_MAP = {
  [de_DE]: date_de,
  [en_US]: date_en_US,
  [en_GB]: date_en_GB,
  [es_ES]: date_es,
  [es_MX]: date_es,
  [fr_FR]: date_fr,
  [it_IT]: date_it,
  [ja_JP]: date_ja,
  [pt_BR]: date_pt,
};
