import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { MusicButton } from 'components/MusicButton';
import { SUBMIT_RSS_ROUTE, PODCASTS_ROUTE } from 'constants/routes';
import CreateNewAccount from 'components/Links/CreateNewAccount';
import DifferentAccount from 'components/Links/DifferentAccount';
import BlurredModal from 'components/Modal/BlurredModal';
import { SELECT_CONTINUE_TO_ACCOUNT, SIGNED_IN_MODAL } from 'constants/metrics';
import { PageContext } from 'contexts';
import {
  emailSelector,
  fullNameSelector,
  hasFeedClaimsSelector,
} from 'store/selectors/user';
import { usePageView } from 'hooks/pageView';
import { truncate } from 'utils/strings';
import './styles.scss';

const Title = (
  <h4>
    <FormattedMessage id="signedIn-title" />
  </h4>
);

const SignedIn = () => {
  usePageView(SIGNED_IN_MODAL);

  const hasFeedClaims = useSelector(hasFeedClaimsSelector);
  const email = truncate(useSelector(emailSelector));
  const fullName = truncate(useSelector(fullNameSelector));
  const returnUrl = hasFeedClaims ? PODCASTS_ROUTE : SUBMIT_RSS_ROUTE;

  return (
    <PageContext.Provider value={SIGNED_IN_MODAL}>
      <BlurredModal title={Title}>
        <p>
          <FormattedMessage
            id="signedInOverview-subtitle"
            values={{ fullName, email }}
          />
        </p>
        <MusicButton
          variant="solid"
          href={returnUrl}
          className="SignedIn__sign-in-button MusicButton__wide"
          data-id={`${SELECT_CONTINUE_TO_ACCOUNT}-${SIGNED_IN_MODAL}`}
        >
          <FormattedMessage id="continue" />
        </MusicButton>
        <p className="SignedIn__new-account">
          <FormattedMessage
            id="manageYourAccount"
            values={{
              differentAccount: <DifferentAccount />,
              createNewAccount: <CreateNewAccount />,
            }}
          />
        </p>
      </BlurredModal>
    </PageContext.Provider>
  );
};

export default SignedIn;
