import { PayloadAction } from '@reduxjs/toolkit';
import { postWithCredentials } from 'api';
import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import {
  ALL,
  ALL_OWNED_PAGINATION,
  ANALYTIC_OPTIONS_TO_API_FIELDS_MAP,
  DELTA,
  TOTAL,
} from 'constants/analytics';
import { EPISODE_ID } from 'constants/api';
import { PODCAST_ANALYTICS_API, EPISODE_ANALYTICS_API } from 'constants/routes';
import {
  getPodcastsTotals,
  getPodcastsTotalsFailure,
  getEpisodesTotals,
  getEpisodesTotalsFailure,
  updatePodcastAnalytics,
  updateTimeFrame,
  updateEpisodeTimeFrame,
} from 'store/slices/analytics';
import {
  podcastTotalsLoading,
  episodeTotalsLoading,
} from 'store/slices/loading';
import { parseISOWithoutHour } from 'utils/date';
import { normalizeAnalytics } from 'utils/normalizer/analytics';
import { normalizeAggregateAnalytics } from 'utils/normalizer/analytics';
import { updateEpisodeAnalytics } from '../../slices/analytics';

export function* fetchAllPodcastTotals({
  payload: { analyticTypes, endTime, startTime },
}: PayloadAction<PodcastAnalyticsActionPayload>): any {
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);

  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        ...ALL_OWNED_PAGINATION,
        ...analyticTypes.reduce(
          (accumulator, currentValue) => ({
            ...accumulator,
            [ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[currentValue].totals]: {
              calculationsIncluded: [DELTA, TOTAL],
            },
          }),
          {}
        ),
      },
    });
    const { data } = yield call([response, 'json']);
    yield put(
      updatePodcastAnalytics({ podcastId: ALL, ...normalizeAnalytics(data) })
    );
  } catch (e) {
    yield put(getPodcastsTotalsFailure());
  }
}

export function* fetchPodcastTotals({
  payload: { analyticTypes, podcastId, startTime, endTime },
}: PayloadAction<PodcastAnalyticsActionPayload>): any {
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);

  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        podcastIds: [podcastId],
        ...analyticTypes.reduce(
          (accumulator, analyticType) => ({
            ...accumulator,
            [ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].totals]: {
              calculationsIncluded: [DELTA, TOTAL],
            },
          }),
          {}
        ),
      },
    });
    const { data } = yield call([response, 'json']);
    yield put(
      updatePodcastAnalytics({
        podcastId,
        ...normalizeAnalytics(data),
      })
    );
  } catch (e) {
    yield put(getPodcastsTotalsFailure());
  }
}

function* fetchTotals(
  action: PayloadAction<PodcastAnalyticsActionPayload>
): any {
  if (action.payload.podcastId === ALL) {
    yield fetchAllPodcastTotals(action);
  } else {
    yield fetchPodcastTotals(action);
  }
}

export function* fetchEpisodeTotals({
  payload: { analyticTypes, episodeId, startTime, endTime },
}: PayloadAction<EpisodeAnalyticsActionPayload>): any {
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);
  try {
    const response = yield call(postWithCredentials, {
      path: EPISODE_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        episodeIds: [episodeId],
        ...analyticTypes.reduce(
          (accumulator, analyticType) => ({
            ...accumulator,
            [ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].aggregate]: {
              groupByProperties: [EPISODE_ID],
              calculationsIncluded: [TOTAL],
            },
          }),
          {}
        ),
      },
    });
    const { data } = yield call([response, 'json']);
    yield put(
      updateEpisodeAnalytics({
        episodes: normalizeAggregateAnalytics(analyticTypes, data),
      })
    );
  } catch (e) {
    yield put(getEpisodesTotalsFailure());
  }
}

export function* podcastTotals(
  action: PayloadAction<PodcastAnalyticsActionPayload>
): any {
  const { podcastId } = action.payload;
  if (podcastId) {
    yield put(podcastTotalsLoading(true));
    yield race([call(fetchTotals, action), take(updateTimeFrame.toString())]);
    yield put(podcastTotalsLoading(false));
  }
}

export function* episodeTotals(
  action: PayloadAction<EpisodeAnalyticsActionPayload>
): any {
  const { episodeId } = action.payload;
  if (episodeId) {
    yield put(episodeTotalsLoading(true));
    yield race([
      call(fetchEpisodeTotals, action),
      take(updateEpisodeTimeFrame.toString()),
    ]);
    yield put(episodeTotalsLoading(false));
  }
}

export const totalsSagas = [
  takeEvery(getPodcastsTotals.toString(), podcastTotals),
  takeEvery(getEpisodesTotals.toString(), episodeTotals),
];
