import { CLAIMED, UNCLAIMED, UNCLAIMED_ALREADY_PLAYING } from 'constants/feed';
import { upgradeToHttps } from 'utils/url';

export const normalizeFeed = (
  feedResponse: any,
  url?: string,
  countryOfOrigin?: string
) => {
  const alreadyPlaying = !!feedResponse.feeds?.[0];
  let status = alreadyPlaying ? UNCLAIMED_ALREADY_PLAYING : UNCLAIMED;
  status = feedResponse.isClaimed ? CLAIMED : status;

  return {
    id: feedResponse.feeds?.[0],
    imageUrl: upgradeToHttps(feedResponse.metadata?.imageUrl),
    ownerEmail: feedResponse.metadata?.ownerEmail,
    status,
    title: feedResponse.metadata?.title,
    ...(url && { url }),
    ...(countryOfOrigin && { countryOfOrigin }),
  };
};
