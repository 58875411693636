import { differenceInDays, isValid, parseISO, subDays } from 'date-fns';
import { PRESET_TIME_FRAME } from 'constants/analytics';
import { LOCALE_TYPES } from 'constants/locales';

export const TODAY = new Date();
export const YESTERDAY = subDays(TODAY, 1);
export const LAST_WEEK = subDays(TODAY, 7);
export const TWO_WEEKS_AGO = subDays(TODAY, 14);
export const ONE_MONTH_AGO = subDays(TODAY, 30);
export const THREE_MONTHS_AGO = subDays(TODAY, 90);
//Set PODCASTS_LAUNCH_DATE to be September 16, 2020
export const PODCASTS_LAUNCH_DATE = new Date(2020, 8, 16, 0, 0, 0);

export const isoWithoutTime = (date = new Date()) => {
  const [dateString] = date.toISOString().split('T');
  return dateString + 'T00:00:00.000Z';
};

export const parseISOWithoutHour = (dateStr: string) => {
  const [dateString] = dateStr.split('T');
  return dateString + 'T00:00:00.000Z';
};

export const isTheSameTimeFrame = (
  startTime: Date,
  endTime: Date,
  selectedStartTime: Date | undefined,
  selectedEndTime: Date | undefined
) => {
  const startDate = startTime.setHours(0, 0, 0, 0);
  const endDate = endTime.setHours(0, 0, 0, 0);
  const selectedStartDate = selectedStartTime?.setHours(0, 0, 0, 0);
  const selectedEndDate = selectedEndTime?.setHours(0, 0, 0, 0);
  return (
    startDate.toString() === selectedStartDate?.toString() &&
    endDate.toString() === selectedEndDate?.toString()
  );
};

export const formatUTC = (utcDateString: string, locale: string): string => {
  const date = parseISO(utcDateString);

  if (!isValid(date)) return '';

  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
};

const formatMonth = (locale: LOCALE_TYPES, chartData: DataPoint[] = []) =>
  chartData.map(({ x, y }) => {
    const date = new Date(x).toLocaleDateString(locale, {
      month: 'short',
      day: 'numeric',
      timeZone: 'GMT',
    });

    return {
      x: date,
      y,
    };
  });

const formatYear = (locale: LOCALE_TYPES, chartData: DataPoint[] = []) =>
  chartData.map(({ x, y }) => {
    const date = new Date(x).toLocaleDateString(locale, {
      month: 'short',
      day: 'numeric',
      year: '2-digit',
      timeZone: 'GMT',
    });

    return {
      x: date,
      y,
    };
  });

export const formatTimeframe = (
  data: DataPoint[],
  locale: LOCALE_TYPES,
  startTime: string,
  endTime: string
) => {
  const dayRange = differenceInDays(new Date(endTime), new Date(startTime));

  if (dayRange <= 365) {
    return formatMonth(locale, data);
  } else {
    return formatYear(locale, data);
  }
};

export const startEndTimes = (days: number) => ({
  startTime: subDays(TODAY, days).toISOString(),
  endTime: YESTERDAY.toISOString(),
  updateSource: PRESET_TIME_FRAME,
});

export const startTimeIsLaunchDate = (startDate: number | Date) =>
  Math.abs(differenceInDays(startDate, PODCASTS_LAUNCH_DATE)) < 1;

/* the start time for episode all-time periods and custom 
   date range modal should be shown as the episode's launch 
   date unless launch date is after PODCASTS_LAUNCH_DATE */

export const adjustEpisodeStartTime = (
  startTime: string,
  updateSource: string,
  episodePublishDate: string
) => {
  if (
    startTimeIsLaunchDate(new Date(startTime)) &&
    updateSource === PRESET_TIME_FRAME
  ) {
    return episodePublishDateIsAfterPodcastsLaunch(episodePublishDate)
      ? episodePublishDate
      : PODCASTS_LAUNCH_DATE.toISOString();
  }
  return startTime;
};

export const episodePublishDateIsAfterPodcastsLaunch = (
  episodePublishDate: string
) => PODCASTS_LAUNCH_DATE.getTime() < new Date(episodePublishDate).getTime();
