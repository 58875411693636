import { en_US, LOCALE } from 'constants/locales';
import { getQueryStringParams } from 'utils/url';

export const fetchWithCredentials = ({
  body,
  headers,
  path,
  method,
}: BaseParams) => {
  let [csrfToken] = getQueryStringParams(['csrf-token']);
  csrfToken = csrfToken || localStorage.getItem('csrfToken') || '';
  const cleanToken = csrfToken.replace(new RegExp(' ', 'g'), '+');
  const locale = localStorage.getItem(LOCALE) || en_US;

  return fetch(path, {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'csrf-token': cleanToken,
      locale: locale,
      ...headers,
    },
    redirect: 'follow',
    ...(body && { body: JSON.stringify(body) }),
  }).then((response) => response);
};

export const getWithCredentials = ({ headers, path }: GetParams) =>
  fetchWithCredentials({ headers, path, method: 'GET' });

export const postWithCredentials = ({ body, headers, path }: PostParams) =>
  fetchWithCredentials({ body, headers, path, method: 'POST' });
