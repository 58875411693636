import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ShareModal from 'components/Modal/ShareModal/';
import BlurredModal from 'components/Modal/BlurredModal';
import { MusicHorizontalItem } from 'components/MusicHorizontalItem';
import { SOCIAL_HANDLES_MAP } from 'constants/locales';
import { localeSelector } from 'store/selectors/locale';
import {
  modalEpisodeImageSelector,
  modalEpisodeSelector,
  modalPodcastIdSelector,
} from 'store/selectors/modal';
import { usePageView } from 'hooks/pageView';
import { PageContext } from 'contexts';
import {
  getFacebookShareLink,
  getLocalizedPublicEpisodeUrl,
  getTwitterShareLink,
} from 'utils/url';
import { SHARE_EPISODE_MODAL } from 'constants/metrics';
import '../styles.scss';

const ModalTitle = (
  <h4>
    <FormattedMessage id="share-episode" defaultMessage="Share my episode" />
  </h4>
);
const ShareEpisodeModal = () => {
  usePageView(SHARE_EPISODE_MODAL);
  const podcastId = useSelector(modalPodcastIdSelector);
  const { title, episodeId } = useSelector(modalEpisodeSelector);
  const image = useSelector(modalEpisodeImageSelector);
  const locale = useSelector(localeSelector);
  const intl = useIntl();

  const shareMessage = intl.formatMessage({ id: 'share-message' }, { title });
  const twitterShareMessage = intl.formatMessage(
    { id: 'share-message-twitter' },
    { title, amazonMusicTwitterAccount: SOCIAL_HANDLES_MAP[locale] }
  );

  const publicUrl = getLocalizedPublicEpisodeUrl(
    podcastId,
    locale,
    episodeId,
    title
  );
  const twitterUrl = getTwitterShareLink(publicUrl, twitterShareMessage);
  const facebookUrl = getFacebookShareLink(publicUrl);
  const emailUrl = `mailto:?body=${encodeURIComponent(
    publicUrl
  )}&subject=${shareMessage}`;

  return (
    <PageContext.Provider value={SHARE_EPISODE_MODAL}>
      <BlurredModal title={ModalTitle}>
        <div className="Share__content">
          <div className="Share__episodeInfo">
            <MusicHorizontalItem
              imageUrl={image}
              label={<FormattedMessage id="Episode" defaultMessage="Episode" />}
              primaryText={title}
            />
          </div>
          <ShareModal
            publicUrl={publicUrl}
            facebookUrl={facebookUrl}
            twitterUrl={twitterUrl}
            emailUrl={emailUrl}
          />
        </div>
      </BlurredModal>
    </PageContext.Provider>
  );
};
export default ShareEpisodeModal;
