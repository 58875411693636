import { createAction } from '@reduxjs/toolkit';

export const reportClickAction = createAction<{
  dataId: string;
  dataPage: string;
}>('metrics/reportClientClick');

export const reportPageViewAction = createAction<Array<any>>(
  'metrics/reportClientPageView'
);
