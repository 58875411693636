import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { postWithCredentials } from 'api';
import { ERROR } from 'constants/status';
import {
  PODCAST_DETAILS_API,
  PODCAST_LIST_API,
  PODCAST_UNCLAIM_API,
  PODCAST_VISIBILITY_API,
} from 'constants/routes';
import {
  PODCAST_LIST,
  PODCAST_DETAILS,
  HIDE_PODCAST,
  UNHIDE_PODCAST,
  NOT_FOUND,
  FORBIDDEN,
  UNEXPECTED,
  UNCLAIM_PODCAST,
  MULTIVARIANT_PODCAST,
} from 'constants/podcastFailures';
import { DEFAULT_PAGE_SIZE } from 'constants/podcasts';
import { podcastListLoadingSelector } from 'store/selectors/loading';
import { updatePodcastEntities } from 'store/slices/entities';
import {
  clearCursor,
  getPodcastDetails,
  getPodcasts,
  hidePodcast,
  unclaimPodcast,
  unhidePodcast,
  updatePodcasts,
} from 'store/slices/podcasts';
import {
  podcastIdListSelector,
  podcastListCursorSelector,
} from 'store/selectors/podcasts';
import { addApiStatus } from 'store/slices/apiStatus';
import {
  podcastDetailsLoading,
  updatePodcastListLoading,
} from 'store/slices/loading';
import { normalizePodcast, normalizePodcasts } from 'utils/normalizer/podcasts';

function* fetchPodcasts(action: PayloadAction<{ pageSize: number }>): any {
  const podcastListLoading = yield select(podcastListLoadingSelector);
  if (podcastListLoading === false) {
    const podcastListCursor = yield select(podcastListCursorSelector);
    yield put(updatePodcastListLoading(true));

    try {
      const response = yield call(postWithCredentials, {
        path: PODCAST_LIST_API,
        body: {
          cursor: podcastListCursor || undefined,
          pageSize: action.payload.pageSize || DEFAULT_PAGE_SIZE,
        },
      });

      const json = yield response.json();
      const podcasts = normalizePodcasts(json.data);

      yield put(updatePodcasts(podcasts));
      yield put(updatePodcastEntities(podcasts.entities));
    } catch (e) {
      yield put(addApiStatus({ type: ERROR, code: PODCAST_LIST }));
    }
    yield put(updatePodcastListLoading(false));
  }
}

function* fetchPodcastDetails(action: {
  payload: { ids: Array<string> };
  type: string;
}): any {
  yield put(podcastDetailsLoading(true));

  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_DETAILS_API,
      body: {
        podcastIds: action.payload.ids,
      },
    });

    if (response.ok) {
      const json = yield response.json();
      const entities = json.data.items;
      if (entities.length > 0) {
        const normalizedPodcastArray = normalizePodcast(entities);
        yield put(updatePodcastEntities(normalizedPodcastArray));
      } else {
        const errorCode =
          json.error.errors.length > 0 && json.error.errors[0].code;
        switch (errorCode) {
          case 403:
            yield put(addApiStatus({ type: ERROR, code: FORBIDDEN }));
            break;
          case 404:
            yield put(addApiStatus({ type: ERROR, code: NOT_FOUND }));
            break;
          default:
            yield put(addApiStatus({ type: ERROR, code: UNEXPECTED }));
        }
      }
    }
  } catch (e) {
    yield put(addApiStatus({ type: ERROR, code: PODCAST_DETAILS }));
  }

  yield put(podcastDetailsLoading(false));
}

function* hidePodcastRequest(action: {
  payload: { id: string };
  type: string;
}): any {
  const id = action.payload.id;
  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_VISIBILITY_API(id),
      body: {
        visible: false,
      },
    });

    if (response.status === 400) {
      const json = yield call([response, 'json']);
      yield call(checkMultivariantPodcastErrorCode, { json, id });
    }

    yield put(getPodcastDetails({ ids: [id] }));
  } catch (e) {
    yield put(addApiStatus({ type: ERROR, code: HIDE_PODCAST, podcastId: id }));
  }
}

function* unhidePodcastRequest(action: {
  payload: { id: string };
  type: string;
}): any {
  const id = action.payload.id;
  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_VISIBILITY_API(id),
      body: {
        visible: true,
      },
    });

    if (response.status === 400) {
      const json = yield call([response, 'json']);
      yield call(checkMultivariantPodcastErrorCode, { json, id });
    }

    yield put(getPodcastDetails({ ids: [id] }));
  } catch (e) {
    yield put(
      addApiStatus({ type: ERROR, code: UNHIDE_PODCAST, podcastId: id })
    );
  }
}

function* unclaimPodcastRequest(action: {
  payload: { id: string };
  type: string;
}): any {
  const id = action.payload.id;
  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_UNCLAIM_API(id),
      body: {
        shouldDiscard: false,
      },
    });
    const podcastIdList = yield select(podcastIdListSelector);
    const pageSize = podcastIdList.length;
    switch (response.status) {
      case 200:
        // Fetch latest claim status for already loaded page size
        yield put(clearCursor());
        yield put(getPodcasts({ pageSize: pageSize }));
        break;
      case 400: {
        const json = yield call([response, 'json']);
        yield call(checkMultivariantPodcastErrorCode, { json, id });
        break;
      }
      default:
        yield put(
          addApiStatus({ type: ERROR, code: UNCLAIM_PODCAST, podcastId: id })
        );
    }
  } catch (e) {
    yield put(
      addApiStatus({ type: ERROR, code: UNCLAIM_PODCAST, podcastId: id })
    );
  }
}

function* checkMultivariantPodcastErrorCode({
  json,
  id,
}: {
  json: any;
  id: string;
}) {
  if (json?.errorCode === MULTIVARIANT_PODCAST) {
    yield put(
      addApiStatus({
        type: ERROR,
        code: MULTIVARIANT_PODCAST,
        podcastId: id,
      })
    );
  }
}

export const podcastSagas = [
  takeEvery(getPodcasts.toString(), fetchPodcasts),
  takeLatest(getPodcastDetails.toString(), fetchPodcastDetails),
  takeLatest(hidePodcast.toString(), hidePodcastRequest),
  takeLatest(unhidePodcast.toString(), unhidePodcastRequest),
  takeLatest(unclaimPodcast.toString(), unclaimPodcastRequest),
];
