import { PayloadAction } from '@reduxjs/toolkit';
import { postWithCredentials } from 'api';
import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import {
  ALL,
  ALL_OWNED_PAGINATION,
  ANALYTIC_OPTIONS_TO_API_FIELDS_MAP,
  DAY,
} from 'constants/analytics';
import { EPISODE_ANALYTICS_API, PODCAST_ANALYTICS_API } from 'constants/routes';
import {
  getPodcastsTimeSeries,
  getPodcastsTimeSeriesFailure,
  getEpisodesTimeSeries,
  getEpisodesTimeSeriesFailure,
  updatePodcastAnalytics,
  updateTimeFrame,
  updateEpisodeTimeFrame,
} from 'store/slices/analytics';
import {
  normalizeAnalytics,
  normalizeTimeSeriesAnalytics,
} from 'utils/normalizer/analytics';
import { parseISOWithoutHour } from 'utils/date';
import { loadingReducer } from 'utils/reducer';
import { updateTimeSeriesLoading } from 'store/slices/loading';
import { updateEpisodeAnalytics } from 'store/slices/analytics';

export function* fetchAllPodcastTimeSeries({
  payload: { analyticTypes, startTime, endTime },
}: PayloadAction<PodcastAnalyticsActionPayload>): any {
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);

  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        ...ALL_OWNED_PAGINATION,
        ...analyticTypes.reduce(
          (accumulator, analyticType) => ({
            ...accumulator,
            [ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].timeSeries]: {
              granularity: DAY,
            },
          }),
          {}
        ),
      },
    });
    const { data } = yield call([response, 'json']);
    yield put(
      updatePodcastAnalytics({ podcastId: ALL, ...normalizeAnalytics(data) })
    );
  } catch (e) {
    yield put(getPodcastsTimeSeriesFailure());
  }
}

export function* fetchPodcastTimeSeries({
  payload: { analyticTypes, podcastId, startTime, endTime },
}: PayloadAction<PodcastAnalyticsActionPayload>): any {
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);

  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        podcastIds: [podcastId],
        ...analyticTypes.reduce(
          (accumulator, analyticType) => ({
            ...accumulator,
            [ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].timeSeries]: {
              granularity: DAY,
            },
          }),
          {}
        ),
      },
    });
    const { data } = yield call([response, 'json']);
    yield put(
      updatePodcastAnalytics({
        podcastId,
        ...normalizeTimeSeriesAnalytics(data, analyticTypes),
      })
    );
  } catch (e) {
    yield put(getPodcastsTimeSeriesFailure());
  }
}

export function* fetchEpisodeTimeSeries({
  payload: { analyticTypes, episodeId, startTime, endTime },
}: PayloadAction<EpisodeAnalyticsActionPayload>): any {
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);

  try {
    const response = yield call(postWithCredentials, {
      path: EPISODE_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        episodeIds: [episodeId],
        ...analyticTypes.reduce(
          (accumulator, analyticType) => ({
            ...accumulator,
            [ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].timeSeries]: {
              granularity: DAY,
            },
          }),
          {}
        ),
      },
    });
    const { data } = yield call([response, 'json']);
    const record: Record<string, EpisodeAnalytic> = {
      [episodeId]: {
        ...normalizeTimeSeriesAnalytics(data, analyticTypes),
      } as EpisodeAnalytic,
    };

    yield put(
      updateEpisodeAnalytics({
        episodes: record,
      })
    );
  } catch (e) {
    yield put(getEpisodesTimeSeriesFailure());
  }
}

function* fetchTimeSeries(
  action: PayloadAction<PodcastAnalyticsActionPayload>
): any {
  if (action.payload.podcastId === ALL) {
    yield fetchAllPodcastTimeSeries(action);
  } else {
    yield fetchPodcastTimeSeries(action);
  }
}

export function* podcastTimeSeries(
  action: PayloadAction<PodcastAnalyticsActionPayload>
): any {
  const { podcastId } = action.payload;
  if (podcastId) {
    const loadingState = action.payload.analyticTypes.reduce(
      loadingReducer(true),
      {}
    );
    yield put(updateTimeSeriesLoading(loadingState));

    yield race([
      call(fetchTimeSeries, action),
      take(updateTimeFrame.toString()),
    ]);

    const finishedState = action.payload.analyticTypes.reduce(
      loadingReducer(false),
      {}
    );
    yield put(updateTimeSeriesLoading(finishedState));
  }
}

export function* episodeTimeSeries(
  action: PayloadAction<EpisodeAnalyticsActionPayload>
): any {
  const { episodeId } = action.payload;

  if (episodeId) {
    const loadingState = action.payload.analyticTypes.reduce(
      loadingReducer(true),
      {}
    );
    yield put(updateTimeSeriesLoading(loadingState));

    yield race([
      call(fetchEpisodeTimeSeries, action),
      take(updateEpisodeTimeFrame.toString()),
    ]);

    const finishedState = action.payload.analyticTypes.reduce(
      loadingReducer(false),
      {}
    );
    yield put(updateTimeSeriesLoading(finishedState));
  }
}

export const timeSeriesSagas = [
  takeEvery(getPodcastsTimeSeries.toString(), podcastTimeSeries),
  takeEvery(getEpisodesTimeSeries.toString(), episodeTimeSeries),
];
