import { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { MusicLink } from 'components/MusicLink';
import BlurredModal from 'components/Modal/BlurredModal';
import { Input } from 'components/Input';
import ModalArtworkTile from 'components/ArtworkTile/components/ModalArtworkTile';
import { MusicButton } from 'components/MusicButton';
import TermsAndConditionsLink from 'components/Links/TermsAndConditions';
import {
  SELECT_UPDATE_RSS,
  SELECT_UPDATE_RSS_INPUT,
  UPDATE_RSS_MODAL,
  GO_PODCAST_RSS_URL,
} from 'constants/metrics';
import { PageContext } from 'contexts';
import { usePageView } from 'hooks/pageView';
import { closeModal } from 'store/slices/modal';
import { updateFeedUrl } from 'store/slices/feed';
import {
  modalPodcastFeedIdSelector,
  modalPodcastIdSelector,
  modalPodcastSelector,
} from 'store/selectors/modal';
import { validUrl } from 'utils/url';
import './styles.scss';
import { MAX_RSS_URL_LENGTH } from 'constants/modals';

const Title = (
  <h4>
    <FormattedMessage
      id="updateRssModal-title"
      defaultMessage="Update your RSS feed"
    />
  </h4>
);

const UpdateRSSModal = () => {
  usePageView(UPDATE_RSS_MODAL);
  const page = useContext(PageContext);
  const modalPodcastFeedId = useSelector(modalPodcastFeedIdSelector);
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const { feedUrl } = useSelector(modalPodcastSelector);

  const onFocus = () => {
    setError(false);
  };

  const onClick = () => {
    const rssUrl = inputRef.current?.value || '';
    if (!validUrl(rssUrl)) {
      setError(true);
    } else {
      dispatch(
        updateFeedUrl({
          feedId: modalPodcastFeedId,
          url: rssUrl,
          podcastId: modalPodcastId,
        })
      );
      dispatch(closeModal());
    }
  };

  return (
    <PageContext.Provider value={UPDATE_RSS_MODAL}>
      <BlurredModal title={Title}>
        <div className="UpdateRssModal__container">
          <p className="bodyPrimary secondary-text">
            <FormattedMessage
              id="updateRssModal-description"
              values={{ termsOfService: <TermsAndConditionsLink /> }}
            />
          </p>
          <div className="UpdateRssModal__tile">
            <ModalArtworkTile />
          </div>
          {error && (
            <label className="UpdateRssModal__input-error-label">
              <FormattedMessage id="invalid-url" defaultMessage="Invalid URL" />
            </label>
          )}

          {feedUrl && (
            <p className="UpdateRssModal__current-rss-container">
              <FormattedMessage
                id="current-rss"
                defaultMessage="Current RSS:"
              />

              <MusicLink
                className="UpdateRssModal__current-rss-url"
                kind="accent"
                href={feedUrl}
                data-id={`${GO_PODCAST_RSS_URL}-${page}`}
                target="_blank"
              >
                {feedUrl?.slice(0, MAX_RSS_URL_LENGTH)}...
              </MusicLink>
            </p>
          )}

          <Input
            ref={inputRef}
            placeholder={formatMessage({
              id: 'updateRssModal-placeholder',
              defaultMessage: 'Enter your new RSS feed URL',
            })}
            className={`UpdateRssModal__input ${error && 'error'}`}
            onFocus={onFocus}
            data-id={`${SELECT_UPDATE_RSS_INPUT}-${UPDATE_RSS_MODAL}`}
          />
          <MusicButton
            variant="solid"
            className="UpdateRssModal__button MusicButton__wide"
            data-id={`${SELECT_UPDATE_RSS}-${UPDATE_RSS_MODAL}`}
            onMusicActivate={onClick}
          >
            <FormattedMessage
              id="update-rss"
              defaultMessage="Update RSS Feed"
            />
          </MusicButton>
        </div>
      </BlurredModal>
    </PageContext.Provider>
  );
};

export default UpdateRSSModal;
