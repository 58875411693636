import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ModalArtworkTile from 'components/ArtworkTile/components/ModalArtworkTile';
import Loader from 'components/Modal/Loader';
import BlurredModal from 'components/Modal/BlurredModal';
import { MusicButton } from 'components/MusicButton';
import { PODCAST_ACTIVE_MODAL, SELECT_HIDE_PODCAST } from 'constants/metrics';
import { PageContext } from 'contexts';
import useGetPodcastDetails from 'hooks/getPodcastDetails';
import { modalPodcastIdSelector } from 'store/selectors/modal';
import { closeModal } from 'store/slices/modal';
import { hidePodcast } from 'store/slices/podcasts';
import './styles.scss';

const Title = (
  <h4>
    <FormattedMessage
      id="podcastActive-title"
      defaultMessage="Your show is now active"
    />
  </h4>
);

const PodcastActive = () => {
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  const { podcastDetailsLoading } = useGetPodcastDetails(modalPodcastId);
  const dispatch = useDispatch();

  const onHidePodcast = () => {
    dispatch(hidePodcast({ id: modalPodcastId }));
    dispatch(closeModal());
  };

  return (
    <PageContext.Provider value={PODCAST_ACTIVE_MODAL}>
      {podcastDetailsLoading ? (
        <Loader />
      ) : (
        <BlurredModal title={Title}>
          <div className="PodcastActive__content-container">
            <p className="PodcastActive__description bodyPrimary secondary-text">
              <FormattedMessage
                id="podcastActive-description"
                defaultMessage="It may take up to one hour before the show may be accessed on all devices"
              />
            </p>
            <ModalArtworkTile />
            <MusicButton
              variant="solid"
              href="/"
              className="PodcastActive__hide-button MusicButton__wide"
              data-id={`${SELECT_HIDE_PODCAST}-${PODCAST_ACTIVE_MODAL}`}
              onMusicActivate={onHidePodcast}
            >
              <FormattedMessage id="hide-show" defaultMessage="Hide Show" />
            </MusicButton>
          </div>
        </BlurredModal>
      )}
    </PageContext.Provider>
  );
};

export default PodcastActive;
