import { RootState } from 'store';

export const claimEmailLoadingSelector = (state: RootState): boolean =>
  !!state.loading.emailClaim;

export const podcastListLoadingSelector = (state: RootState): boolean =>
  !!state.loading.podcastList;

export const podcastDetailsLoadingSelector = (state: RootState): boolean =>
  !!state.loading.podcastDetails;

export const podcastTotalsLoadingSelector = (state: RootState): boolean =>
  !!state.loading.podcastTotals;

export const episodeTotalsLoadingSelector = (state: RootState): boolean =>
  !!state.loading.episodeTotals;

export const updateFeedUrlLoadingSelector = (state: RootState): boolean =>
  !!state.loading.updateFeedUrl;

export const episodesListLoadingSelector = (state: RootState): boolean =>
  !!state.loading.podcastList;

export const timeSeriesLoadingSelector = (analyticsType: string) => (
  state: RootState
): boolean =>
  !!state.loading.timeSeries && state.loading.timeSeries[analyticsType];

export const aggregateLoadingSelector = (state: RootState): boolean =>
  !!state.loading.aggregate;

export const episodePlaybacksLoadingSelector = (state: RootState): boolean =>
  !!state.loading.episodePlaybacks;
