export const ALL = 'all';
export const DAY = 'DAY';
export const STARTS = 'starts';
export const PLAYS = 'plays';
export const FOLLOWERS = 'followers';
export const LISTENERS = 'listeners';
export const ENGAGED_LISTENERS = 'engagedListeners';
export const DELTA = 'DELTA';
export const TOTAL = 'TOTAL';
export const PODCAST_ID = 'PODCAST_ID';
export const PRESET_TIME_FRAME = 'preset';
export const CUSTOM_TIME_FRAME = 'custom';
export const PODCAST = 'PODCAST';
export const EPISODE = 'EPISODE';

export const ANALYTIC_OPTIONS_TO_API_FIELDS_MAP = {
  starts: {
    aggregate: 'startsAggregate',
    totals: 'startsTotals',
    timeSeries: 'startsTimeSeries',
  },
  plays: {
    aggregate: 'playsAggregate',
    totals: 'playsTotals',
    timeSeries: 'playsTimeSeries',
  },
  listeners: {
    aggregate: 'listenersAggregate',
    totals: 'listenersTotals',
    timeSeries: 'listenersTimeSeries',
  },
  engagedListeners: {
    aggregate: 'engagedListenersAggregate',
    totals: 'engagedListenersTotals',
    timeSeries: 'engagedListenersTimeSeries',
  },
  followers: {
    aggregate: 'followsAggregate',
    totals: 'followsTotals',
    timeSeries: 'followsTimeSeries',
  },
};

export const ALL_OWNED_PAGINATION = {
  pagination: {
    allOwnedPodcasts: true,
  },
};
