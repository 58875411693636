import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ModalArtworkTile from 'components/ArtworkTile/components/ModalArtworkTile';
import SubmitRssLink from 'components/Links/SubmitRssFeed';
import Loader from 'components/Loader';
import BlurredModal from 'components/Modal/BlurredModal';
import { MusicButton } from 'components/MusicButton';
import {
  SELECT_RELEASE_OWNERSHIP_MODAL,
  RELEASE_OWNERSHIP_MODAL,
} from 'constants/metrics';
import { PageContext } from 'contexts';
import useGetPodcastDetails from 'hooks/getPodcastDetails';
import { usePageView } from 'hooks/pageView';
import { closeModal } from 'store/slices/modal';
import { unclaimPodcast } from 'store/slices/podcasts';
import { modalPodcastIdSelector } from 'store/selectors/modal';
import './styles.scss';

const Title = (
  <h4>
    <FormattedMessage
      id="releaseOwnershipModal-title"
      defaultMessage="Release Ownership of Your RSS Feed"
    />
  </h4>
);

const ReleaseOwnershipModal = () => {
  usePageView(RELEASE_OWNERSHIP_MODAL);
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  const { podcastDetailsLoading } = useGetPodcastDetails(modalPodcastId);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(unclaimPodcast({ id: modalPodcastId }));
    dispatch(closeModal());
  };

  return (
    <PageContext.Provider value={RELEASE_OWNERSHIP_MODAL}>
      {podcastDetailsLoading ? (
        <Loader />
      ) : (
        <BlurredModal title={Title}>
          <div className="ReleaseOwnershipModal__container">
            <p className="bodyPrimary secondary-text">
              <FormattedMessage
                id="releaseOwnershipModal-description"
                values={{
                  here: <SubmitRssLink />,
                }}
                defaultMessage="Release Ownership allows this show to be transferred or claimed by a new owner. The show will remain on your dashboard until a new owner has claimed it. New owners can claim ownership by submitting the show's RSS feed {here}"
              />
            </p>
            <div className="ReleaseOwnershipModal__description">
              <ModalArtworkTile />
            </div>
            <MusicButton
              variant="solid"
              className="ReleaseOwnershipModal__button MusicButton__wide"
              data-id={`${SELECT_RELEASE_OWNERSHIP_MODAL}-${RELEASE_OWNERSHIP_MODAL}`}
              onMusicActivate={onClick}
            >
              <FormattedMessage
                id="release-ownership"
                defaultMessage="Release Ownership"
              />
            </MusicButton>
          </div>
        </BlurredModal>
      )}
    </PageContext.Provider>
  );
};

export default ReleaseOwnershipModal;
