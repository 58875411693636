import { createSlice } from '@reduxjs/toolkit';
import { LOCALE, LOCALES, LOCALE_TYPES } from 'constants/locales';
import { PODCASTER_SUPPORT_EMAIL_TYPES } from 'constants/emails';
import { PODCASTER_SUPPORT_EMAILS } from 'constants/emails';
import { en_US } from 'constants/locales';

type LOCALE_SLICE = {
  code: LOCALE_TYPES;
  supportEmail: PODCASTER_SUPPORT_EMAIL_TYPES;
};

const browserLanguage = () =>
  LOCALES.includes(navigator.languages[0]) ? navigator.languages[0] : en_US;

const localeCode =
  (localStorage.getItem(LOCALE) as LOCALE_TYPES) || browserLanguage();

const initialState: LOCALE_SLICE = {
  code: localeCode,
  supportEmail: PODCASTER_SUPPORT_EMAILS[localeCode],
};

const localeSlice = createSlice({
  name: LOCALE,
  initialState,
  reducers: {
    setLocale: (_state, action) => action.payload,
  },
});

export const { setLocale } = localeSlice.actions;
export const { reducer } = localeSlice;
