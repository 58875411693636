import { takeLatest } from 'redux-saga/effects';
import {
  confirmFeed,
  sendEmail,
  submitFeed,
  validateFeed,
  updateFeedUrl,
} from 'store/slices/feed';
import { confirmFeedSaga } from './confirm';
import { submitFeedSaga } from './submit';
import { validateFeedSaga } from './validate';
import { sendEmailSaga } from './sendEmail';
import { updateFeedUrlSaga } from './updateFeedUrl';

export const feedSagas = [
  takeLatest(confirmFeed.toString(), confirmFeedSaga),
  takeLatest(sendEmail.toString(), sendEmailSaga),
  takeLatest(submitFeed.toString(), submitFeedSaga),
  takeLatest(validateFeed.toString(), validateFeedSaga),
  takeLatest(updateFeedUrl.toString(), updateFeedUrlSaga),
];
