import { FEATURES, DEFAULT_FEATURE_FLAGS } from 'constants/features';

export const checkForFeature = (feature: string) => {
  const localFeatureFlags = JSON.parse(localStorage.getItem(FEATURES) || '{}');
  const featureFlags = {
    ...DEFAULT_FEATURE_FLAGS,
    ...localFeatureFlags,
  };

  return !!featureFlags[feature];
};
