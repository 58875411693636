import { createAction, createSlice } from '@reduxjs/toolkit';
import { Podcast } from 'types/podcasts';
import { updateTimeFrame } from './analytics';

type PodcastEntity = Record<string, Podcast>;
type EpisodeEntity = Record<string, Episode>;

type EntitiesState = {
  podcasts: PodcastEntity;
  episodes: EpisodeEntity;
};

const initialState: EntitiesState = {
  podcasts: {},
  episodes: {},
};

const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    updatePodcastEntities: (state, action) => {
      Object.entries(action.payload as PodcastEntity).forEach(
        ([id, entity]) => {
          state.podcasts[id] = {
            ...state.podcasts[id],
            ...entity,
          };
        }
      );
    },
    updateEpisodeEntities: (state, action) => {
      Object.entries(action.payload as EpisodeEntity).forEach(
        ([id, entity]) => {
          state.episodes[id] = {
            ...state.episodes[id],
            ...entity,
          };
        }
      );
    },
    updatePodcastEpisodes: (state, action) => {
      const { podcastId, episodes } = action.payload;
      const {
        episodeListCursor,
        episodeList,
        episodesListHasNextPage,
      } = episodes;

      state.podcasts[podcastId] = state.podcasts[podcastId] || [];
      const existingEpisodeList = state.podcasts[podcastId]?.episodeList || [];
      const set = new Set([...existingEpisodeList, ...episodeList]);

      state.podcasts[podcastId].episodeListCursor = episodeListCursor;
      state.podcasts[
        podcastId
      ].episodeListHasNextPage = episodesListHasNextPage;
      state.podcasts[podcastId].episodeList = Array.from(set);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTimeFrame, (state, action) => {
      state.podcasts[action.payload.podcastId] =
        state.podcasts[action.payload.podcastId] || {};
      state.podcasts[action.payload.podcastId].episodeListCursor = undefined;
      state.podcasts[action.payload.podcastId].episodeList = [];
    });
  },
});

export const getEpisodes = createAction<EpisodesActionPayload>(
  'episodes/getEpisodes'
);

export const {
  updatePodcastEntities,
  updateEpisodeEntities,
  updatePodcastEpisodes,
} = entitiesSlice.actions;

export const { reducer } = entitiesSlice;
