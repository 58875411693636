import { aggregateSagas } from './aggregate';
import { timeSeriesSagas } from './timeSeries';
import { totalsSagas } from './totals';
import { playbackSegmentsSagas } from './playbackSegments';

export const analyticsSagas = [
  ...aggregateSagas,
  ...timeSeriesSagas,
  ...totalsSagas,
  ...playbackSegmentsSagas,
];
