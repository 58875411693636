import cookiePreferenceEN from '@translations/messages-en-US.puff.json';

const REGEX = /<a href='([^']*)'>([^<]*)<\/a>/g; // regex for parsing <a> tags in the cookie consent legalese

const convertRegExArray = (regExArray: RegExpMatchArray) => ({
  fullTag: regExArray[0],
  link: regExArray[1],
  displayText: regExArray[2],
  replaceValue: regExArray[2].replace(/[-\s]/g, ''),
});

export const formatTags = (cookiePreferenceLegalese: string) => {
  const tags = getTags(cookiePreferenceLegalese);
  return cookiePreferenceLegalese.replace(REGEX, (a) => {
    const currentTag = tags.find((tag) => tag.fullTag === a);
    return `{${currentTag?.replaceValue}}`;
  });
};

export const getTags = (cookiePreferenceLegalese: string) =>
  Array.from(cookiePreferenceLegalese.matchAll(REGEX)).map((tag) =>
    convertRegExArray(tag)
  );

export const formatCookiePreferenceMessages = (
  messages: typeof cookiePreferenceEN
) => {
  let legalese = messages.resources.cookieConsentLegaleseContent;

  // catch the "\n" in the legalese representing the line break
  legalese = legalese.replace(/\n/g, '{br}');
  // catch the "{vendor-count}" in the leagalese and remove the "-" because react-intl does not like
  // special characters in the placeholders
  legalese = legalese.replace(/{vendor-count}/g, '{vendorCount}');

  // map the formatted tags to display values as these will be used as ids as well
  //  { CookieNotice: "Cookie Notice" }
  const tagValues = getTags(legalese).reduce(
    (acc, tag) => ({
      ...acc,
      [tag.replaceValue]: tag.displayText,
    }),
    {}
  );

  return {
    resources: {
      ...messages.resources,
      ...tagValues,
      cookieConsentLegaleseContent: formatTags(legalese),
    },
  };
};
