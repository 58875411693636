import { CSRF_API, SUBMIT_RSS_ROUTE } from 'constants/routes';

export const AUTH_PORTAL_ROOT = 'https://www.amazon.com/ap';

export const authPortalBaseParams = (language: string = 'en-US') =>
  '?openid.ns=http://specs.openid.net/auth/2.0' +
  '&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select' +
  '&openid.identity=http://specs.openid.net/auth/2.0/identifier_select' +
  '&pageId=amzn_ziggy_ui' +
  '&openid.assoc_handle=amzn_podcaster_portal_us' +
  `&language=${language.replace('-', '_')}`;

export const AUTH_ENV =
  window.location.hostname === 'local.podcasters.amazon.com'
    ? 'beta.podcasters.amazon.com'
    : window.location.host;

export const CIRRUS_RCA_API = {
  NA: 'https://music.amazon.com/NA/api/cirrus/v3/',
  FE: 'https://music.amazon.com/FE/api/cirrus/v3/',
  EU: 'https://music.amazon.com/EU/api/cirrus/v3/',
};

export const AMAZON_MUSIC_LINK = 'https://music.amazon.com/podcasts';
export const AUDIBLE_LINK = 'https://www.audible.com/ep/podcasts';
export const AMAZON_INFLUENCER_LINK =
  'https://www.amazonmusicforinfluencers.com/splash';
export const ART19_PUBLISHERS_LINK = 'https://art19.com/publishers';

export const ALEXA_GUIDELINES_LINK =
  'https://developer.amazon.com/en-US/alexa/branding/alexa-guidelines';
export const ECHO_GUIDELINES_LINK =
  'https://developer.amazon.com/en-US/alexa/branding/echo-guidelines';

export const LOCAL_URL = 'https://local.podcasters.amazon.com:3000';
export const PROD_URL = 'https://podcasters.amazon.com';

export const LISTEN_ON_AM_BUTTONS_LINK =
  'https://podcaster-portal-promo-tools.s3.us-west-1.amazonaws.com/WW+Listen+on+Amazon+Music.zip';

export const signInUrl = (language: string, returnUrl: string) =>
  `${AUTH_PORTAL_ROOT}/signin` +
  authPortalBaseParams(language) +
  '&openid.mode=checkid_setup' +
  `&openid.return_to=https://${AUTH_ENV}${CSRF_API}?path=https://${window.location.host}${returnUrl}`;

export const signInToDifferentAccountUrl = (
  language: string,
  returnUrl: string
) =>
  `${AUTH_PORTAL_ROOT}/signin` +
  authPortalBaseParams(language) +
  '&openid.mode=logout' +
  `&openid.return_to=${encodeURIComponent(signInUrl(language, returnUrl))}`;

export const registerUrl = (language: string) =>
  `${AUTH_PORTAL_ROOT}/register` +
  authPortalBaseParams(language) +
  '&openid.mode=checkid_setup' +
  `&openid.return_to=https://${AUTH_ENV}${CSRF_API}?path=https://${window.location.host}${SUBMIT_RSS_ROUTE}`;

export const SIGN_OUT_URL =
  `${AUTH_PORTAL_ROOT}/signin` +
  authPortalBaseParams() +
  '&openid.mode=logout' +
  `&openid.return_to=https://${window.location.host}`;

export const SOCIAL_TEMPLATES_LINK =
  'https://podcaster-portal-promo-tools.s3.us-west-1.amazonaws.com/Amazon%2BMusic%2BPodcasts_Social%2BTemplates.zip';

export const ANCHOR_UPDATE_DEFAULT_EMAIL_LINK =
  'https://help.anchor.fm/hc/en-us/articles/360028557892-Adding-your-email-address-to-your-podcast-RSS-feed';
export const SPREAKER_UPDATE_DEFAULT_EMAIL_LINK =
  'https://help.spreaker.com/en/articles/3958023-how-to-claim-a-podcast-on-spotify-and-other-distribution-platforms';
export const SOUNDCLOUD_UPDATE_DEFAULT_EMAIL_LINK =
  'https://help.soundcloud.com/hc/en-us/articles/115003447807-Changing-the-email-address-in-your-RSS-feed';

export const PRIVACY_NOTICE_LINK =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&view-type=content-only';
export const SECONDARY_PRIVACY_NOTICE_LINK =
  'https://music.amazon.com/help?nodeId=202196530';
export const CONDITIONS_OF_USE_LINK =
  'https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&nodeId=508088';
export const INTEREST_BASED_ADS_LINK =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050&view-type=content-only';

export const COOKIE_NOTICE_LINK_DE =
  'https://www.amazon.de/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';
export const COOKIE_NOTICE_LINK_US =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';
export const COOKIE_NOTICE_LINK_GB =
  'https://www.amazon.uk/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';
export const COOKIE_NOTICE_LINK_ES =
  'https://www.amazon.es/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';
export const COOKIE_NOTICE_LINK_MX =
  'https://www.amazon.com.mx/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';
export const COOKIE_NOTICE_LINK_FR =
  'https://www.amazon.fr/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';
export const COOKIE_NOTICE_LINK_IT =
  'https://www.amazon.it/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';
export const COOKIE_NOTICE_LINK_JP =
  'https://www.amazon.jp/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';
export const COOKIE_NOTICE_LINK_BR =
  'https://www.amazon.com.br/gp/help/customer/display.html?nodeId=201890250&view-type=content-only';

export const COOKIE_PRIVACY_NOTICE_LINK_DE =
  'https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';
export const COOKIE_PRIVACY_NOTICE_LINK_US =
  'https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';
export const COOKIE_PRIVACY_NOTICE_LINK_GB =
  'https://www.amazon.uk/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';
export const COOKIE_PRIVACY_NOTICE_LINK_ES =
  'https://www.amazon.es/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';
export const COOKIE_PRIVACY_NOTICE_LINK_MX =
  'https://www.amazon.com.mx/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';
export const COOKIE_PRIVACY_NOTICE_LINK_FR =
  'https://www.amazon.fr/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';
export const COOKIE_PRIVACY_NOTICE_LINK_IT =
  'https://www.amazon.it/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';
export const COOKIE_PRIVACY_NOTICE_LINK_JP =
  'https://www.amazon.jp/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';
export const COOKIE_PRIVACY_NOTICE_LINK_BR =
  'https://www.amazon.com.br/gp/help/customer/display.html?nodeId=201909010&ref_=footer_privacy';

export const COOKIE_PREFERENCES_LINK =
  'https://www.amazon.com/privacyprefs/music';
