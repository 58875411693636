import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MusicLink } from 'components/MusicLink';
import { supportEmailSelector } from 'store/selectors/locale';
import { EMAIL_TO_ENUM_MAP } from 'constants/metrics';
import { PageContext } from 'contexts';
import { FormattedMessage } from 'react-intl';
import { PODCASTER_SUPPORT_EMAIL_TYPES } from 'constants/emails';

type ContactUsProps = {
  email?: PODCASTER_SUPPORT_EMAIL_TYPES;
};

const ContactUs = ({ email }: ContactUsProps) => {
  const page = useContext(PageContext);
  let supportEmail = useSelector(supportEmailSelector);
  supportEmail = email ?? supportEmail;
  const dataId = EMAIL_TO_ENUM_MAP[supportEmail];

  const onClick = () => {
    // Mozilla issue prevents us from using target="_blank" correctly: https://bugzilla.mozilla.org/show_bug.cgi?id=646552
    window.open(`mailto:${supportEmail}`, '_blank');
  };

  return (
    <MusicLink
      kind="accent"
      href="#"
      data-id={`${dataId}-${page}`}
      onClick={onClick}
    >
      <FormattedMessage id="contact-us" />
    </MusicLink>
  );
};

export default ContactUs;
