import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { localeSelector } from 'store/selectors/locale';
import { MessageLoader, DefaultMessages } from 'utils/translation';

const useGetLocaleMessages = <D extends DefaultMessages>(
  messageLoader: MessageLoader,
  defaultMessages: D
) => {
  const locale = useSelector(localeSelector);
  const [messages, setMessages] = useState(defaultMessages);
  useEffect(() => {
    messageLoader[locale]().then(({ default: translations }) => {
      if (messages !== translations) {
        setMessages(translations as D);
      }
    });
  }, [locale]);

  return messages;
};

export default useGetLocaleMessages;
