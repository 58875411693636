import { MusicImage } from '@amzn/DMWebUIComponents/dist/react/components';
import './styles.scss';

const ArtworkTileLabel = ({
  imageUrl,
  status,
  title,
  hostingProvider,
}: ArtworkTileLabelProps) => (
  <div className="ArtworkTileLabel__container">
    <MusicImage
      kind="square"
      src={imageUrl}
      size={250}
      className="ArtworkTileLabel__img"
    />
    <p className="label small-text">{status}</p>
    <p className="ArtworkTileLabel__title primary-text">{title}</p>
    <p className="ArtworkTileLabel__subtitle secondary-text">
      {hostingProvider}
    </p>
  </div>
);

export default ArtworkTileLabel;
