import { RootState } from 'store';

const EMPTY_OBJECT = {};

export const entitiesSelector = (state: RootState) =>
  state.entities || EMPTY_OBJECT;

export const podcastsEntitiesSelector = (state: RootState) =>
  state.entities.podcasts || EMPTY_OBJECT;

export const episodesEntitiesSelector = (state: RootState) =>
  state.entities.episodes || EMPTY_OBJECT;
