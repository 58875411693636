export const FEATURES = 'features';

// Features to toggle
export const EXAMPLE_FEATURE = 'exampleFeature';
export const AUDIENCE_INSIGHTS_FEATURE = 'audienceInsightsFeature';
export const EXCHANGE_TOKEN_FEATURE = 'exchangeTokenFeature';
export const DATE_RANGE_FEATURE = 'dateRangeFeature';
export const CSV_DOWNLOAD_FEATURE = 'csvDownloadFeature';
export const EPISODE_DETAILS_FEATURE = 'episodeDetailsFeature';
export const EPISODE_PERFORMANCE_CHART_FEATURE =
  'episodePerformanceChartFeature';
export const COOKIE_PREFERENCE_FEATURE = 'cookiePreferenceFeature';
export const COOKIE_PREFERENCE_TEST_MODE_FEATURE =
  'cookiePreferenceTestModeFeature';

export const DEFAULT_FEATURE_FLAGS = {
  [EXAMPLE_FEATURE]: true,
  [AUDIENCE_INSIGHTS_FEATURE]: true,
  [EXCHANGE_TOKEN_FEATURE]: true,
  [DATE_RANGE_FEATURE]: true,
  [CSV_DOWNLOAD_FEATURE]: true,
  [EPISODE_DETAILS_FEATURE]: true,
  [EPISODE_PERFORMANCE_CHART_FEATURE]: true,
  [COOKIE_PREFERENCE_FEATURE]: true,
  [COOKIE_PREFERENCE_TEST_MODE_FEATURE]: false,
};
