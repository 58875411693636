import { call, put } from 'redux-saga/effects';
import { postWithCredentials } from 'api';
import { ERROR, SUCCESS, UPDATE_RSS_SUCCESS } from 'constants/status';
import { FEED_URL_UPDATE_API } from 'constants/routes';
import { CLAIM_NOT_EXIST, DEPENDENCY_ERROR } from 'constants/feed';
import { CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME } from 'constants/modals';
import { openModal } from 'store/slices/modal';
import { updateFeedUrlLoading } from 'store/slices/loading';
import { addApiStatus } from 'store/slices/apiStatus';

export function* updateFeedUrlSaga(
  action: {
    payload: { feedId: string; url: string; podcastId: string };
    type: string;
  },
  retryCount = 1
): any {
  const feedId = action.payload.feedId;
  const url = action.payload.url;
  yield put(updateFeedUrlLoading(true));

  try {
    const response = yield call(postWithCredentials, {
      path: `${FEED_URL_UPDATE_API}/${feedId}`,
      body: {
        feedUpdatable: {
          feedUrl: url,
        },
      },
    });

    switch (response.status) {
      case 200:
        yield put(
          addApiStatus({
            type: SUCCESS,
            code: UPDATE_RSS_SUCCESS,
            podcastId: action.payload.podcastId,
          })
        );
        break;
      case 400: {
        const json = yield call([response, 'json']);
        yield put(
          addApiStatus({
            type: ERROR,
            code: json.errorCode || CLAIM_NOT_EXIST,
            podcastId: action.payload.podcastId,
          })
        );
        break;
      }
      case 401:
      case 403: {
        yield put(openModal({ name: CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME }));
        break;
      }
      default:
        if (retryCount < 2) {
          yield call(updateFeedUrlSaga, action, retryCount + 1);
        } else {
          yield put(
            addApiStatus({
              type: ERROR,
              code: DEPENDENCY_ERROR,
              podcastId: action.payload.podcastId,
            })
          );
        }
    }
  } catch (error) {
    yield put(
      addApiStatus({
        type: ERROR,
        code: DEPENDENCY_ERROR,
        podcastId: action.payload.podcastId,
      })
    );
  }

  yield put(updateFeedUrlLoading(false));
}
