import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'store/slices/modal';
import { MusicButton } from 'components/MusicButton';
import { MusicLink } from 'components/MusicLink';
import { registerUrl, signInUrl } from 'constants/links';
import {
  CONNECT_ACCOUNT_MODAL,
  GO_CREATE_NEW_ACCOUNT,
  SELECT_CLOSE_MODAL,
  SELECT_SIGN_IN,
} from 'constants/metrics';
import { SUBMIT_RSS_ROUTE } from 'constants/routes';
import { usePageView } from 'hooks/pageView';
import { localeSelector } from 'store/selectors/locale';
import { reauthenticateUrl } from 'utils/url';
import './styles.scss';

type ConnectAccountProps = {
  returnHome?: boolean;
  url?: string;
};

export const ConnectAccount = ({
  returnHome = false,
  url,
}: ConnectAccountProps) => {
  const dispatch = useDispatch();
  const locale = useSelector(localeSelector);
  usePageView(CONNECT_ACCOUNT_MODAL);

  const urlWithLanguage = url ? url : signInUrl(locale, SUBMIT_RSS_ROUTE);

  const onClose = () => {
    dispatch(closeModal());
  };

  const buttonProps = returnHome ? { href: '/' } : { onMusicActivate: onClose };

  return (
    <div className="ConnectAccount">
      <div className="ConnectAccount__body">
        <MusicButton
          class="ConnectAccount__close-button"
          icon-only
          icon-name="cancel"
          variant="glass"
          size="small"
          data-id={`${SELECT_CLOSE_MODAL}-${CONNECT_ACCOUNT_MODAL}`}
          {...buttonProps}
        />
        <h4 className="ConnectAccount__title">
          <FormattedMessage id="connectYourAmazonAccount" />
        </h4>
        <p>
          <FormattedMessage id="connectYourAmazonAccountMessage" />
        </p>
        <MusicButton
          variant="solid"
          href={urlWithLanguage}
          className="ConnectAccount__sign-in-button MusicButton__wide"
          data-id={`${SELECT_SIGN_IN}-${CONNECT_ACCOUNT_MODAL}`}
        >
          <FormattedMessage id="signInWithMyAmazonId" />
        </MusicButton>
        <p className="ConnectAccount__new-account">
          <FormattedMessage id="manageUsingANewAccount" />{' '}
          <MusicLink
            kind="accent"
            href={registerUrl(locale)}
            className="ConnectAccount__create-new-account"
            data-id={`${GO_CREATE_NEW_ACCOUNT}-${CONNECT_ACCOUNT_MODAL}`}
          >
            <FormattedMessage id="createNewAccount" />
          </MusicLink>
        </p>
      </div>
    </div>
  );
};

export const ConnectAccountHome = () => {
  const locale = useSelector(localeSelector);
  return <ConnectAccount returnHome url={reauthenticateUrl(locale)} />;
};
