import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ModalArtworkTile from 'components/ArtworkTile/components/ModalArtworkTile';
import Loader from 'components/Loader';
import BlurredModal from 'components/Modal/BlurredModal';
import { MusicButton } from 'components/MusicButton';
import { SELECT_UNHIDE_PODCAST, UNHIDE_PODCAST_MODAL } from 'constants/metrics';
import { PageContext } from 'contexts';
import useGetPodcastDetails from 'hooks/getPodcastDetails';
import { usePageView } from 'hooks/pageView';
import { closeModal } from 'store/slices/modal';
import { unhidePodcast } from 'store/slices/podcasts';
import { modalPodcastIdSelector } from 'store/selectors/modal';
import './styles.scss';

const Title = (
  <h4>
    <FormattedMessage
      id="unhidePodcast-title"
      defaultMessage="Unhide Your Show"
    />
  </h4>
);

const UnhidePodcast = () => {
  usePageView(UNHIDE_PODCAST_MODAL);
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  const { podcastDetailsLoading } = useGetPodcastDetails(modalPodcastId);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(unhidePodcast({ id: modalPodcastId }));
    dispatch(closeModal());
  };

  return (
    <PageContext.Provider value={UNHIDE_PODCAST_MODAL}>
      {podcastDetailsLoading ? (
        <Loader />
      ) : (
        <BlurredModal title={Title}>
          <div className="UnhidePodcastModal__container">
            <p className="bodyPrimary secondary-text">
              <FormattedMessage
                id="unhidePodcast-description"
                defaultMessage="Unhiding your show makes it available to listeners on Amazon Music and Audible. It can take up to 1 hour to unhide."
              />
            </p>
            <div className="UnhidePodcastModal__description">
              <ModalArtworkTile />
            </div>
            <MusicButton
              variant="solid"
              href="/"
              className="UnhidePodcastModal__unhide-button MusicButton__wide"
              data-id={`${SELECT_UNHIDE_PODCAST}-${UNHIDE_PODCAST_MODAL}`}
              onMusicActivate={onClick}
            >
              <FormattedMessage id="unhide-show" />
            </MusicButton>
          </div>
        </BlurredModal>
      )}
    </PageContext.Provider>
  );
};

export default UnhidePodcast;
