import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { MusicButton } from 'components/MusicButton';
import BaseModal from 'components/Modal/BaseModal';
import { SELECT_CLOSE_MODAL } from 'constants/metrics';
import { PageContext } from 'contexts';
import { closeModal } from 'store/slices/modal';
import './styles.scss';

const BlurredModal = ({
  children,
  title,
  routeOnClose,
  containerClassName,
  cancelButton = true,
}: BlurredModalProps) => {
  const dispatch = useDispatch();
  const page = useContext(PageContext);

  const dismissModal = () => {
    dispatch(closeModal());
  };

  const onClose = routeOnClose
    ? { href: routeOnClose }
    : { onMusicActivate: dismissModal };

  return (
    <BaseModal>
      <div className={`BlurredModal__container ${containerClassName}`}>
        <div className="BlurredModal__body">
          {cancelButton && (
            <MusicButton
              class="BlurredModal__close-button"
              icon-only
              icon-name="cancel"
              variant="glass"
              size="small"
              data-id={`${SELECT_CLOSE_MODAL}-${page}`}
              {...onClose}
            />
          )}
          {title && <div className="BlurredModal__title">{title}</div>}
          {children}
        </div>
      </div>
    </BaseModal>
  );
};

export default BlurredModal;
