import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const sideNavSlice = createSlice({
  name: 'sideNav',
  initialState,
  reducers: {
    toggleSideNav: (state) => !state,
    dismissSideNav: () => initialState,
  },
});

export const { toggleSideNav, dismissSideNav } = sideNavSlice.actions;
export const { reducer } = sideNavSlice;
