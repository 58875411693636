import { Suspense } from 'react';
import { HeaderWithCancel } from 'components/Header/HeaderWithCancel';
import { TOSLoader } from 'utils/translation';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/selectors/locale';
import Loader from 'components/Loader';
import { TERMS_OF_SERVICE_PAGE, TOS_MODAL } from 'constants/metrics';
import { usePageView } from 'hooks/pageView';
import { PageContext } from 'contexts';
import './styles.scss';

type TermsOfServiceProps = {
  modal?: boolean;
};

const TermsOfService = ({ modal }: TermsOfServiceProps) => {
  const page = modal ? TOS_MODAL : TERMS_OF_SERVICE_PAGE;
  usePageView(page);
  const locale = useSelector(localeSelector);
  const TosComponent = TOSLoader[locale];

  return (
    <PageContext.Provider value={page}>
      {!modal && <HeaderWithCancel />}
      <div className="TermsOfService__container">
        <Suspense fallback={<Loader />}>
          <TosComponent />
        </Suspense>
      </div>
    </PageContext.Provider>
  );
};

export default TermsOfService;
