import { EXPLICIT } from 'constants/podcasts';
import { Podcast, PodcastListResponse } from 'types/podcasts';

const decoratePodcast = (podcast: Podcast) => ({
  ...podcast,
  isExplicit: podcast.parentalAdvisory === EXPLICIT,
  hasTrailer: !!podcast.trailerEpisodes?.length,
  trailerEpisode:
    podcast.trailerEpisodes?.[podcast.trailerEpisodes?.length - 1],
  numberOfSeasons: podcast.seasons?.length,
  parentCategories: podcast.resolvedCategories
    ?.filter((item) => !item.parentCategoryId)
    .map((item) => item.category.categoryTitle),
  subcategories: podcast.resolvedCategories
    ?.filter((item) => item.parentCategoryId)
    .map((item) => item.category.categoryTitle),
  status: podcast.anticipatedStatus?.status || podcast.status,
});

const podcastEntitiesReducer = (
  accumulator: Record<string, Podcast>,
  podcast: Podcast
) => ({
  ...accumulator,
  [podcast.podcastId]: decoratePodcast(podcast),
});

export const normalizePodcast = (podcastArray: Array<Podcast>) =>
  podcastArray.reduce(podcastEntitiesReducer, {});

export const normalizePodcasts = (data: PodcastListResponse) => ({
  hasNextPage: data.hasNextPage,
  cursor: data.cursor,
  podcastIds: data.items.map((item: Podcast) => item.podcastId),
  entities: data.items.reduce(podcastEntitiesReducer, {}),
});
