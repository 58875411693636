import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { MusicButton } from 'components/MusicButton';
import { GO_FACEBOOK, GO_TWITTER, GO_EMAIL } from 'constants/metrics';
import { PageContext } from 'contexts';
import './styles.scss';

const onClick = (link: string) => {
  // mozilla woes: https://bugzilla.mozilla.org/show_bug.cgi?id=646552
  window.open(link, '_blank');
};

const ShareModal = ({
  publicUrl,
  facebookUrl,
  twitterUrl,
  emailUrl,
}: ShareModalProps) => {
  const page = useContext(PageContext);
  return (
    <>
      <div className="Share__link-container">
        <p className="Share__link-text">
          <FormattedMessage id="share-link" defaultMessage="Share link:" />
        </p>
        <div className="Share__link">{publicUrl}</div>
      </div>
      <div className="Share__buttons">
        <div className="Share__buttons-social">
          <MusicButton
            href={facebookUrl}
            variant="glass"
            size="small"
            iconName="sharefacebook"
            iconOnly
            target="_blank"
            data-id={`${GO_FACEBOOK}-${page}`}
          />
          <MusicButton
            href={twitterUrl}
            variant="glass"
            size="small"
            iconName="sharetwitter"
            iconOnly
            target="_blank"
            data-id={`${GO_TWITTER}-${page}`}
          />
          <MusicButton
            variant="glass"
            size="small"
            iconName="shareemail"
            iconOnly
            onMusicActivate={() => onClick(emailUrl)}
            data-id={`${GO_EMAIL}-${page}`}
          />
        </div>
      </div>
    </>
  );
};

export default ShareModal;
