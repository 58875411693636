import { ReactElement } from 'react';
import './styles.scss';

type HeaderProps = {
  children?: Array<ReactElement> | ReactElement;
};

const Header = ({ children }: HeaderProps) => (
  <header className="Header">{children}</header>
);

export default Header;
