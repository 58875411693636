import { call, put, select } from 'redux-saga/effects';
import { postWithCredentials } from 'api';
import { checkFeedClaims, submitFeed, updateFeed } from 'store/slices/feed';
import { CLAIMS_SUBMIT_API } from 'constants/routes';
import { addApiStatus } from 'store/slices/apiStatus';
import {
  feedcountryOfOriginSelector,
  feedIdSelector,
  feedUrlSelector,
} from 'store/selectors/feed';
import { localeSelector } from 'store/selectors/locale';
import { openModal } from 'store/slices/modal';
import { CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME } from 'constants/modals';
import { emailClaimLoading } from 'store/slices/loading';
import { ERROR } from 'constants/status';

export function* submitFeedSaga(
  action: typeof submitFeed,
  retryCount = 1
): any {
  const feedId = yield select(feedIdSelector);
  const feedUrl = yield select(feedUrlSelector);
  const countryOfOrigin = yield select(feedcountryOfOriginSelector);
  const locale = yield select(localeSelector);

  yield put(emailClaimLoading(true));
  try {
    const response = yield call(postWithCredentials, {
      body: {
        feedId,
        feedUrl,
        locale,
        ...(!!countryOfOrigin && { countryOfOrigin }),
      },
      path: CLAIMS_SUBMIT_API,
    });

    if (response.ok) {
      const json = yield call([response, 'json']);
      yield put(updateFeed({ id: json.feedId }));
      localStorage.setItem('feedId', json.feedId);
      yield put(checkFeedClaims());
    } else {
      switch (response.status) {
        case 400: {
          const json = yield call([response, 'json']);
          console.error(json);
          yield put(addApiStatus({ type: ERROR }));
          break;
        }
        case 401:
        case 403: {
          yield put(
            openModal({ name: CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME })
          );
          break;
        }
        default:
          if (retryCount < 2) {
            yield call(submitFeedSaga, action, retryCount + 1);
          } else {
            yield put(addApiStatus({ type: ERROR }));
          }
      }
    }
  } catch (error) {
    if (retryCount < 2) {
      yield call(submitFeedSaga, action, retryCount + 1);
    } else {
      yield put(addApiStatus({ type: ERROR }));
    }
  }
  yield put(emailClaimLoading(false));
}
