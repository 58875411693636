import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { MusicButton as BauhausMusicButton } from '@amzn/DMWebUIComponents/dist/react/components';

export const MusicButton = ({ href, ...rest }: any) => {
  const history = useHistory();
  const navigate = useCallback(() => {
    history.push(href);
  }, [history, href]);

  if (href) {
    if (href?.startsWith('/')) {
      return <BauhausMusicButton onMusicActivate={navigate} {...rest} />;
    }
    return <BauhausMusicButton href={href} {...rest} />;
  }
  return <BauhausMusicButton {...rest} />;
};
