import { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { PageContext } from 'contexts';
import { SELECT_LOGO } from 'constants/metrics';
import { HOME_ROUTE } from 'constants/routes';

const Logo = () => {
  const page = useContext(PageContext);

  return (
    <Link to={HOME_ROUTE} data-id={`${SELECT_LOGO}-${page}`}>
      <img className="Header__logo" src={logo} alt="Amazon Music | Podcasts" />
    </Link>
  );
};

export default Logo;
