export const US_SOCIAL = '@AmazonMusic';
export const GB_SOCIAL = '@AmazonMusicUK';
export const DE_SOCIAL = '@AmazonMusicDE';
export const JP_SOCIAL = '@AmazonMusicJP';
export const MX_SOCIAL = '@AmazonMusicMX';
export const BR_SOCIAL = '@AmazonMusicBR';
export const IT_SOCIAL = '@AmazonMusicIT';
export const FR_SOCIAL = '@AmazonMusicFR';
export const ES_SOCIAL = '@AmazonMusicES';

export const PODCASTS_ON_AMAZON_MUSIC_HASHTAG_US = '#PodcastsOnAmazonMusic';
export const US_PROMO_TOOLS_SOCIAL = '@PlayMorePods';
