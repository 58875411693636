import { HeaderWithCancelToPodcasts } from 'components/Header/HeaderWithCancel';
import Loader from 'components/Loader';

const Loading = () => (
  <>
    <HeaderWithCancelToPodcasts />
    <Loader />
  </>
);

export default Loading;
