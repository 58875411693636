import { PayloadAction } from '@reduxjs/toolkit';
import { postWithCredentials } from 'api';
import { ERROR } from 'constants/api';
import { EPISODE_PLAYBACKS } from 'constants/episodeFailures';
import { EPISODE_ANALYTICS_API } from 'constants/routes';
import { call, takeEvery, put, race, take } from 'redux-saga/effects';
import {
  getEpisodesPlaybackSegments,
  updateEpisodeAnalytics,
  updateEpisodeTimeFrame,
} from 'store/slices/analytics';
import { addApiStatus } from 'store/slices/apiStatus';
import { updateEpisodePlaybacksLoading } from 'store/slices/loading';
import { parseISOWithoutHour } from 'utils/date';

export function* fetchEpisodePlaybackSegments(
  action: PayloadAction<EpisodeAnalyticsPlaybackActionPayload>
): any {
  const { episodeId, startTime, endTime } = action.payload;
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);

  try {
    const response = yield call(postWithCredentials, {
      path: EPISODE_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        episodeIds: [episodeId],
        playbackSegments: true,
      },
    });

    const { data } = yield call([response, 'json']);

    yield put(
      updateEpisodeAnalytics({
        episodes: {
          [episodeId]: data as EpisodeAnalytic,
        },
      })
    );
  } catch (e) {
    yield put(addApiStatus({ type: ERROR, code: EPISODE_PLAYBACKS }));
  }
}

export function* episodePlaybackSegments(
  action: PayloadAction<EpisodeAnalyticsPlaybackActionPayload>
): any {
  const { episodeId } = action.payload;

  if (episodeId) {
    yield put(updateEpisodePlaybacksLoading(true));

    yield race([
      call(fetchEpisodePlaybackSegments, action),
      take(updateEpisodeTimeFrame.toString()),
    ]);

    yield put(updateEpisodePlaybacksLoading(false));
  }
}

export const playbackSegmentsSagas = [
  takeEvery(getEpisodesPlaybackSegments.toString(), episodePlaybackSegments),
];
