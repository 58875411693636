import { call, put } from 'redux-saga/effects';
import { postWithCredentials } from 'api';
import { CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME } from 'constants/modals';
import { CLAIMS_CONFIRM_API } from 'constants/routes';
import { ERROR, SUCCESS } from 'constants/status';
import { claim } from 'store/slices/claim';
import { confirmFeed, feed } from 'store/slices/feed';
import { getQueryStringParams } from 'utils/url';
import { openModal } from 'store/slices/modal';
import { normalizeFeed } from 'utils/normalizer/feed';

export function* confirmFeedSaga(
  action: typeof confirmFeed,
  retryCount = 1
): any {
  const [token] = getQueryStringParams(['token']);

  try {
    const response = yield call(postWithCredentials, {
      body: {},
      path: `${CLAIMS_CONFIRM_API}?confirmToken=${token}`,
    });

    if (response.ok) {
      const json = yield call([response, 'json']);
      const normalizedFeed = normalizeFeed(json);
      yield put(feed(normalizedFeed));
      yield put(claim({ status: SUCCESS }));
    } else {
      switch (response.status) {
        case 400: {
          const json = yield call([response, 'json']);
          yield put(claim({ status: ERROR, errorCode: json.errorCode }));
          break;
        }
        case 401:
        case 403: {
          yield put(
            openModal({ name: CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME })
          );
          break;
        }
        default:
          if (retryCount < 2) {
            yield call(confirmFeedSaga, action, retryCount + 1);
          } else {
            yield put(claim({ status: ERROR }));
          }
      }
    }
  } catch (error) {
    yield put(claim({ status: ERROR }));
  }
}
