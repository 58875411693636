import { FormattedMessage } from 'react-intl';
import { MusicLink } from 'components/MusicLink';
import { COOKIE_CONSENT_VENDOR_COUNT } from 'constants/modals';

export const CookieConsentBody = ({ tagObjects }: CookieConsentBodyProps) => {
  const bodyLink = (url: string, idValue: string) => (
    <MusicLink kind="accent" href={url}>
      <FormattedMessage {...{ id: idValue }} />
    </MusicLink>
  );

  const bodyLinkValues = tagObjects.reduce(
    (acc, tag) => ({
      ...acc,
      [tag.replaceValue]: bodyLink(tag.link, tag.replaceValue),
    }),
    {}
  );

  return (
    <p className="CookieConsent__bannerOne">
      <FormattedMessage
        id="cookieConsentLegaleseContent"
        values={{
          ...bodyLinkValues,
          br: <br />,
          vendorCount: COOKIE_CONSENT_VENDOR_COUNT,
        }}
      />
    </p>
  );
};
