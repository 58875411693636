import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState: EpisodesState = {
  currentEpisodeId: '',
};

const episodesSlice = createSlice({
  name: 'episodes',
  initialState,
  reducers: {
    updateCurrentEpisodeId: (state, action) => {
      state.currentEpisodeId = action.payload;
    },
  },
});

export const getEpisodes = createAction<EpisodesActionPayload>(
  'episodes/getEpisodes'
);

export const getEpisodeDetails = createAction<{ episodeId: string }>(
  'episodes/getEpisodeDetails'
);

export const { updateCurrentEpisodeId } = episodesSlice.actions;

export const { reducer } = episodesSlice;
