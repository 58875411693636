import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reportPageViewAction } from 'store/actions/metrics';
import { PAGE_VIEW } from 'constants/metrics';
import { convertToSeconds } from 'utils/conversions';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/selectors/locale';
import Cookies from 'js-cookie';
import { PODCASTER_SESSION_ID } from '../constants/cookies';

export const usePageView = (page: string) => {
  const dispatch = useDispatch();
  const locale = useSelector(localeSelector);

  useEffect(() => {
    const pageViewEvent = {
      timestamp: convertToSeconds(Date.now()),
      actionName: PAGE_VIEW,
      additionalDetails: {
        url: window.location.href,
        pageType: page,
        podcasterSessionId: Cookies.get(PODCASTER_SESSION_ID),
        locale,
        baseEventVersion: '3',
        specificEventVersion: '3',
      },
    };

    dispatch(reportPageViewAction([pageViewEvent]));
  }, []);
};
