import {
  DEFAULT_EMAIL,
  ANCHOR,
  SOUNDCLOUD,
  SPREAKER,
  OTHER,
} from 'constants/defaultEmails';

type DEFAULT_EMAIL_TYPES = 'anchor' | 'soundcloud' | 'spreaker' | 'other';

const DEFAULT_MAP = {
  [ANCHOR]: 'DEFAULT_EMAIL_ANCHOR',
  [SOUNDCLOUD]: 'DEFAULT_EMAIL_SOUNDCLOUD',
  [SPREAKER]: 'DEFAULT_EMAIL_SPREAKER',
  [OTHER]: 'DEFAULT_EMAIL_OTHER',
};

export const invalidReasons = (
  errorCode: string,
  podcastProvider?: DEFAULT_EMAIL_TYPES
) => {
  if (errorCode === DEFAULT_EMAIL && podcastProvider) {
    return DEFAULT_MAP[podcastProvider] || DEFAULT_MAP[OTHER];
  }
  return errorCode;
};
