import { lazy } from 'react';
import en_US_translations from '@translations/translation-en-US.puff.json';
import countriesEN from '@translations/country.translation-en-US.puff.json';
import languagesEN from '@translations/language.translation-en-US.puff.json';
import cookiePreferenceEN from '@translations/messages-en-US.puff.json';

import {
  en_US,
  de_DE,
  en_GB,
  es_ES,
  es_MX,
  fr_FR,
  it_IT,
  ja_JP,
  pt_BR,
} from 'constants/locales';

export type Translation = {
  value: string;
  note: string;
};

type TranslationJSON = Promise<{ default: typeof en_US_translations }>;

export const messageLoader = {
  [en_US]: (): TranslationJSON =>
    new Promise((resolve) => {
      resolve({ default: en_US_translations });
    }),
  [de_DE]: (): TranslationJSON =>
    import('@translations/translation-de-DE.puff.json'),
  [en_GB]: (): TranslationJSON =>
    import('@translations/translation-en-GB.puff.json'),
  [es_ES]: (): TranslationJSON =>
    import('@translations/translation-es-ES.puff.json'),
  [es_MX]: (): TranslationJSON =>
    import('@translations/translation-es-MX.puff.json'),
  [fr_FR]: (): TranslationJSON =>
    import('@translations/translation-fr-FR.puff.json'),
  [it_IT]: (): TranslationJSON =>
    import('@translations/translation-it-IT.puff.json'),
  [ja_JP]: (): TranslationJSON =>
    import('@translations/translation-ja-JP.puff.json'),
  [pt_BR]: (): TranslationJSON =>
    import('@translations/translation-pt-BR.puff.json'),
};

export const TOSLoader = {
  [de_DE]: lazy(() => import('components/TermsOfService/components/TOS_de_DE')),
  [en_GB]: lazy(() => import('components/TermsOfService/components/TOS_en_GB')),
  [en_US]: lazy(() => import('components/TermsOfService/components/TOS_en_US')),
  [es_ES]: lazy(() => import('components/TermsOfService/components/TOS_es_ES')),
  [es_MX]: lazy(() => import('components/TermsOfService/components/TOS_es_MX')),
  [fr_FR]: lazy(() => import('components/TermsOfService/components/TOS_fr_FR')),
  [it_IT]: lazy(() => import('components/TermsOfService/components/TOS_it_IT')),
  [ja_JP]: lazy(() => import('components/TermsOfService/components/TOS_ja_JP')),
  [pt_BR]: lazy(() => import('components/TermsOfService/components/TOS_pt_BR')),
};

type CountriesJson = Promise<{ default: typeof countriesEN }>;

export const countriesLoader = {
  [de_DE]: (): CountriesJson =>
    import('@translations/country.translation-de-DE.puff.json'),
  [en_US]: (): CountriesJson =>
    import('@translations/country.translation-en-US.puff.json'),
  [en_GB]: (): CountriesJson =>
    import('@translations/country.translation-en-GB.puff.json'),
  [es_ES]: (): CountriesJson =>
    import('@translations/country.translation-es-ES.puff.json'),
  [es_MX]: (): CountriesJson =>
    import('@translations/country.translation-es-MX.puff.json'),
  [fr_FR]: (): CountriesJson =>
    import('@translations/country.translation-fr-FR.puff.json'),
  [it_IT]: (): CountriesJson =>
    import('@translations/country.translation-it-IT.puff.json'),
  [ja_JP]: (): CountriesJson =>
    import('@translations/country.translation-ja-JP.puff.json'),
  [pt_BR]: (): CountriesJson =>
    import('@translations/country.translation-pt-BR.puff.json'),
};

type LanguagesJson = Promise<{ default: typeof languagesEN }>;

export const languagesLoader = {
  [de_DE]: (): LanguagesJson =>
    import('@translations/language.translation-de-DE.puff.json'),
  [en_US]: (): LanguagesJson =>
    import('@translations/language.translation-en-US.puff.json'),
  [en_GB]: (): LanguagesJson =>
    import('@translations/language.translation-en-GB.puff.json'),
  [es_ES]: (): LanguagesJson =>
    import('@translations/language.translation-es-ES.puff.json'),
  [es_MX]: (): LanguagesJson =>
    import('@translations/language.translation-es-MX.puff.json'),
  [fr_FR]: (): LanguagesJson =>
    import('@translations/language.translation-fr-FR.puff.json'),
  [it_IT]: (): LanguagesJson =>
    import('@translations/language.translation-it-IT.puff.json'),
  [ja_JP]: (): LanguagesJson =>
    import('@translations/language.translation-ja-JP.puff.json'),
  [pt_BR]: (): LanguagesJson =>
    import('@translations/language.translation-pt-BR.puff.json'),
};

type CookiePreferenceJson = Promise<{ default: typeof cookiePreferenceEN }>;

export const cookiePreferenceLoader = {
  [de_DE]: (): CookiePreferenceJson =>
    import('@translations/messages-de-DE.puff.json'),
  [en_US]: (): CookiePreferenceJson =>
    import('@translations/messages-en-US.puff.json'),
  [en_GB]: (): CookiePreferenceJson =>
    import('@translations/messages-en-GB.puff.json'),
  [es_ES]: (): CookiePreferenceJson =>
    import('@translations/messages-es-ES.puff.json'),
  [es_MX]: (): CookiePreferenceJson =>
    import('@translations/messages-es-MX.puff.json'),
  [fr_FR]: (): CookiePreferenceJson =>
    import('@translations/messages-fr-FR.puff.json'),
  [it_IT]: (): CookiePreferenceJson =>
    import('@translations/messages-it-IT.puff.json'),
  [ja_JP]: (): CookiePreferenceJson =>
    import('@translations/messages-ja-JP.puff.json'),
  [pt_BR]: (): CookiePreferenceJson =>
    import('@translations/messages-pt-BR.puff.json'),
};

export type MessageLoader =
  | typeof messageLoader
  | typeof countriesLoader
  | typeof languagesLoader
  | typeof cookiePreferenceLoader;
export type DefaultMessages =
  | typeof en_US_translations
  | typeof countriesEN
  | typeof languagesEN
  | typeof cookiePreferenceEN;
