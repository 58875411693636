import { MusicImage } from '@amzn/DMWebUIComponents/dist/react/components';
import './styles.scss';

export const MusicHorizontalItem = ({
  size = 'medium',
  imageUrl,
  primaryText,
  secondaryText,
  label,
  isInactive = false,
}: MusicHorizontalItemProps) => {
  const labelClass = isInactive ? 'index' : 'label';
  return (
    <div className="MusicHorizontalItem__container">
      <MusicImage
        kind="square"
        src={imageUrl}
        className={`MusicHorizontalItem__image ${size}`}
      />
      <div className="MusicHorizontalItem__text">
        {label && (
          <p className={`MusicHorizontalItem__label ${labelClass}`}>{label}</p>
        )}
        <p className="MusicHorizontalItem__primary">{primaryText}</p>
        {secondaryText && <p className="secondary-text">{secondaryText}</p>}
      </div>
    </div>
  );
};
