import { MusicIcon } from '@amzn/DMWebUIComponents/dist/react/components';
import './styles.scss';

const Loader = () => (
  <div className="Loader">
    <MusicIcon name="loader" size="large" />
  </div>
);

export default Loader;
