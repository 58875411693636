import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MusicButton } from 'components/MusicButton';
import BaseModal from 'components/Modal/BaseModal';
import { SELECT_CLOSE_MODAL } from 'constants/metrics';
import { PageContext } from 'contexts';
import { closeModal } from 'store/slices/modal';
import './styles.scss';

const ScrollableBodyModal = ({
  body,
  title,
  actions,
  containerClassName = '',
  maxBodyHeight = 'none',
  cancelButton = true,
}: ScrollableBodyModalProps) => {
  const dispatch = useDispatch();
  const page = useContext(PageContext);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [showFade, setShowFade] = useState<boolean>();

  useEffect(() => {
    const handleAreaScrollStyles = () => {
      if (!scrollableRef.current) {
        return;
      }
      const scrollableCurrent = scrollableRef.current;
      const newShowFade =
        scrollableCurrent.scrollHeight > scrollableCurrent.clientHeight;
      if (newShowFade !== showFade) {
        setShowFade(newShowFade);
      }
    };
    handleAreaScrollStyles();
    window.addEventListener('resize', handleAreaScrollStyles);
    return () => {
      window.removeEventListener('resize', handleAreaScrollStyles);
    };
  }, []);

  const dismissModal = () => {
    dispatch(closeModal());
  };

  return (
    <BaseModal allowEscape={cancelButton}>
      <div className={`ScrollableBodyModal__container ${containerClassName}`}>
        <div className="ScrollableBodyModal__body">
          {cancelButton && (
            <MusicButton
              class="ScrollableBodyModal__close-button"
              icon-only
              icon-name="cancel"
              variant="glass"
              size="small"
              data-id={`${SELECT_CLOSE_MODAL}-${page}`}
              onMusicActivate={dismissModal}
            />
          )}
          <div
            className="ScrollableBodyModal__scrollArea"
            ref={scrollableRef}
            style={{ maxHeight: maxBodyHeight }}
          >
            {showFade && <div className="ScrollableBodyModal__TopFade" />}
            {title && <div className="ScrollableBodyModal__title">{title}</div>}
            {body}
            {showFade && <div className="ScrollableBodyModal__BottomFade" />}
          </div>
          {actions && (
            <div className="ScrollableBodyModal__actions">{actions}</div>
          )}
        </div>
      </div>
    </BaseModal>
  );
};

export default ScrollableBodyModal;
