import { useDispatch } from 'react-redux';
import { MusicButton } from '@amzn/DMWebUIComponents/dist/react/components';
import { closeModal } from 'store/slices/modal';
import TermsOfService from 'components/TermsOfService';
import './styles.scss';
import { SELECT_CLOSE_MODAL, TOS_MODAL } from 'constants/metrics';

export const TOS = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <div className="TOS__container">
      <MusicButton
        class="TOS__close-button"
        icon-only
        icon-name="cancel"
        variant="glass"
        size="small"
        onMusicActivate={onClose}
        data-id={`${SELECT_CLOSE_MODAL}-${TOS_MODAL}`}
      />
      <TermsOfService modal />
    </div>
  );
};
