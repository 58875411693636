import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import ModalArtworkTile from 'components/ArtworkTile/components/ModalArtworkTile';
import BlurredModal from 'components/Modal/BlurredModal';
import Loader from 'components/Modal/Loader';
import ContactUs from 'components/Links/ContactUs';
import { PODCAST_SUSPENDED_MODAL } from 'constants/metrics';
import { PageContext } from 'contexts';
import useGetPodcastDetails from 'hooks/getPodcastDetails';
import { usePageView } from 'hooks/pageView';
import { modalPodcastIdSelector } from 'store/selectors/modal';
import './styles.scss';

const Title = (
  <h4>
    <FormattedMessage
      id="suspendedModal-title"
      defaultMessage="Your Show is Suspended"
    />
  </h4>
);

const SuspendedModal = () => {
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  const { podcastDetailsLoading } = useGetPodcastDetails(modalPodcastId);
  usePageView(PODCAST_SUSPENDED_MODAL);

  return (
    <PageContext.Provider value={PODCAST_SUSPENDED_MODAL}>
      {podcastDetailsLoading ? (
        <Loader />
      ) : (
        <BlurredModal title={Title}>
          <div className="SuspendedModal__content-container">
            <p className="bodyPrimary secondary-text">
              <FormattedMessage
                id="suspendedModal-description"
                values={{
                  contactUs: <ContactUs />,
                }}
                defaultMessage="Your podcast has been suspended for violating our terms of service. To learn more, {contactUs}."
              />
            </p>
            <div className="SuspendedModal__artwork">
              <ModalArtworkTile />
            </div>
          </div>
        </BlurredModal>
      )}
    </PageContext.Provider>
  );
};

export default SuspendedModal;
