import { PayloadAction } from '@reduxjs/toolkit';
import { postWithCredentials } from 'api';
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import {
  ANALYTIC_OPTIONS_TO_API_FIELDS_MAP,
  DELTA,
  TOTAL,
} from 'constants/analytics';
import { CHRONOLOGICAL_NEWEST_FIRST, EPISODE_ID } from 'constants/api';
import { DEFAULT_PAGE_SIZE } from 'constants/podcasts';
import { EPISODE_ANALYTICS_API } from 'constants/routes';
import { episodeListCursorSelector } from 'store/selectors/episodes';
import {
  getEpisodesAggregate,
  getEpisodesAggregateFailure,
  updateEpisodeAnalytics,
  updateTimeFrame,
} from 'store/slices/analytics';
import { parseISOWithoutHour } from 'utils/date';
import { normalizeAggregateAnalytics } from 'utils/normalizer/analytics';
import { updateAggregateLoading } from 'store/slices/loading';

export function* fetchEpisodesAggregate({
  payload: { analyticTypes, startTime, endTime, podcastId },
}: PayloadAction<EpisodeAnalyticsAggregateActionPayload>): any {
  const episodeListCursor = yield select(episodeListCursorSelector(podcastId));
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);

  try {
    const response = yield call(postWithCredentials, {
      path: EPISODE_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        pagination: {
          pageToken: episodeListCursor,
          pageSize: DEFAULT_PAGE_SIZE,
          podcastId,
          sortByProperty: CHRONOLOGICAL_NEWEST_FIRST,
        },
        ...analyticTypes.reduce(
          (accumulator, analyticType) => ({
            ...accumulator,
            [ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].aggregate]: {
              groupByProperties: [EPISODE_ID],
              calculationsIncluded: [TOTAL, DELTA],
            },
          }),
          {}
        ),
      },
    });
    const { data } = yield call([response, 'json']);
    return normalizeAggregateAnalytics(analyticTypes, data);
  } catch (e) {
    yield put(getEpisodesAggregateFailure());
  }
}

export function* requestEpisodesAggregate(
  action: PayloadAction<EpisodeAnalyticsAggregateActionPayload>
): any {
  if (action.payload.podcastId) {
    yield put(updateAggregateLoading(true));

    const [episodeAnalytics] = yield race([
      call(fetchEpisodesAggregate, action),
      take(updateTimeFrame.toString()),
    ]);

    if (episodeAnalytics) {
      yield put(
        updateEpisodeAnalytics({
          episodes: episodeAnalytics,
        })
      );
    }
    yield put(updateAggregateLoading(false));
  }
}

export const episodeAggregateSagas = [
  takeEvery(getEpisodesAggregate.toString(), requestEpisodesAggregate),
];
