import { LOCALE_TYPES, LOCALE_TO_TLD_MAP } from 'constants/locales';
import {
  authPortalBaseParams,
  AUTH_PORTAL_ROOT,
  AUTH_ENV,
  PROD_URL,
} from 'constants/links';
import { CSRF_API } from 'constants/routes';
import { COOKIE_PREFERENCES_LINK } from 'constants/links';

export const validUrl = (url: string | undefined | null) => {
  if (!url) return false;
  return /.+\..+/.test(url);
};

export const getQueryStringParams = (requestedParams: Array<string>) => {
  const urlParams = new URLSearchParams(window.location.search);

  return requestedParams.map(
    (currentParam) => urlParams.get(currentParam) || ''
  );
};

export const productionStage = () =>
  window.location.hostname.includes(PROD_URL);

export const upgradeToHttps = (url: string | null | undefined) => {
  if (!url) return '';
  if (!url.startsWith('http')) return `https://${url}`;
  return url.replace('http://', 'https://');
};

const formattedTitleForUrl = (title: string, locale: string) => {
  const lowercased = title.toLocaleLowerCase(locale);
  const cleanedString = lowercased
    .trim()
    .replace(/([^\p{L}\p{N}\p{Zs}])+/gu, '');
  return cleanedString.replace(/(\p{Zs})+/gu, '-');
};

export const getLocalizedPublicUrl = (
  podcastId: string,
  locale: LOCALE_TYPES,
  title: string = ''
) => {
  const formattedTitle = formattedTitleForUrl(title, locale);

  return `https://music.amazon${LOCALE_TO_TLD_MAP[locale]}/podcasts/${podcastId}/${formattedTitle}`;
};

export const getLocalizedPublicEpisodeUrl = (
  podcastId: string,
  locale: LOCALE_TYPES,
  episodeId: string,
  episodeTitle: string = ''
) => {
  const formattedEpisodeTitle = formattedTitleForUrl(episodeTitle, locale);

  return `https://music.amazon${LOCALE_TO_TLD_MAP[locale]}/podcasts/${podcastId}/episodes/${episodeId}/${formattedEpisodeTitle}`;
};

export const getFacebookShareLink = (url: string) => {
  const qs = new URLSearchParams({ u: url });

  return `https://www.facebook.com/sharer/sharer.php?${qs.toString()}`;
};

export const getTwitterShareLink = (
  url: string,
  text = '',
  hashtags = ['PodcastsOnAmazonMusic']
) => {
  const qs = new URLSearchParams({
    hashtags: hashtags.join(','),
    text,
    url,
  });

  return `https://twitter.com/intent/tweet?${qs.toString()}`;
};

export const queryStringWithoutCSRF = () => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('csrf-token');
  const queryString = urlParams.toString();

  return queryString && `?${queryString}`;
};

export const reauthenticateUrl = (language: string) => {
  const [currentUrl] = window.location.href.split('?');

  return (
    `${AUTH_PORTAL_ROOT}/signin` +
    authPortalBaseParams(language) +
    '&openid.mode=checkid_setup' +
    '&openid.pape.max_auth_age=0' +
    '&openid.return_to=https://' +
    `${AUTH_ENV}${CSRF_API}?path=` +
    `${encodeURIComponent(`${currentUrl}${queryStringWithoutCSRF()}`)}`
  );
};

export const getCookiePrefUrl = (locale: string) =>
  `${COOKIE_PREFERENCES_LINK}?disableNav=true&locale=${locale.replace(
    '-',
    '_'
  )}`;
