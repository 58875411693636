import { useSelector } from 'react-redux';
import {
  currentPodcastEndTimeFrameSelector,
  currentPodcastStartTimeFrameSelector,
} from 'store/selectors/analytics';
import { modalPodcastIdSelector } from 'store/selectors/modal';
import { updateTimeFrame } from 'store/slices/analytics';
import DateRangeModal from '..';

const PodcastDateRangeModal = () => {
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  const startTime = useSelector(currentPodcastStartTimeFrameSelector);
  const endTime = useSelector(currentPodcastEndTimeFrameSelector);

  const confirmDateRangePayloadId = {
    podcastId: modalPodcastId,
  };

  return (
    <DateRangeModal
      startTime={startTime}
      endTime={endTime}
      updateTimeFrameReducer={updateTimeFrame}
      confirmDateRangePayloadId={confirmDateRangePayloadId}
    />
  );
};

export default PodcastDateRangeModal;
