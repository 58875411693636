import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'types/apiStatuses';

const initialState: ApiStatus = {};

const apiSlice = createSlice({
  name: 'apiStatus',
  initialState,
  reducers: {
    addApiStatus: (_state, action) => action.payload,
    clearApiStatus: () => ({}),
  },
});

export const { addApiStatus, clearApiStatus } = apiSlice.actions;
export const { reducer } = apiSlice;
