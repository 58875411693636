import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import ModalArtworkTile from 'components/ArtworkTile/components/ModalArtworkTile';
import BlurredModal from 'components/Modal/BlurredModal';
import Loader from 'components/Modal/Loader';
import { PODCAST_HIDDEN_MODAL } from 'constants/metrics';
import { PageContext } from 'contexts';
import useGetPodcastDetails from 'hooks/getPodcastDetails';
import { usePageView } from 'hooks/pageView';
import { modalPodcastIdSelector } from 'store/selectors/modal';
import './styles.scss';

const Title = (
  <h4>
    <FormattedMessage
      id="podcastHidden-title"
      defaultMessage="Your Show is Hidden"
    />
  </h4>
);

const PodcastHidden = () => {
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  const { podcastDetailsLoading } = useGetPodcastDetails(modalPodcastId);
  usePageView(PODCAST_HIDDEN_MODAL);

  return (
    <PageContext.Provider value={PODCAST_HIDDEN_MODAL}>
      {podcastDetailsLoading ? (
        <Loader />
      ) : (
        <BlurredModal title={Title}>
          <div className="PodcastHiddenModal__content-container">
            <p className="bodyPrimary secondary-text">
              <FormattedMessage
                id="podcastHidden-description"
                defaultMessage="Hiding your show makes it temporarily unavailable to listeners on Amazon Music and Audible. "
              />
            </p>
            <div className="PodcastHiddenModal__artwork">
              <ModalArtworkTile />
            </div>
          </div>
        </BlurredModal>
      )}
    </PageContext.Provider>
  );
};

export default PodcastHidden;
