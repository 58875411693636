import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { podcastsEntitiesSelector } from './entities';

const EMPTY_OBJECT = {};
const EMPTY_STRING_ARRAY = Array<string>();

export const currentPodcastIdSelector = (state: RootState) =>
  state.podcasts?.currentPodcastId;

export const podcastIdListSelector = (state: RootState) =>
  state.podcasts.podcastIds;

export const podcastListCursorSelector = (state: RootState) =>
  state.podcasts.cursor;

export const podcastListHasNextPageSelector = (state: RootState) =>
  state.podcasts.hasNextPage;

const currentPodcastSelector = createSelector(
  [currentPodcastIdSelector, podcastsEntitiesSelector],
  (currentPodcastId, podcastsEntities) =>
    podcastsEntities[currentPodcastId] || EMPTY_OBJECT
);

export const currentPodcastIsExplicitSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.isExplicit ?? true
);

export const currentPodcastHasTrailerSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.hasTrailer || false
);

export const currentPodcastTrailerEpisodeSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.trailerEpisode
);

export const currentPodcastNumberOfSeasonsSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.numberOfSeasons
);

export const currentPodcastParentCategoriesSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.parentCategories || EMPTY_STRING_ARRAY
);

export const currentPodcastSubcategoriesSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.subcategories || EMPTY_STRING_ARRAY
);

export const currentPodcastImageUrlSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.imageUrl
);

export const currentPodcastDescriptionSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.description
);

export const currentPodcastTitleSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.title
);

export const currentPodcastStatusSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.status
);

export const currentPodcastLanguageSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.language
);

export const currentPodcastLastUpdatedTimeSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.lastUpdatedTimeUTC
);

export const currentPodcastFeedUrlSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.feedUrl
);

export const currentPodcastHostingProviderSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.hostingProvider || ''
);

export const currentPodcastEpisodeListSelector = createSelector(
  [currentPodcastSelector],
  (currentPodcast) => currentPodcast.episodeList || EMPTY_STRING_ARRAY
);

export const podcastSelector = (podcastId: string) => (state: RootState) =>
  state.entities.podcasts[podcastId];

export const podcastImageUrlSelector = (podcastId: string) => (
  state: RootState
) => state.entities.podcasts[podcastId]?.imageUrl;

export const podcastTitleSelector = (podcastId: string) => (state: RootState) =>
  state.entities.podcasts[podcastId]?.title;

export const podcastStatusSelector = (podcastId: string) => (
  state: RootState
) => state.entities.podcasts[podcastId]?.status;

export const podcastLanguageSelector = (podcastId: string) => (
  state: RootState
) => state.entities.podcasts[podcastId]?.language;
