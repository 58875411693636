import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { episodesEntitiesSelector } from './entities';

const EMPTY_OBJECT = {};

export const currentEpisodeIdSelector = (state: RootState) =>
  state.episodes?.currentEpisodeId;

export const currentEpisodeSelector = createSelector(
  [currentEpisodeIdSelector, episodesEntitiesSelector],
  (currentEpisodeId, episodesEntities) =>
    episodesEntities[currentEpisodeId] || EMPTY_OBJECT
);

export const currentEpisodeImageUrlSelector = createSelector(
  [currentEpisodeSelector],
  (currentEpisode) => currentEpisode?.image?.sourceUrl
);

export const currentEpisodeTitleSelector = createSelector(
  [currentEpisodeSelector],
  (currentEpisode) => currentEpisode?.title
);

export const currentEpisodePublishedTimeSelector = createSelector(
  [currentEpisodeSelector],
  (currentEpisode) => currentEpisode?.publishedTimeUTC
);

export const episodeListCursorSelector = (podcastId: string) => (
  state: RootState
) => state.entities.podcasts[podcastId]?.episodeListCursor;

export const episodesListHasNextPageSelector = (podcastId: string) => (
  state: RootState
) => state.entities.podcasts[podcastId]?.episodeListHasNextPage;

export const episodeImageUrlSelector = (episodeId: string) => (
  state: RootState
) => state.entities.episodes[episodeId]?.image.sourceUrl;

export const episodeTitleSelector = (episodeId: string) => (state: RootState) =>
  state.entities.episodes[episodeId]?.title;

export const episodePublishedTimeSelector = (episodeId: string) => (
  state: RootState
) => state.entities.episodes[episodeId]?.publishedTimeUTC;
