import { Suspense, lazy, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { checkForFeature } from 'utils/features';
import { EPISODE_DETAILS_FEATURE } from 'constants/features';
import {
  CONFIRM_ROUTE,
  EMAIL_SENT_ROUTE,
  SUBMIT_RSS_ROUTE,
  VERIFY_ROUTE,
  TOS_ROUTE,
  FAQ_ROUTE,
  ERROR_PAGE_ROUTE,
  PROMOTIONAL_TOOLS_ROUTE,
  PODCAST_DETAILS_ROUTE,
  EPISODE_DETAILS_ROUTE,
  PODCASTS_ROUTE,
  EPISODE_DETAILS_ROUTE_MISSING_PODCAST_ID,
} from 'constants/routes';
import HomeRoute from 'components/Router/Home';
import Loading from 'components/Modal/LoaderWithHeader';
import { ListenToHistory } from 'components/ListenToHistory';
import { useSelector } from 'react-redux';
import { inBetaSelector } from 'store/selectors/user';

const AddYourPodcast = lazy(() => import('components/AddYourPodcast'));
const ClaimConfirm = lazy(() => import('components/ClaimConfirm'));
const EmailSent = lazy(() => import('components/EmailSent'));
const ErrorPage = lazy(() => import('components/404'));
const FAQ = lazy(() => import('components/FAQ'));
const PodcastDetails = lazy(
  () => import('components/Details/AnalyticsPodcastDetails')
);
const OldPodcastDetails = lazy(() => import('components/PodcastDetails'));
const EpisodeDetails = lazy(() => import('components/Details/EpisodeDetails'));
const PromotionalTools = lazy(() => import('components/PromotionalTools'));
const ProtectedRoute = lazy(() => import('components/ProtectedRoute'));
const TermsOfService = lazy(() => import('components/TermsOfService'));
const Verify = lazy(() => import('components/Verify'));
const OldOverview = lazy(() => import('components/Overview'));
const Overview = lazy(() => import('components/AnalyticsOverview'));

export const Router = () => {
  const audienceInsightsFeature = useSelector(inBetaSelector);

  return (
    <Fragment>
      <ListenToHistory />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path={SUBMIT_RSS_ROUTE} component={AddYourPodcast} />
          <Route path={VERIFY_ROUTE} component={Verify} />
          <Route path={EMAIL_SENT_ROUTE} component={EmailSent} />
          <Route path={CONFIRM_ROUTE} component={ClaimConfirm} />
          <Route path={TOS_ROUTE} component={TermsOfService} />
          <Route path={FAQ_ROUTE} component={FAQ} />
          <Route path={ERROR_PAGE_ROUTE} component={ErrorPage} />
          {checkForFeature(EPISODE_DETAILS_FEATURE) && (
            <ProtectedRoute
              path={EPISODE_DETAILS_ROUTE}
              component={EpisodeDetails}
            />
          )}
          {checkForFeature(EPISODE_DETAILS_FEATURE) && (
            <ProtectedRoute
              path={EPISODE_DETAILS_ROUTE_MISSING_PODCAST_ID}
              component={EpisodeDetails}
            />
          )}

          <ProtectedRoute
            path={PODCAST_DETAILS_ROUTE}
            component={
              audienceInsightsFeature ? PodcastDetails : OldPodcastDetails
            }
          />
          <Route
            path={PODCASTS_ROUTE}
            render={() =>
              audienceInsightsFeature ? <Overview /> : <OldOverview />
            }
          />
          <Route path={PROMOTIONAL_TOOLS_ROUTE} component={PromotionalTools} />
          <HomeRoute />
        </Switch>
      </Suspense>
    </Fragment>
  );
};
