import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { MusicLink } from 'components/MusicLink';
import { registerUrl } from 'constants/links';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/selectors/locale';
import { GO_CREATE_NEW_ACCOUNT } from 'constants/metrics';
import { PageContext } from 'contexts';

const CreateNewAccount = () => {
  const page = useContext(PageContext);
  const locale = useSelector(localeSelector);
  return (
    <MusicLink
      kind="accent"
      href={registerUrl(locale)}
      data-id={`${GO_CREATE_NEW_ACCOUNT}-${page}`}
    >
      <FormattedMessage id="createNewAccount" />
    </MusicLink>
  );
};

export default CreateNewAccount;
