import { RootState } from 'store';

export const feedSelector = (state: RootState) => state.feed || '';
export const feedTitleSelector = (state: RootState) => state.feed.title || '';
export const feedEmailSelector = (state: RootState) =>
  state.feed.ownerEmail || '';
export const feedImageSourceSelector = (state: RootState) =>
  state.feed.imageUrl || '';
export const feedIdSelector = (state: RootState) => state.feed.id || '';
export const feedUrlSelector = (state: RootState) => state.feed.url || '';
export const feedcountryOfOriginSelector = (state: RootState) =>
  state.feed.countryOfOrigin || '';
export const feedStatusSelector = (state: RootState) => state.feed.status || '';
export const feedInvalidReasonSelector = (state: RootState) =>
  state.feed.invalidReason || '';
