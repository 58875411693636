export const EMAIL_SENT_ROUTE = '/confirmation-email-sent';
export const CONFIRM_ROUTE = '/confirm';
export const SUBMIT_RSS_ROUTE = '/submit-rss';
export const TOS_ROUTE = '/terms-of-service';
export const FAQ_ROUTE = '/frequently-asked-questions';
export const PROMOTIONAL_TOOLS_ROUTE = '/promotional-tools';
export const VERIFY_ROUTE = '/verify';
export const ERROR_PAGE_ROUTE = '/not-found';
export const HOME_ROUTE = '/';
export const PODCASTS_ROUTE = '/podcasts';
export const EPISODES_ROUTE = '/episodes';
export const PODCAST_DETAILS_ROUTE = '/podcasts/:id';
export const EPISODE_DETAILS_ROUTE = '/podcasts/:podcastId/episodes/:episodeId';
export const EPISODE_DETAILS_ROUTE_MISSING_PODCAST_ID =
  '/podcasts//episodes/:episodeId';

/* API routes */
export const CSRF_API = '/auth/csrf';
export const USER_AUTHENTICATED_API = '/api/validated';
export const CLAIMS_CONFIRM_API = '/api/claims/confirm';
export const CLAIMS_SUBMIT_API = '/api/claims/submit';
export const CLAIMS_VALIDATE_API = '/api/claims/validate';
export const FEED_URL_UPDATE_API = '/api/feed';
export const EMAIL_OWNER_API = '/api/emails/owner';
export const USER_INFO_API = '/api/user';
export const EPISODE_LIST_API = '/api/episodes/list';
export const PODCAST_LIST_API = '/api/podcasts/list';
export const PODCAST_DETAILS_API = '/api/podcasts/get';
export const EPISODE_DETAILS_API = '/api/episode/get';
export const PODCAST_VISIBILITY_API = (podcastId: string) =>
  `/api/podcast/${podcastId}/visibility`;
export const PODCAST_UNCLAIM_API = (podcastId: string) =>
  `/api/podcast/${podcastId}/unclaim`;
export const PODCAST_ANALYTICS_API = '/api/metrics/podcast';
export const EPISODE_ANALYTICS_API = '/api/metrics/episode';
export const EXCHANGE_TOKEN_API = '/api/exchange';
export const SESSION_INFO_API = '/unauth/session/get';
export const GET_USER_PREFERENCE_AUTH_API = '/api/user/cookie/get';
export const GET_USER_PREFERENCE_UNAUTH_API = '/unauth/cookie/get';
export const SUBMIT_USER_PREFERENCE_AUTH_API = '/api/user/cookie/submit';
export const SUBMIT_USER_PREFERENCE_UNAUTH_API = '/unauth/cookie/submit';
