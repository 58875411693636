import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { modalNameSelector } from 'store/selectors/modal';
import { closeModal } from 'store/slices/modal';

export const ListenToHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const modal = useSelector(modalNameSelector);

  useEffect(() => {
    modal && dispatch(closeModal());
  }, [location.pathname]);

  return null;
};
