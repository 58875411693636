export const CLAIM_PODCAST = 'CLAIM_PODCAST_MODAL';
export const CONNECT_YOUR_AMAZON_ACCOUNT = 'CONNECT_YOUR_AMAZON_ACCOUNT_MODAL';
export const CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME =
  'CONNECT_YOUR_AMAZON_ACCOUNT_RETURN_HOME_MODAL';
export const CONTINUE_WITH_YOUR_AMAZON_ACCOUNT =
  'CONTINUE_WITH_YOUR_AMAZON_ACCOUNT_MODAL';
export const PODCAST_DATE_RANGE = 'PODCAST_DATE_RANGE_MODAL';
export const EPISODE_DATE_RANGE = 'EPISODE_DATE_RANGE_MODAL';
export const TERMS_OF_SERVICE = 'TERMS_OF_SERVICE_MODAL';
export const HIDDEN_PODCAST = 'HIDDEN_PODCAST_MODAL';
export const ACTIVE_PODCAST = 'ACTIVE_PODCAST_MODAL';
export const PENDING_PODCAST = 'PENDING_PODCAST_MODAL';
export const UNHIDE_PODCAST = 'UNHIDE_PODCAST_MODAL';
export const UNCONFIRMED = 'UNCONFIRMED_MODAL';
export const HIDE = 'HIDE_MODAL';
export const SUSPENDED = 'SUSPENDED_MODAL';
export const SHARE_PODCAST = 'SHARE_PODCAST_MODAL';
export const SHARE_EPISODE = 'SHARE_EPISODE_MODAL';
export const METADATA = 'METADATA_MODAL';
export const UPDATE_RSS = 'UPDATE_RSS_MODAL';
export const OWNERSHIP_RELEASED = 'OWNERSHIP_RELEASED_MODAL';
export const RELEASE_OWNERSHIP = 'RELEASE_OWNERSHIP_MODAL';
export const TIMEFRAME = 'TIMEFRAME_MODAL';
export const COOKIE_CONSENT = 'COOKIE_CONSENT_MODAL';
export const MAX_RSS_URL_LENGTH = 50;
export const COOKIE_CONSENT_VENDOR_COUNT = 103;
