import { RootState } from 'store';
import { checkForFeature } from 'utils/features';
import { AUDIENCE_INSIGHTS_FEATURE } from 'constants/features';
import { EU_VIEWER_COUNTRIES } from 'constants/user';
import { createSelector } from '@reduxjs/toolkit';

export const authenticatedSelector = (state: RootState) =>
  state.user.authenticated;
export const emailSelector = (state: RootState) =>
  state.user.primaryContact?.emailAddress;
export const fullNameSelector = (state: RootState) =>
  state.user.primaryContact?.fullName;
export const hasFeedClaimsSelector = (state: RootState) =>
  state.user.hasFeedClaims;
export const inBetaSelector = (state: RootState) =>
  state.user.inBeta || checkForFeature(AUDIENCE_INSIGHTS_FEATURE);
export const authSelector = (state: RootState) => state.user.auth;
export const authTokenSelector = (state: RootState) => state.user.auth?.token;
export const authExpirationTimeSelector = (state: RootState) =>
  state.user.auth?.expirationTime || 0;
export const cookiePreferenceSelector = (state: RootState) =>
  state.user.cookiePreference?.cookieConsent;
export const viewerCountrySelector = (state: RootState) =>
  state.user.viewerCountry || '';
export const inEuCountrySelector = createSelector(
  viewerCountrySelector,
  (viewerCountry) => EU_VIEWER_COUNTRIES.includes(viewerCountry)
);
