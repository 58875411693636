import { ANALYTIC_OPTIONS_TO_API_FIELDS_MAP } from 'constants/analytics';

export const normalizeAnalytics = ({
  startsTotals,
  playsTotals,
  listenersTotals,
  engagedListenersTotals,
  followsTotals,
  startsTimeSeries,
  playsTimeSeries,
  listenersTimeSeries,
  engagedListenersTimeSeries,
  followsTimeSeries,
}: any) => ({
  ...(!!startsTotals && {
    starts: startsTotals.total,
  }),
  ...(!!playsTotals && {
    plays: playsTotals.total,
  }),
  ...(!!listenersTotals && {
    listeners: listenersTotals.total,
  }),
  ...(!!engagedListenersTotals && {
    engagedListeners: engagedListenersTotals.total,
  }),
  ...(!!followsTotals && {
    followers: followsTotals.total,
  }),
  ...(!!startsTotals && {
    startsDelta: startsTotals.delta,
  }),
  ...(!!playsTotals && {
    playsDelta: playsTotals.delta,
  }),
  ...(!!listenersTotals && {
    listenersDelta: listenersTotals.delta,
  }),
  ...(!!engagedListenersTotals && {
    engagedListenersDelta: engagedListenersTotals.delta,
  }),
  ...(!!followsTotals && {
    followersDelta: followsTotals.delta,
  }),
  ...(!!startsTimeSeries && {
    startsTimeSeries: mapTimeSeriesToChartData(startsTimeSeries),
  }),
  ...(!!playsTimeSeries && {
    playsTimeSeries: mapTimeSeriesToChartData(playsTimeSeries),
  }),
  ...(!!followsTimeSeries && {
    followersTimeSeries: mapTimeSeriesToChartData(followsTimeSeries),
  }),
  ...(!!listenersTimeSeries && {
    listenersTimeSeries: mapTimeSeriesToChartData(listenersTimeSeries),
  }),
  ...(!!engagedListenersTimeSeries && {
    engagedListenersTimeSeries: mapTimeSeriesToChartData(
      engagedListenersTimeSeries
    ),
  }),
});

export const normalizeAggregateAnalytics = (
  analyticTypes: AnalyticTypeOption[],
  data: any
) =>
  analyticTypes.reduce(
    (acc: Record<string, {}>, analyticType: AnalyticTypeOption) => {
      const aggregateDataList =
        data[ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].aggregate];

      return aggregateDataList
        ? aggregateDataList.reduce(
            (innerAcc: Record<string, {}>, aggregateData: { id: string }) => ({
              ...innerAcc,
              [aggregateData.id]: {
                ...acc[aggregateData.id],
                ...normalizeAggregateData(aggregateData, analyticType),
              },
            }),
            acc
          )
        : acc;
    },
    {}
  );

export const normalizeAggregateData = (
  aggregateData: any,
  analyticType: string
) => ({
  [analyticType]: aggregateData?.total,
  [`${analyticType}Delta`]: aggregateData?.delta,
});

export const normalizeTimeSeriesAnalytics: any = (
  timeSeriesData: any,
  analyticTypes: AnalyticTypeOption[]
) =>
  analyticTypes.reduce(
    (
      accumulator: Record<string, unknown>,
      analyticType: AnalyticTypeOption
    ) => ({
      ...accumulator,
      [`${analyticType}TimeSeries`]: mapTimeSeriesToChartData(
        timeSeriesData[
          ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].timeSeries
        ]
      ),
    }),
    {}
  );

type TimeSeriesData = {
  time: number | string;
  value: number;
};

const mapTimeSeriesToChartData = (timeSeries: TimeSeriesData[]) =>
  timeSeries?.map((timeSeriesData: TimeSeriesData) => ({
    x: timeSeriesData.time,
    y: timeSeriesData.value,
  }));
