import { useContext } from 'react';
import { FormattedMessage, FormattedList } from 'react-intl';
import { useSelector } from 'react-redux';
import { MusicLink } from '@amzn/DMWebUIComponents/dist/react/components';
import BlurredModal from 'components/Modal/BlurredModal';
import { MusicDivider } from 'components/MusicDivider';
import { MusicHorizontalItem } from 'components/MusicHorizontalItem';
import { localeSelector } from 'store/selectors/locale';
import {
  currentPodcastFeedUrlSelector,
  currentPodcastLastUpdatedTimeSelector,
  currentPodcastHostingProviderSelector,
  currentPodcastIdSelector,
  currentPodcastImageUrlSelector,
  currentPodcastIsExplicitSelector,
  currentPodcastLanguageSelector,
  currentPodcastNumberOfSeasonsSelector,
  currentPodcastParentCategoriesSelector,
  currentPodcastStatusSelector,
  currentPodcastSubcategoriesSelector,
  currentPodcastTitleSelector,
  currentPodcastTrailerEpisodeSelector,
} from 'store/selectors/podcasts';
import { usePageView } from 'hooks/pageView';
import { LanguageContext, PageContext } from 'contexts';
import { getLocalizedPublicUrl, getLocalizedPublicEpisodeUrl } from 'utils/url';
import {
  GO_PODCAST_PUBLIC_URL,
  GO_PODCAST_TRAILER_PUBLIC_URL,
  METADATA_MODAL,
} from 'constants/metrics';
import { PODCAST_ACTIVE } from 'constants/podcasts';
import { LanguagesKeys } from 'types/translations';
import { formatUTC } from 'utils/date';
import './styles.scss';

const MetadataModal = () => {
  usePageView(METADATA_MODAL);
  const localizedLanguages = useContext(LanguageContext);
  const locale = useSelector(localeSelector);

  const podcastId = useSelector(currentPodcastIdSelector);
  const title = useSelector(currentPodcastTitleSelector);
  const imageUrl = useSelector(currentPodcastImageUrlSelector);
  const status = useSelector(currentPodcastStatusSelector);
  const hostingProvider = useSelector(currentPodcastHostingProviderSelector);
  const Label = (
    <span>
      {status === PODCAST_ACTIVE ? (
        <FormattedMessage defaultMessage="ACTIVE" id="status-active" />
      ) : (
        <FormattedMessage defaultMessage="HIDDEN" id="status-hidden" />
      )}
    </span>
  );

  const feedUrl = useSelector(currentPodcastFeedUrlSelector);
  const trailerEpisode = useSelector(currentPodcastTrailerEpisodeSelector);

  const parentCategories = useSelector(currentPodcastParentCategoriesSelector);
  const parentCategoriesValue =
    parentCategories.length !== 0 ? (
      <FormattedList type="unit" value={parentCategories} />
    ) : (
      <FormattedMessage id="n-a" defaultMessage="N/A" />
    );

  const subcategories = useSelector(currentPodcastSubcategoriesSelector);
  const subcategoriesValue =
    subcategories.length !== 0 ? (
      <FormattedList type="unit" value={subcategories} />
    ) : (
      <FormattedMessage id="n-a" defaultMessage="N/A" />
    );

  const isExplicit = useSelector(currentPodcastIsExplicitSelector);
  const isExplicitValue = isExplicit ? (
    <FormattedMessage id="yes" defaultMessage="Yes" />
  ) : (
    <FormattedMessage id="no" defaultMessage="No" />
  );

  const formattedTimestamp = formatUTC(
    useSelector(currentPodcastLastUpdatedTimeSelector) || '',
    locale
  ) || <FormattedMessage id="n-a" defaultMessage="N/A" />;

  const language = useSelector(currentPodcastLanguageSelector);
  const localizedLanguage = language ? (
    localizedLanguages[`language-${language}` as LanguagesKeys]
  ) : (
    <FormattedMessage id="n-a" defaultMessage="N/A" />
  );

  const numberOfSeasons = useSelector(
    currentPodcastNumberOfSeasonsSelector
  ) || <FormattedMessage id="n-a" defaultMessage="N/A" />;

  const publicUrl = getLocalizedPublicUrl(podcastId, locale, title);
  const trailerUrl = trailerEpisode
    ? getLocalizedPublicEpisodeUrl(
        podcastId,
        locale,
        trailerEpisode.episodeId,
        trailerEpisode.title
      )
    : undefined;
  const trailerUrlValue = trailerUrl ? (
    <MusicLink
      kind="accent"
      href={trailerUrl}
      data-id={`${GO_PODCAST_TRAILER_PUBLIC_URL}-${METADATA_MODAL}`}
      target="_blank"
    >
      {trailerUrl}
    </MusicLink>
  ) : (
    <FormattedMessage id="n-a" defaultMessage="N/A" />
  );

  return (
    <PageContext.Provider value={METADATA_MODAL}>
      <BlurredModal
        containerClassName="MetadataModal__BlurredModal"
        title={
          <MusicHorizontalItem
            size="small"
            imageUrl={imageUrl}
            primaryText={title}
            label={Label}
            secondaryText={hostingProvider}
          />
        }
      >
        <MusicDivider />
        <div className="MetadataModal__content">
          <p className="MetadataModal__item">
            <b>
              <FormattedMessage
                id="metadata-lastUpdatedTime"
                defaultMessage="Last updated {timestamp}"
                values={{ timestamp: formattedTimestamp }}
              />
            </b>
          </p>
          <p className="MetadataModal__item break-text">
            <FormattedMessage id="metadata-rss" defaultMessage="RSS:" />
            <span className="MetadataModal__value secondary-text extra-large-text">
              {feedUrl}
            </span>
          </p>
          <br />
          <br />
          <p className="MetadataModal__item">
            <FormattedMessage
              id="metadata-category"
              defaultMessage="Category:"
            />
            <span className="MetadataModal__value">
              {parentCategoriesValue}
            </span>
          </p>
          <p className="MetadataModal__item">
            <FormattedMessage
              id="metadata-subcategory"
              defaultMessage="Subcategory:"
            />
            <span className="MetadataModal__value">{subcategoriesValue}</span>
          </p>
          <p className="MetadataModal__item">
            <FormattedMessage
              id="metadata-language"
              defaultMessage="Language:"
            />
            <span className="MetadataModal__value">{localizedLanguage}</span>
          </p>
          <p className="MetadataModal__item">
            <FormattedMessage id="metadata-seasons" defaultMessage="Seasons:" />
            <span className="MetadataModal__value">{numberOfSeasons}</span>
          </p>
          <p className="MetadataModal__item">
            <FormattedMessage
              id="metadata-explicit"
              defaultMessage="Explicit:"
            />
            <span className="MetadataModal__value">{isExplicitValue}</span>
          </p>
          <br />
          <p className="MetadataModal__item break-text">
            <FormattedMessage
              id="metadata-showUrl"
              defaultMessage="Show url:"
            />
            <span className="MetadataModal__value">
              <MusicLink
                kind="accent"
                href={publicUrl}
                data-id={`${GO_PODCAST_PUBLIC_URL}-${METADATA_MODAL}`}
                target="_blank"
              >
                {publicUrl}
              </MusicLink>
            </span>
          </p>
          <p className="MetadataModal__item break-text">
            <FormattedMessage id="metadata-trailer" defaultMessage="Trailer:" />
            <span className="MetadataModal__value">{trailerUrlValue}</span>
          </p>
        </div>
      </BlurredModal>
    </PageContext.Provider>
  );
};

export default MetadataModal;
