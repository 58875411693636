import { FormattedMessage } from 'react-intl';
import BlurredModal from 'components/Modal/BlurredModal';
import { MusicButton } from 'components/MusicButton';
import { CLAIM_PODCAST_MODAL, SELECT_ADD_PODCAST } from 'constants/metrics';
import { HOME_ROUTE, SUBMIT_RSS_ROUTE } from 'constants/routes';
import { PageContext } from 'contexts';
import { usePageView } from 'hooks/pageView';
import './styles.scss';

const Title = (
  <h4>
    <FormattedMessage
      id="claimPodcast-title"
      defaultMessage="First Let's Add a Podcast"
    />
  </h4>
);

const ClaimPodcast = () => {
  usePageView(CLAIM_PODCAST_MODAL);

  return (
    <PageContext.Provider value={CLAIM_PODCAST_MODAL}>
      <BlurredModal title={Title} routeOnClose={HOME_ROUTE}>
        <FormattedMessage
          id="claimPodcast-body"
          defaultMessage="Looks like there aren't any claimed podcasts associated with this account. Once you confirm ownership of your podcast you will be able to access your Dashboard."
        />
        <MusicButton
          variant="solid"
          href={SUBMIT_RSS_ROUTE}
          className="ClaimPodcast__main-cta MusicButton__wide"
          data-id={`${SELECT_ADD_PODCAST}-${CLAIM_PODCAST_MODAL}`}
        >
          <FormattedMessage
            id="addOrClaimYourPodcast"
            defaultMessage="Add or Claim Your Podcast"
          />
        </MusicButton>
      </BlurredModal>
    </PageContext.Provider>
  );
};

export default ClaimPodcast;
