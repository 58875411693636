import { PodcastStatusTypes } from 'types/podcasts';

export const DEFAULT_PAGE_SIZE = 50;
export const EXPLICIT = 'EXPLICIT';

export const PODCAST_ACTIVE = 'ACTIVE';
export const PODCAST_INACTIVE = 'INACTIVE';
export const PODCAST_REMOVED = 'REMOVED';
export const PODCAST_SUSPENDED = 'SUSPENDED';
export const PODCAST_PENDING_CONFIRMATION = 'PENDING_CONFIRMATION';
export const PODCAST_EMAIL_FAILED = 'EMAIL_FAILED';
export const PODCAST_PENDING = 'PENDING';
export const PODCAST_UNCONFIRMED = 'UNCONFIRMED';
export const PODCAST_CONFIRMED = 'CONFIRMED';
export const PODCAST_UNKNOWN = 'UNKNOWN';
export const PODCAST_HIDDEN = 'HIDDEN';
export const PODCAST_UNCLAIMED = 'UNCLAIMED';

export const PODCAST_STATUS_TO_MESSAGE_ID_MAP = {
  [PODCAST_ACTIVE]: 'status-active',
  [PODCAST_REMOVED]: 'status-removed',
  [PODCAST_SUSPENDED]: 'status-suspended',
  [PODCAST_PENDING]: 'status-pending',
  [PODCAST_EMAIL_FAILED]: 'status-invalidEmail',
  [PODCAST_UNCONFIRMED]: 'status-unconfirmed',
  [PODCAST_CONFIRMED]: 'status-confirmed',
  [PODCAST_UNKNOWN]: 'status-unknown',
  [PODCAST_HIDDEN]: 'status-hidden',
  [PODCAST_UNCLAIMED]: 'status-ownership-released',
};

export const getDisplayedStatus = (status: PodcastStatusTypes) => {
  switch (status) {
    case PODCAST_PENDING_CONFIRMATION:
      return PODCAST_UNCONFIRMED;
    case PODCAST_CONFIRMED:
      return PODCAST_PENDING;
    case PODCAST_INACTIVE:
      return PODCAST_HIDDEN;
    default:
      return status;
  }
};
