import { PayloadAction } from '@reduxjs/toolkit';
import { postWithCredentials } from 'api';
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import {
  ANALYTIC_OPTIONS_TO_API_FIELDS_MAP,
  DELTA,
  PODCAST_ID,
  TOTAL,
} from 'constants/analytics';
import { DEFAULT_PAGE_SIZE } from 'constants/podcasts';
import { PODCAST_ANALYTICS_API } from 'constants/routes';
import { podcastListCursorSelector } from 'store/selectors/podcasts';
import {
  getPodcastsAggregate,
  getPodcastsAggregateFailure,
  updatePodcastAnalytics,
  updateTimeFrame,
} from 'store/slices/analytics';
import { parseISOWithoutHour } from 'utils/date';
import { normalizeAggregateAnalytics } from 'utils/normalizer/analytics';
import { updateAggregateLoading } from 'store/slices/loading';

export function* fetchPodcastsAggregate({
  payload: { analyticTypes, startTime, endTime },
}: PayloadAction<PodcastAnalyticsAggregateActionPayload>): any {
  const podcastCursor = yield select(podcastListCursorSelector);
  const parsedStartTime = parseISOWithoutHour(startTime);
  const parsedEndTime = parseISOWithoutHour(endTime);

  try {
    const response = yield call(postWithCredentials, {
      path: PODCAST_ANALYTICS_API,
      body: {
        startTime: parsedStartTime,
        endTime: parsedEndTime,
        pagination: {
          cursor: podcastCursor || undefined,
          pageSize: DEFAULT_PAGE_SIZE,
        },
        ...analyticTypes.reduce(
          (accumulator, analyticType) => ({
            ...accumulator,
            [ANALYTIC_OPTIONS_TO_API_FIELDS_MAP[analyticType].aggregate]: {
              groupByProperties: [PODCAST_ID],
              calculationsIncluded: [TOTAL, DELTA],
            },
          }),
          {}
        ),
      },
    });
    const { data } = yield call([response, 'json']);
    return normalizeAggregateAnalytics(analyticTypes, data);
  } catch (e) {
    yield put(getPodcastsAggregateFailure());
  }
}

export function* requestPodcastsAggregate(
  action: PayloadAction<PodcastAnalyticsAggregateActionPayload>
): any {
  yield put(updateAggregateLoading(true));

  const [podcastAnalytics] = yield race([
    call(fetchPodcastsAggregate, action),
    take(updateTimeFrame.toString()),
  ]);

  if (podcastAnalytics) {
    for (const podcastId in podcastAnalytics) {
      yield put(
        updatePodcastAnalytics({
          podcastId,
          ...podcastAnalytics[podcastId],
        })
      );
    }
  }

  yield put(updateAggregateLoading(false));
}

export const podcastAggregateSagas = [
  takeEvery(getPodcastsAggregate.toString(), requestPodcastsAggregate),
];
