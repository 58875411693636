import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { MusicLink } from 'components/MusicLink';
import { SUBMIT_RSS_ROUTE } from 'constants/routes';
import { GO_SUBMIT_RSS_FEED } from 'constants/metrics';
import { PageContext } from 'contexts';

const SubmitRssLink = () => {
  const page = useContext(PageContext);
  return (
    <MusicLink
      kind="accent"
      href={SUBMIT_RSS_ROUTE}
      data-id={`${GO_SUBMIT_RSS_FEED}-${page}`}
    >
      <FormattedMessage id="here" />
    </MusicLink>
  );
};

export default SubmitRssLink;
