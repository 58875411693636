import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import ModalArtworkTile from 'components/ArtworkTile/components/ModalArtworkTile';
import BlurredModal from 'components/Modal/BlurredModal';
import Loader from 'components/Modal/Loader';
import ContactUs from 'components/Links/ContactUs';
import { PODCAST_UNCONFIRMED_MODAL } from 'constants/metrics';
import { PageContext } from 'contexts';
import useGetPodcastDetails from 'hooks/getPodcastDetails';
import { usePageView } from 'hooks/pageView';
import {
  modalPodcastIdSelector,
  modalPodcastFeedOwnerEmailSelector,
} from 'store/selectors/modal';
import './styles.scss';

const Title = (
  <h4>
    <FormattedMessage
      id="unconfirmedModal-title"
      defaultMessage="Confirm Ownership of Your Show"
    />
  </h4>
);

const UnconfirmedModal = () => {
  const modalPodcastId = useSelector(modalPodcastIdSelector);
  const feedOwnerEmail = useSelector(modalPodcastFeedOwnerEmailSelector);
  const { podcastDetailsLoading } = useGetPodcastDetails(modalPodcastId);
  usePageView(PODCAST_UNCONFIRMED_MODAL);

  return (
    <PageContext.Provider value={PODCAST_UNCONFIRMED_MODAL}>
      {podcastDetailsLoading ? (
        <Loader />
      ) : (
        <BlurredModal title={Title}>
          <div className="UnconfirmedModal__content-container">
            <p className="bodyPrimary secondary-text">
              <FormattedMessage
                id="unconfirmedModal-description"
                values={{
                  emailAddress: feedOwnerEmail,
                  contactUs: <ContactUs />,
                }}
                defaultMessage="A confirmation email has been sent to {emailAddress}, click the Confirm Ownership button within 24 hours to confirm ownership of your podcast. If you need help confirming ownership of your show, {contactUs}."
              />
            </p>
            <div className="UnconfirmedModal__artwork">
              <ModalArtworkTile />
            </div>
          </div>
        </BlurredModal>
      )}
    </PageContext.Provider>
  );
};

export default UnconfirmedModal;
